/*-----------------------------------------------------------------------------------

    [Master Stylesheet]
    Theme Name: Webency - Web Design Agency HTML5 Template
    Theme URI: https://themejunction.net/html/Webency/demo/
    Author: Theme-Junction
    Author URI: https://themeforest.net/user/theme-junction
    Description: Webency - Web Design Agency HTML5 Template

-----------------------------------------------------------------------------------

/************ TABLE OF CONTENTS ***************
    Theme Header CSS
    Theme Heading CSS
    Theme Hero CSS
    Theme About CSS
    Theme Counter CSS
    Theme Service CSS
    Theme Testimonial CSS
    Theme Team CSS
    Theme Portfolio CSS
    Theme FAQ CSS
    Theme Blog CSS
    Theme Contact CSS
    Theme Footer CSS
    Theme CTA CSS
    Theme Price CSS
    Theme Scroll CSS
    Theme Product CSS
    Theme Animation CSS
    Theme Mainmenu CSS
    Theme Breadcrumb CSS
    Theme Preloder CSS
**********************************************/
/**----------------------------------------
START: Theme Default CSS
----------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Bilbo+Swash+Caps&family=Nunito+Sans:opsz,wght@6..12,600&family=Ubuntu:wght@400;500;700&display=swap");
:root {
  /**
    @Font-Family Declaration
  */
  --tj-ff-body: 'Nunito Sans', sans-serif;
  --tj-ff-heading: 'Ubuntu', sans-serif;
  --tj-ff-heading2: 'Bilbo Swash Caps', cursive;
  --tj-ff-fontawesome: "Font Awesome 6 Pro";
  /**
    @Font-weight Declaration
  */
  --tj-fw-normal: normal;
  --tj-fw-thin: 100;
  --tj-fw-elight: 200;
  --tj-fw-light: 300;
  --tj-fw-regular: 400;
  --tj-fw-medium: 500;
  --tj-fw-sbold: 600;
  --tj-fw-bold: 700;
  --tj-fw-ebold: 800;
  --tj-fw-black: 900;
  /**
    @Font-Size Declaration
  */
  --tj-fs-body: 16px;
  --tj-fs-h1: 70px;
  --tj-fs-h2: 50px;
  --tj-fs-h3: 28px;
  --tj-fs-h4: 22px;
  --tj-fs-h5: 18px;
  --tj-fs-h6: 16px;
  /**
    @Color Declaration
  */
  --tj-color-common-white: #ffffff;
  --tj-color-common-black: #3a3a3a;
  --tj-color-heading-primary: #3a3a3a;
  --tj-color-text-body: #727272;
  --tj-color-theme-primary: #5f39ff;
  --tj-color-theme-secondary: #20d9a1;
  --tj-color-grey-1: #dcdee7;
  --tj-color-grey-2: #0e0e0e;
  --tj-color-grey-3: #313131;
  --tj-color-grey-4: #d9d9d9;
  --tj-color-grey-5: #fafafc;
  --tj-color-grey-6: #282828;
  --tj-color-grey-7: #dcdee7;
  --tj-color-grey-8: #eeecff;
  --tj-color-grey-9: #a1acb3;
  --tj-color-light-1: #e4e1ff;
  --tj-color-light-2: #f8f7ff;
  --tj-color-light-3: #705fff;
  --tj-color-light-4: #fafffd;
  --tj-color-light-5: #d4fff2;
  --tj-color-light-6: #9e92ff;
  --tj-color-light-7: #e4e1ff;
  --tj-color-light-8: #d6d1ff;
  --tj-color-light-9: #3f8acf;
  --tj-color-light-10: #8677ff;
  --tj-color-extra-1: #ff647c;
  --tj-color-extra-2: #ffb800;
  --tj-color-extra-3: #0cb770;
  --tj-color-extra-4: #7b61ff;
  --tj-color-border-1: #e4e1ff;
  --tj-color-border-2: #bcb4ff;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

/**
  Typography CSS
*/
body {
  font-family: var(--tj-ff-heading);
  font-size: var(--tj-fs-body);
  font-weight: var(--tj-fw-regular);
  color: var(--tj-color-text-body);
  line-height: 1.7;
  overflow-x: hidden;
  position: relative;
  font-size: 16px;
}

.site-content {
  overflow-x: hidden;
  position: relative;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--tj-ff-heading);
  color: var(--tj-color-heading-primary);
  margin-top: 0px;
  font-weight: var(--tj-fw-bold);
  line-height: 1.3;
  -webkit-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

h1 {
  font-size: var(--tj-fs-h1);
}

h2 {
  font-size: var(--tj-fs-h2);
}

h3 {
  font-size: var(--tj-fs-h3);
}

h4 {
  font-size: var(--tj-fs-h4);
}

h5 {
  font-size: var(--tj-fs-h5);
}

h6 {
  font-size: var(--tj-fs-h6);
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  line-height: inherit;
  display: block;
}

h1:hover a,
h2:hover a,
h3:hover a,
h4:hover a,
h5:hover a,
h6:hover a {
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
}

ul {
  margin: 0px;
  padding: 0px;
}

a,
.btn,
button,
span,
p,
input,
select,
textarea,
li,
img,
svg path,
*::after,
*::before,
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}

a,
button {
  color: inherit;
  outline: none;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

button:focus,
button:focus:not(:focus-visible) {
  outline: 0;
  -webkit-box-shadow: 0 0 0;
          box-shadow: 0 0 0;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

select,
.nice-select,
input:not([type=submit]):not([type=radio]):not([type=checkbox]),
textarea {
  outline: none;
  background-color: --tj-color-common-white;
  height: auto;
  width: 100%;
  font-size: 16px;
  border: 1px solid var(--tj-color-common-white);
  color: var(--tj-color-common-black);
  padding-left: 29px;
  padding-right: 29px;
}

input[type=color] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding: 0;
  border-radius: 50%;
}

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: var(--tj-color-theme-primary);
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
  background: transparent;
}

*::-moz-selection {
  background: var(--tj-color-common-black);
  color: var(--tj-color-common-white);
  text-shadow: none;
}

::-moz-selection {
  background: var(--tj-color-common-black);
  color: var(--tj-color-common-white);
  text-shadow: none;
}

::selection {
  background: var(--tj-color-common-black);
  color: var(--tj-color-common-white);
  text-shadow: none;
}

*::-webkit-input-placeholder {
  color: var(--tj-color-common-black);
  font-size: var(--tj-fs-body);
  opacity: 1;
}
*::-moz-placeholder {
  color: var(--tj-color-common-black);
  font-size: var(--tj-fs-body);
  opacity: 1;
}
*:-ms-input-placeholder {
  color: var(--tj-color-common-black);
  font-size: var(--tj-fs-body);
  opacity: 1;
}
*::-ms-input-placeholder {
  color: var(--tj-color-common-black);
  font-size: var(--tj-fs-body);
  opacity: 1;
}
*::placeholder {
  color: var(--tj-color-common-black);
  font-size: var(--tj-fs-body);
  opacity: 1;
}

/**
 	Common Classes CSS
*/
img {
  max-width: 100%;
}

.w-img img {
  width: 100%;
}

.m-img img {
  max-width: 100%;
}

.fix {
  overflow: hidden;
}

.clear {
  clear: both;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.z-index-1 {
  z-index: 1;
}

.z-index-11 {
  z-index: 11;
}

.overflow-y-visible {
  overflow-x: hidden;
  overflow-y: visible;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.include-bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.b-radius {
  border-radius: 6px;
}

.dot-style {
  margin: 0;
  padding: 0;
  list-style: none;
}

form .error {
  font-size: 14px;
  color: red !important;
}

/**----------------------------------------
START: Theme Button CSS
----------------------------------------*/
.tj-cancel-btn,
.tj-primary-btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 150px;
  font-size: 16px;
  font-weight: var(--tj-fw-sbold);
  font-family: var(--tj-ff-body);
  line-height: 2;
  text-transform: capitalize;
  color: var(--tj-color-common-white);
  padding: 12px 35px;
  border: none;
  background-image: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), color-stop(51%, var(--tj-color-theme-primary)), to(var(--tj-color-theme-secondary)));
  background-image: linear-gradient(to right, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 51%, var(--tj-color-theme-secondary) 100%);
  background-size: 200%;
}
.tj-cancel-btn:hover, .tj-cancel-btn:focus,
.tj-primary-btn:hover,
.tj-primary-btn:focus {
  color: var(--tj-color-common-white);
  background-position: -100%;
}
.tj-cancel-btn.btn-border,
.tj-primary-btn.btn-border {
  position: relative;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-primary)), to(var(--tj-color-theme-secondary)));
  background: linear-gradient(90deg, var(--tj-color-theme-primary) 0%, var(--tj-color-theme-secondary) 100%);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  -webkit-box-shadow: 0 0 0 0;
          box-shadow: 0 0 0 0;
  padding: 1px;
}
.tj-cancel-btn.btn-border span,
.tj-primary-btn.btn-border span {
  padding: 12px 35px;
  background: var(--tj-color-common-white);
  border-radius: 58px;
  color: var(--tj-color-common-black);
}
.tj-cancel-btn.btn-border:hover,
.tj-primary-btn.btn-border:hover {
  color: var(--tj-color-common-white);
}
.tj-cancel-btn.btn-border:hover span,
.tj-primary-btn.btn-border:hover span {
  background: transparent;
  color: var(--tj-color-common-white);
}

.tj-secondary-btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 150px;
  font-size: 16px;
  font-weight: var(--tj-fw-sbold);
  font-family: var(--tj-ff-body);
  line-height: 2;
  text-transform: capitalize;
  color: var(--tj-color-theme-primary);
  padding: 12px 35px;
  border: none;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-primary)), to(var(--tj-color-theme-secondary)));
  background: linear-gradient(90deg, var(--tj-color-theme-primary) 0%, var(--tj-color-theme-secondary) 100%);
  background-size: 150% auto;
}
.tj-secondary-btn:hover, .tj-secondary-btn:focus {
  color: var(--tj-color-theme-primary);
  background-position: right center;
}
.tj-secondary-btn.btn-border {
  position: relative;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  -webkit-box-shadow: 0 0 0 0;
          box-shadow: 0 0 0 0;
  padding: 1px;
}
.tj-secondary-btn.btn-border span {
  padding: 12px 35px;
  background: var(--tj-color-common-white);
  border-radius: 58px;
  color: var(--tj-color-theme-primary);
}
.tj-secondary-btn.btn-border:hover span {
  background: transparent;
  color: var(--tj-color-common-white);
}

.tj-header-btn {
  border-radius: 150px;
  border: 1px solid var(--tj-color-theme-secondary);
  color: var(--tj-color-common-white);
  padding: 19px 35px;
  font-weight: var(--tj-fw-sbold);
  font-family: var(--tj-ff-body);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tj-header-btn:hover {
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  border-color: transparent;
  color: var(--tj-color-common-white);
}

.tj-black-btn {
  background: var(--tj-color-common-black);
  padding: 12px 30px;
  font-family: var(--tj-ff-heading);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 6px;
  font-size: 18px;
  font-weight: var(--tj-fw-regular);
  color: var(--tj-color-common-white);
  position: relative;
  border: none;
  z-index: 3;
}
.tj-black-btn::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  border-radius: 6px;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}
.tj-black-btn i {
  padding-left: 10px;
  position: relative;
  top: 2px;
}
.tj-black-btn:hover {
  color: var(--tj-color-common-white);
}
.tj-black-btn:hover::before {
  opacity: 1;
  visibility: visible;
}

.tj-primary-btn-two {
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  padding: 12px 30px;
  font-family: var(--tj-ff-body);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 6px;
  font-size: 18px;
  font-weight: var(--tj-fw-medium);
  color: var(--tj-color-common-white);
  position: relative;
  border: none;
  z-index: 3;
}
.tj-primary-btn-two i {
  padding-left: 10px;
  position: relative;
  top: 2px;
}
.tj-primary-btn-two:hover {
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-primary)), to(var(--tj-color-theme-secondary)));
  background: linear-gradient(90deg, var(--tj-color-theme-primary) 0%, var(--tj-color-theme-secondary) 100%);
  color: var(--tj-color-common-white);
}

.tj-white-btn {
  background: transparent;
  padding: 13px 40px;
  font-weight: var(--tj-fw-sbold);
  font-family: var(--tj-ff-body);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 10px;
  font-size: 16px;
  color: var(--tj-color-common-black);
  position: relative;
  border: 1px solid var(--tj-color-common-black);
  z-index: 3;
}
.tj-white-btn:hover {
  background-color: var(--tj-color-common-black);
  color: var(--tj-color-common-white);
}

.text-btn {
  padding: 9px 30px;
  font-weight: var(--tj-fw-sbold);
  font-family: var(--tj-ff-body);
  border: 1px solid rgba(158, 146, 255, 0.5);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 6px;
  font-size: 16px;
  font-weight: var(--tj-fw-regular);
  color: var(--tj-color-text-body);
  position: relative;
  z-index: 3;
  margin-right: 15px;
}
.text-btn:hover {
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-primary)), to(var(--tj-color-theme-secondary)));
  background: linear-gradient(90deg, var(--tj-color-theme-primary) 0%, var(--tj-color-theme-secondary) 100%);
  border-color: transparent;
  color: var(--tj-color-common-white);
}
.text-btn:hover::before {
  opacity: 1;
  visibility: visible;
}

.tj-transparent-btn {
  color: var(--tj-color-common-white);
  background-image: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background-image: linear-gradient(90deg, var(--tj-color-theme-secondary), var(--tj-color-theme-primary));
  display: inline-block;
  padding: 13px 35px;
  border: 1px solid;
  position: relative;
  z-index: 0;
  border-radius: 150px;
  font-family: var(--tj-ff-body);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tj-transparent-btn::before {
  content: "";
  position: absolute;
  left: -2px;
  top: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary), var(--tj-color-theme-primary));
  z-index: -2;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 150px;
}
.tj-transparent-btn::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--tj-color-grey-2);
  z-index: -1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 150px;
}
.tj-transparent-btn:hover {
  color: var(--tj-color-common-white);
}
.tj-transparent-btn:hover::after {
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary), var(--tj-color-theme-primary));
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

/* 14 */
.tj-black-btn-two {
  background: var(--tj-color-common-black);
  padding: 12px 30px;
  font-family: var(--tj-ff-heading);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 6px;
  font-size: 18px;
  font-weight: var(--tj-fw-regular);
  color: var(--tj-color-common-white);
  position: relative;
  border: none;
  z-index: 3;
  overflow: hidden;
}
.tj-black-btn-two::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 6px;
  background-color: var(--tj-color-common-white);
  background-image: linear-gradient(315deg, var(--tj-color-common-white) 0%, var(--tj-color-common-white) 74%);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.tj-black-btn-two.active {
  top: 2px;
}
.tj-black-btn-two:hover {
  color: var(--tj-color-common-black);
}
.tj-black-btn-two:hover::after {
  top: auto;
  bottom: 0;
  height: 100%;
}

/* !END: Theme Button CSS */
/**----------------------------------------
START: Theme Header CSS
----------------------------------------*/
.tj-header-area {
  padding: 25px 0;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tj-header-area.header-absolute {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
}
.tj-header-area.header-sticky {
  position: fixed;
}
.tj-header-area.sticky {
  padding: 15px 0;
  background: var(--tj-color-grey-2);
  -webkit-animation: sticky 0.9s;
          animation: sticky 0.9s;
}
.tj-header-area .header-button {
  margin-right: -40px;
}
.tj-header-area .header-button .tj-secondary-btn.btn-border span {
  background: var(--tj-color-grey-2);
  color: var(--tj-color-common-white);
}
.tj-header-area .header-button .tj-secondary-btn:hover span {
  background: transparent;
  color: var(--tj-color-common-white);
}
@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .tj-header-area .header-button {
    margin-right: 0;
  }
}
.tj-header-area .logo-area {
  max-width: 195px;
  width: 100%;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-header-area .logo-area {
    max-width: 150px;
  }
}
.tj-header-area .logo-area a {
  display: inline-block;
}
.tj-header-area .logo-area a img {
  width: 100%;
}
.tj-header-area.header-style-two.sticky {
  padding: 0;
  background: var(--tj-color-common-white);
  -webkit-animation: sticky 0.9s;
          animation: sticky 0.9s;
  -webkit-box-shadow: 30px 15px 30px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 30px 15px 30px 0px rgba(0, 0, 0, 0.05);
}
.tj-header-area.header-style-two.sticky .header-topbar {
  display: none;
}
.tj-header-area.header-style-two.sticky .menu-area {
  padding: 15px 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 0;
  background: var(--tj-color-common-white);
}
@media (max-width: 575px) {
  .tj-header-area.header-style-two.sticky .menu-area .container {
    padding: 0;
  }
}
.tj-header-area.header-style-two .header-button {
  margin-right: 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-header-area.header-style-three {
    padding: 15px 0;
  }
}
.tj-header-area.header-style-three .menu-area {
  padding: 20px 100px;
  -webkit-box-shadow: none;
          box-shadow: none;
  background: var(--tj-color-common-white);
  max-width: 1520px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .tj-header-area.header-style-three .menu-area {
    padding: 20px 35px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-header-area.header-style-three .menu-area {
    padding: 15px 15px;
  }
}
.tj-header-area.header-style-three.sticky {
  padding: 0;
  background: var(--tj-color-common-white);
  -webkit-box-shadow: 30px 15px 30px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 30px 15px 30px 0px rgba(0, 0, 0, 0.05);
}
.tj-header-area.header-style-three .logo-area {
  max-width: 162px;
  width: 100%;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-header-area.header-style-three .logo-area {
    max-width: 150px;
  }
}
.tj-header-area.header-style-three .logo-area a {
  display: inline-block;
}
.tj-header-area.header-style-three .logo-area a img {
  width: 100%;
}
.tj-header-area.header-style-three .header-button {
  margin-right: 0;
}

.header-topbar {
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  padding: 17px 0;
  margin-bottom: 20px;
}
@media (max-width: 575px) {
  .header-topbar {
    padding: 12px 0;
    margin-bottom: 15px;
  }
}

.topbar-content-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 575px) {
  .topbar-content-area {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
  }
}
.topbar-content-area .topbar-share {
  width: 100%;
}
@media (max-width: 575px) {
  .topbar-content-area .topbar-left-content {
    margin-bottom: 10px;
    width: 100%;
  }
}
.topbar-content-area .topbar-contact ul li {
  display: inline-block;
  color: var(--tj-color-common-white);
  font-size: 14px;
  display: inline-block;
  margin-right: 30px;
  padding-left: 30px;
  position: relative;
}
.topbar-content-area .topbar-contact ul li:last-child {
  margin-right: 0;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .topbar-content-area .topbar-contact ul li {
    margin-right: 25px;
  }
}
.topbar-content-area .topbar-contact ul li a {
  font-family: var(--tj-ff-heading);
  font-weight: var(--tj-fw-bold);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  color: var(--tj-color-common-white);
}
.topbar-content-area .topbar-contact ul li i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  line-height: 1;
  font-size: 18px;
}
.topbar-content-area .topbar-share ul li {
  display: inline-block;
  margin-right: 5px;
}
.topbar-content-area .topbar-share ul li:last-child {
  margin-right: 0;
}
.topbar-content-area .topbar-share ul li a i {
  background: var(--tj-color-common-white);
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50%;
  color: var(--tj-color-theme-primary);
  font-size: 14px;
  display: inline-block;
  text-align: center;
  -webkit-transition: 0.1s;
  transition: 0.1s;
}
.topbar-content-area .topbar-share ul li:hover i {
  background: var(--tj-color-theme-secondary);
  color: var(--tj-color-common-white);
}

.menu-area {
  border-radius: 8px;
  background: var(--tj-color-grey-5);
  -webkit-box-shadow: 30px 15px 30px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 30px 15px 30px 0px rgba(0, 0, 0, 0.05);
  max-width: 1370px;
  padding: 22px 10px;
  margin: 0 auto;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .menu-area {
    max-width: 1150px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .menu-area {
    max-width: 750px;
    padding: 15px 15px;
  }
}
@media (max-width: 575px) {
  .menu-area {
    max-width: calc(100% - 30px);
  }
}
.menu-area .header-content-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.header-content-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@-webkit-keyframes sticky {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}
@keyframes sticky {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}
/* !END: Theme Header CSS */
/**----------------------------------------
START: Theme Heading CSS
----------------------------------------*/
.tj-sec-heading {
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 3;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-sec-heading {
    margin-bottom: 35px;
  }
}
.tj-sec-heading .sub-title {
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 35px;
  font-family: var(--tj-ff-heading2);
  letter-spacing: 3.5px;
  font-weight: var(--tj-fw-regular);
  display: inline-block;
  margin-bottom: 10px;
  line-height: 1.2;
}
.tj-sec-heading .sub-title.secondary-color {
  color: var(--tj-color-theme-secondary);
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-sec-heading .sub-title {
    font-size: 30px;
  }
}
.tj-sec-heading .white-sub-title {
  color: var(--tj-color-common-white);
  font-size: 35px;
  font-family: var(--tj-ff-heading2);
  letter-spacing: 3.5px;
  font-weight: var(--tj-fw-regular);
  margin-bottom: 20px;
  line-height: 1.2;
  display: inline-block;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-sec-heading .white-sub-title {
    font-size: 30px;
  }
}
.tj-sec-heading .active-color {
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.tj-sec-heading .sec-title {
  margin-bottom: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tj-sec-heading .sec-title {
    font-size: 40px;
    margin-bottom: 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-sec-heading .sec-title {
    font-size: 32px;
    margin-bottom: 15px;
  }
}
.tj-sec-heading .black-color {
  color: var(--tj-color-common-black);
}
.tj-sec-heading p {
  margin-bottom: 0;
}

.secondary-color {
  font-size: 35px;
  font-family: var(--tj-ff-heading2);
  letter-spacing: 3.5px;
  font-weight: var(--tj-fw-regular);
  display: inline-block;
  margin-bottom: 10px;
  line-height: 1.2;
  color: var(--tj-color-theme-primary);
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .secondary-color {
    font-size: 30px;
  }
}

/* !END: Theme Heading CSS */
/**----------------------------------------
START: Theme Hero CSS
----------------------------------------*/
.tj-hero-section {
  position: relative;
  padding-top: 210px;
  padding-bottom: 90px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: linear-gradient(181deg, var(--tj-color-grey-7) 2.29%, rgba(40, 40, 40, 0.7) 89.63%);
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-hero-section {
    padding-top: 160px;
    padding-bottom: 110px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-hero-section .tj-sec-heading {
    margin-bottom: 80px;
  }
}
.tj-hero-section .title {
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-primary)), to(var(--tj-color-theme-secondary)));
  background: linear-gradient(90deg, var(--tj-color-theme-primary) 0%, var(--tj-color-theme-secondary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: var(--tj-fw-bold);
}
.tj-hero-section .hero-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-hero-section .hero-shape {
    width: 50%;
  }
}
.tj-hero-section .hero-shape1 {
  position: absolute;
  top: 0;
  right: 0;
}
.tj-hero-section .hero-shape2 {
  position: absolute;
  top: 20%;
  left: 0;
  opacity: 0.5;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-hero-section .hero-shape2 {
    display: none;
  }
}
.tj-hero-section .hero-shape3 {
  position: absolute;
  top: 12%;
  left: 0;
  opacity: 0.2;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-hero-section .hero-shape3 {
    display: none;
  }
}
.tj-hero-section .hero-scroll {
  position: absolute;
  bottom: -44px;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 3;
  max-width: 125px;
  width: 100%;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-hero-section .hero-scroll {
    max-width: 90px;
    bottom: -30px;
  }
}
.tj-hero-section .hero-scroll .scroll-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.tj-hero-section .hero-scroll .scroll-icon a {
  display: inline-block;
  opacity: 0.6;
}
.tj-hero-section .hero-scroll .jump {
  -webkit-animation: jump-arrow 2.5s infinite;
  animation: jump-arrow 2.5s infinite;
}

.tj-slider-section {
  position: relative;
}
.tj-slider-section .swiper-slide {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.tj-slider-section .swiper-slide::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(78deg, rgba(40, 40, 40, 0.95) 0%, rgba(40, 40, 40, 0.76) 41.52%, rgba(255, 255, 255, 0) 100%);
  mix-blend-mode: multiply;
}
.tj-slider-section .swiper-slide .white-sub-title,
.tj-slider-section .swiper-slide .desc,
.tj-slider-section .swiper-slide .title {
  opacity: 0;
  visibility: hidden;
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, -webkit-transform 2000ms ease;
}
.tj-slider-section .swiper-slide .hero-button {
  opacity: 0;
  visibility: hidden;
  -webkit-transition-delay: 700ms;
  transition-delay: 700ms;
  -webkit-transition: opacity 1500ms ease, -webkit-transform 1500ms ease;
  transition: opacity 1500ms ease, -webkit-transform 1500ms ease;
  transition: transform 1500ms ease, opacity 1500ms ease;
  transition: transform 1500ms ease, opacity 1500ms ease, -webkit-transform 1500ms ease;
}
.tj-slider-section .swiper-slide .white-sub-title,
.tj-slider-section .swiper-slide .title {
  -webkit-transform: translateY(-125px);
  -ms-transform: translateY(-125px);
      transform: translateY(-125px);
}
.tj-slider-section .swiper-slide .hero-button,
.tj-slider-section .swiper-slide .desc {
  -webkit-transform: translateY(125px);
  -ms-transform: translateY(125px);
      transform: translateY(125px);
}
.tj-slider-section .swiper-slide.swiper-slide-active .white-sub-title,
.tj-slider-section .swiper-slide.swiper-slide-active .desc,
.tj-slider-section .swiper-slide.swiper-slide-active .title,
.tj-slider-section .swiper-slide.swiper-slide-active .hero-button {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0) translateX(0);
  -ms-transform: translateY(0) translateX(0);
      transform: translateY(0) translateX(0);
}
.tj-slider-section .swiper-pagination {
  width: auto;
  right: 50px;
  left: auto;
  bottom: auto;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(90deg);
      -ms-transform: translateY(-50%) rotate(90deg);
          transform: translateY(-50%) rotate(90deg);
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-slider-section .swiper-pagination {
    right: 15px;
  }
}
.tj-slider-section .swiper-pagination .swiper-pagination-bullet {
  display: inline-block;
  margin: 0 6px;
}
.tj-slider-section .swiper-pagination .swiper-pagination-bullet:last-child {
  margin-bottom: 0;
}
.tj-slider-section .swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 50%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background: transparent;
  position: relative;
  top: -9px;
  background: rgba(255, 255, 255, 0.6);
  opacity: 1;
}
.tj-slider-section .swiper-pagination-bullet-active {
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
}

.tj-hero-section-three {
  position: relative;
  overflow: hidden;
  padding-top: 310px;
  padding-bottom: 195px;
  background: linear-gradient(78deg, rgba(95, 57, 255, 0.95) 0%, rgba(95, 57, 255, 0.76) 41.52%, rgba(255, 255, 255, 0) 100%);
  mix-blend-mode: multiply;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 2;
}
.tj-hero-section-three::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.35;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  mix-blend-mode: lighten;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .tj-hero-section-three {
    padding-top: 245px;
    padding-bottom: 145px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-hero-section-three {
    padding-top: 175px;
    padding-bottom: 80px;
  }
}
.tj-hero-section-three .hero-lg-image {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 1328px;
  width: 100%;
  height: 100%;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-mask-size: cover;
          mask-size: cover;
}
.tj-hero-section-three .hero-lg-image img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.tj-hero-section-three .hero-lg-image::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--tj-color-light-2);
  mix-blend-mode: color;
}
.tj-hero-section-three .hero-content-area {
  padding-top: 0;
  padding-bottom: 0;
}
.tj-hero-section-three .tj-sec-heading {
  margin-bottom: 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .tj-hero-section-three .tj-sec-heading .title {
    font-size: 55px;
  }
}
.tj-hero-section-three .tj-sec-heading .desc {
  max-width: 680px;
}
.tj-hero-section-three .tj-sec-heading .desc p {
  font-size: 18px;
}
.tj-hero-section-three .tj-sec-heading .hero-button {
  margin-top: 30px;
}
.tj-hero-section-three .hero-group-shape .group-1 {
  position: absolute;
  top: 0;
  left: 0;
}
.tj-hero-section-three .hero-group-shape .group-2 {
  position: absolute;
  bottom: 35px;
  right: 35%;
  opacity: 0.4;
}
.tj-hero-section-three .hero-group-shape .group-3 {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.9;
  z-index: 4;
}
.tj-hero-section-three .hero-group-shape .group-4 {
  position: absolute;
  top: 0;
  left: 40%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  opacity: 0.9;
  z-index: 4;
}
.tj-hero-section-three .hero-group-shape .group-5 {
  position: absolute;
  bottom: 100px;
  left: 40%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  opacity: 0.5;
}
.tj-hero-section-three .hero-group-shape .group-6 {
  position: absolute;
  top: 20%;
  width: 105px;
  height: 105px;
  left: 35%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-mask-size: contain;
          mask-size: contain;
  background: var(--tj-color-common-white);
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-hero-section-three .hero-group-shape {
    display: none;
  }
}

.hero-group-shape {
  opacity: 0.5;
}
@media (max-width: 575px) {
  .hero-group-shape {
    display: none;
  }
}
.hero-group-shape .bg-shape-one {
  position: absolute;
  top: 0;
  right: 0;
}
.hero-group-shape .bg-shape-two {
  position: absolute;
  top: 0;
  right: 0;
}
.hero-group-shape .bg-shape-three {
  position: absolute;
  top: 0;
  right: 0;
}
.hero-group-shape .bg-shape-four {
  position: absolute;
  top: 70px;
  right: 0;
}
.hero-group-shape .hero-shape {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  opacity: 0.4;
}
.hero-group-shape .hero-shape1 {
  position: absolute;
  top: 30%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 50px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .hero-group-shape .hero-shape1 {
    display: none;
  }
}
.hero-group-shape .hero-shape2 {
  position: absolute;
  bottom: 10%;
  right: 15%;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .hero-group-shape .hero-shape2 {
    display: none;
  }
}

.hero-left-content {
  position: relative;
  z-index: 3;
}
.hero-left-content .hero-sub-title {
  color: var(--tj-color-common-white);
  font-family: var(--tj-ff-heading2);
  font-size: 35px;
  letter-spacing: 3.5px;
  font-weight: var(--tj-fw-regular);
  display: block;
  margin-bottom: 10px;
}
.hero-left-content .hero-title {
  color: var(--tj-color-common-white);
  margin-bottom: 25px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero-left-content .hero-title {
    font-size: 55px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .hero-left-content .hero-title {
    font-size: 50px;
  }
}
@media (max-width: 575px) {
  .hero-left-content .hero-title {
    font-size: 38px;
  }
}
.hero-left-content p {
  color: var(--tj-color-grey-4);
  font-size: 18px;
  max-width: 685px;
  margin-bottom: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-left-content p {
    max-width: 480px;
  }
}
.hero-left-content .hero-button {
  margin-top: 50px;
}

.hero-image-group {
  text-align: right;
  position: relative;
  margin-right: -175px;
  z-index: 3;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .hero-image-group {
    margin-right: -35px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-image-group {
    margin-right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .hero-image-group {
    margin-right: 140px;
    text-align: center;
  }
}
@media (max-width: 575px) {
  .hero-image-group {
    margin-right: 20px;
    text-align: center;
  }
}
.hero-image-group .group-image1 {
  position: relative;
  max-width: 430px;
  width: 100%;
  margin-left: auto;
  z-index: 3;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-image-group .group-image1 {
    max-width: 300px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-image-group .group-image1 {
    margin-right: -30px;
  }
}
@media (max-width: 575px) {
  .hero-image-group .group-image1 {
    max-width: 250px;
  }
}
.hero-image-group .group-image1::before {
  position: absolute;
  content: "";
  top: -25px;
  width: 110%;
  height: 105%;
  right: -20px;
  background-size: contain;
  background-repeat: no-repeat;
}
.hero-image-group .group-image1 img {
  position: relative;
  z-index: 3;
  border-radius: 550px;
}
.hero-image-group .group-image2 {
  position: absolute;
  top: 20%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  z-index: 3;
  max-width: 345px;
  width: 100%;
}
.hero-image-group .group-image2::before {
  content: "";
  position: absolute;
  border-radius: 550px 500px 500px 500px;
  opacity: 3.25%;
  background: -webkit-gradient(linear, left top, left bottom, from(var(--tj-color-grey-7)), to(rgba(40, 40, 40, 0.7)));
  background: linear-gradient(180deg, var(--tj-color-grey-7) 0%, rgba(40, 40, 40, 0.7) 100%);
  top: 46%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  left: -15px;
  width: 346px;
  height: 530px;
  z-index: 3;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-image-group .group-image2::before {
    width: 275px;
    height: 450px;
    top: 50%;
    left: -20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-image-group .group-image2::before {
    width: 220px;
    height: 360px;
  }
}
@media (max-width: 575px) {
  .hero-image-group .group-image2::before {
    width: 220px;
    height: 350px;
    top: 50%;
    left: -20px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-image-group .group-image2 {
    left: -50px;
    max-width: 250px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-image-group .group-image2 {
    max-width: 200px;
    left: 25%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-image-group .group-image2 {
    max-width: 200px;
    left: 15%;
  }
}
@media (max-width: 575px) {
  .hero-image-group .group-image2 {
    max-width: 200px;
    left: 20px;
  }
}
.hero-image-group .group-image2 img {
  border-radius: 515px 500px 515px 500px;
  border: 10px solid var(--tj-color-common-black);
}
.hero-image-group .group-image3 {
  position: absolute;
  left: 0;
  top: -12%;
  opacity: 0.2;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-image-group .group-image3 {
    top: -22%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .hero-image-group .group-image3 {
    display: none;
  }
}
.hero-image-group .group-image4 {
  position: absolute;
  left: 15%;
  top: -5%;
  opacity: 0.5;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-image-group .group-image4 {
    top: -15%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .hero-image-group .group-image4 {
    display: none;
  }
}
.hero-image-group .group-image5 {
  position: absolute;
  right: -20px;
  top: -10%;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .hero-image-group .group-image5 {
    display: none;
  }
}
.hero-image-group .group-image6 {
  position: absolute;
  left: -5%;
  bottom: 5%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-image-group .group-image6 {
    bottom: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .hero-image-group .group-image6 {
    display: none;
  }
}

.hero-content-area {
  padding-top: 285px;
  padding-bottom: 90px;
  max-width: 760px;
  position: relative;
  z-index: 3;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .hero-content-area {
    padding-top: 260px;
    padding-bottom: 30px;
  }
}
.hero-content-area .title {
  color: var(--tj-color-grey-5);
  margin-bottom: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .hero-content-area .title {
    font-size: 50px;
  }
}
@media (max-width: 575px) {
  .hero-content-area .title {
    font-size: 38px;
  }
}
.hero-content-area .desc p {
  color: var(--tj-color-light-4);
  font-size: 20px;
}
.hero-content-area .hero-button {
  margin-top: 40px;
}
.hero-content-area .active-shape {
  position: relative;
}
.hero-content-area .active-shape::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 50px;
  left: 0;
  bottom: 8%;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
  z-index: -1;
}

@-webkit-keyframes jump-arrow {
  0%, 20%, 55%, 80%, 100% {
    -webkit-transform: translate3d(-50%, 0, 0);
            transform: translate3d(-50%, 0, 0);
  }
  40% {
    -webkit-transform: translate3d(-50%, -8px, 0);
            transform: translate3d(-50%, -8px, 0);
  }
  70% {
    -webkit-transform: translate3d(-50%, -8px, 0);
            transform: translate3d(-50%, -8px, 0);
  }
  90% {
    -webkit-transform: translate3d(-50%, -5px, 0);
            transform: translate3d(-50%, -5px, 0);
  }
}

@keyframes jump-arrow {
  0%, 20%, 55%, 80%, 100% {
    -webkit-transform: translate3d(-50%, 0, 0);
            transform: translate3d(-50%, 0, 0);
  }
  40% {
    -webkit-transform: translate3d(-50%, -8px, 0);
            transform: translate3d(-50%, -8px, 0);
  }
  70% {
    -webkit-transform: translate3d(-50%, -8px, 0);
            transform: translate3d(-50%, -8px, 0);
  }
  90% {
    -webkit-transform: translate3d(-50%, -5px, 0);
            transform: translate3d(-50%, -5px, 0);
  }
}
/* !END: Theme Hero CSS */
/**----------------------------------------
START: Theme About CSS
----------------------------------------*/
.tj-about-section {
  padding-top: 230px;
  padding-bottom: 150px;
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tj-about-section {
    padding-top: 445px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-about-section {
    padding-top: 410px;
    padding-bottom: 80px;
  }
}
@media (max-width: 575px) {
  .tj-about-section {
    padding-top: 350px;
  }
}
.tj-about-section .tj-sec-heading {
  margin-bottom: 0;
}

.about-section-shape {
  position: absolute;
  top: 10%;
  right: 5%;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-section-shape {
    top: 5%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .about-section-shape {
    display: none;
  }
}

.about-content-one .about-button {
  margin-top: 40px;
}
.about-content-one .desc p {
  margin-bottom: 20px;
  max-width: 600px;
}
.about-content-one .desc p:last-child {
  margin-bottom: 0;
}

.about-image-group {
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .about-image-group {
    margin-bottom: 270px;
  }
}
@media (max-width: 575px) {
  .about-image-group {
    margin-bottom: 220px;
  }
}
.about-image-group .about-circle-box {
  position: absolute;
  right: 18%;
  border-radius: 50%;
  bottom: 200px;
}
.about-image-group .about-circle-box::before {
  position: absolute;
  content: "";
  width: 22px;
  height: 22px;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  z-index: 9;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-image-group .about-circle-box {
    bottom: 190px;
    right: 10%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-image-group .about-circle-box {
    right: 32%;
    bottom: 180px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-image-group .about-circle-box {
    bottom: 190px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-image-group .about-circle-box {
    bottom: 160px;
    right: 13%;
  }
}
@media (max-width: 575px) {
  .about-image-group .about-circle-box {
    bottom: 140px;
    right: 2%;
  }
}
.about-image-group .about-circle-box .shape-1 {
  border-radius: 50%;
  padding: 5px;
  position: relative;
  z-index: 3;
  max-width: 120px;
  -webkit-animation: rotateImg 10s infinite linear;
          animation: rotateImg 10s infinite linear;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-image-group .about-circle-box .shape-1 {
    max-width: 100px;
  }
}
@media (max-width: 575px) {
  .about-image-group .about-circle-box .shape-1 {
    max-width: 100px;
  }
}
.about-image-group .about-circle-box .circle-one {
  position: absolute;
  top: 90%;
  left: 20%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-width: 250px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .about-image-group .about-circle-box .circle-one {
    max-width: 210px;
  }
}
.about-image-group .about-circle-box .ab-circle {
  background: rgba(228, 225, 255, 0.15);
  border-radius: 132px;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  padding: 5px;
  border: 1px solid var(--tj-color-light-3);
}
.about-image-group .about-circle-box .ab-circle svg {
  fill: var(--tj-color-theme-primary);
  height: auto;
  max-width: 120px;
  -webkit-transform-origin: center;
      -ms-transform-origin: center;
          transform-origin: center;
  width: 100%;
  font-weight: var(--tj-fw-bold);
}
.about-image-group .about-image1 {
  position: absolute;
  top: -240px;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 16%;
  max-width: 400px;
  width: 100%;
  z-index: 3;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .about-image-group .about-image1 {
    max-width: 375px;
    left: 30%;
    top: -210px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-image-group .about-image1 {
    max-width: 375px;
    left: 18%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .about-image-group .about-image1 {
    max-width: 375px;
    left: 25%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-image-group .about-image1 {
    max-width: 330px;
    left: 20%;
    top: -190px;
  }
}
@media (max-width: 575px) {
  .about-image-group .about-image1 {
    max-width: 260px;
    left: 20%;
    top: -160px;
  }
}
.about-image-group .about-image1::before {
  position: absolute;
  content: "";
  bottom: -20px;
  right: -50%;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
}
@media (max-width: 575px) {
  .about-image-group .about-image1::before {
    display: none;
  }
}
.about-image-group .about-image1::after {
  position: absolute;
  content: "";
  top: 3.5%;
  left: 7%;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}
.about-image-group .about-image1 img {
  border-radius: 50%;
  padding: 50px;
  position: relative;
  z-index: 3;
  max-width: 420px;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-image-group .about-image1 img {
    max-width: 375px;
    padding: 45px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .about-image-group .about-image1 img {
    max-width: 375px;
    padding: 45px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-image-group .about-image1 img {
    max-width: 330px;
    padding: 40px;
  }
}
@media (max-width: 575px) {
  .about-image-group .about-image1 img {
    max-width: 260px;
    padding: 35px;
  }
}
.about-image-group .about-image2 {
  position: absolute;
  top: -20px;
  left: -18.5%;
  max-width: 245px;
  width: 100%;
  bottom: auto;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .about-image-group .about-image2 {
    left: -2.5%;
    max-width: 200px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-image-group .about-image2 {
    left: 0;
    max-width: 120px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-image-group .about-image2 {
    left: 10.5%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-image-group .about-image2 {
    left: 5.5%;
    max-width: 150px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-image-group .about-image2 {
    left: -10px;
    max-width: 130px;
  }
}
@media (max-width: 575px) {
  .about-image-group .about-image2 {
    left: 0;
    max-width: 110px;
    padding: 5px;
    top: 15px;
  }
}
.about-image-group .about-image2 img {
  border: 1px solid var(--tj-color-theme-primary);
  border-radius: 50%;
  padding: 10px;
}
.about-image-group .about-image3 {
  position: absolute;
  top: auto;
  left: 30px;
  bottom: 190px;
  max-width: 200px;
  width: 100%;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .about-image-group .about-image3 {
    bottom: 160px;
    left: 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-image-group .about-image3 {
    left: 95px;
    bottom: 150px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .about-image-group .about-image3 {
    left: 95px;
    bottom: 170px;
    max-width: 160px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-image-group .about-image3 {
    left: 40px;
  }
}
@media (max-width: 575px) {
  .about-image-group .about-image3 {
    left: 10px;
    max-width: 130px;
    bottom: 120px;
  }
}
.about-image-group .about-image3::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}
.about-image-group .about-image3 img {
  border-radius: 50%;
  padding: 10px;
}
.about-image-group .about-image4 {
  position: absolute;
  top: 150px;
  right: 16%;
  max-width: 150px;
  width: 100%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-image-group .about-image4 {
    right: 26%;
    top: 85px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .about-image-group .about-image4 {
    right: 10%;
    top: 80px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-image-group .about-image4 {
    max-width: 130px;
    right: 6%;
    top: 80px;
  }
}
@media (max-width: 575px) {
  .about-image-group .about-image4 {
    max-width: 100px;
    right: 2%;
    top: 80px;
  }
}
.about-image-group .about-image4 img {
  border: 1px solid var(--tj-color-theme-secondary);
  border-radius: 50%;
  padding: 7px;
}
.about-image-group .about-shape1 {
  position: absolute;
  bottom: 50px;
  left: -9%;
  top: auto;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .about-image-group .about-shape1 {
    left: -3%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .about-image-group .about-shape1 {
    display: none;
  }
}
.about-image-group .about-shape2 {
  position: absolute;
  left: 4%;
  top: 30px;
  max-width: 250px;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .about-image-group .about-shape2 {
    left: 8%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-image-group .about-shape2 {
    max-width: 220px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .about-image-group .about-shape2 {
    max-width: 185px;
    left: 18%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .about-image-group .about-shape2 {
    max-width: 160px;
    left: 10%;
  }
}
@media (max-width: 575px) {
  .about-image-group .about-shape2 {
    max-width: 130px;
  }
}
.about-image-group .about-shape3 {
  position: absolute;
  right: 22%;
  top: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .about-image-group .about-shape3 {
    display: none;
  }
}

.tj-about-section-two {
  padding-top: 160px;
  padding-bottom: 150px;
  overflow: hidden;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-about-section-two {
    padding-bottom: 80px;
    padding-top: 90px;
  }
}
.tj-about-section-two .tj-sec-heading {
  margin-bottom: 0;
}

.tj-about-section-three {
  padding-top: 190px;
  padding-bottom: 130px;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .tj-about-section-three {
    padding-bottom: 85px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-about-section-three {
    padding-top: 90px;
    padding-bottom: 60px;
  }
}

.about-group-shape {
  opacity: 0.5;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .about-group-shape {
    display: none;
  }
}
.about-group-shape .bg-shape-one {
  position: absolute;
  top: -15%;
  left: -12%;
  opacity: 0.2;
}
.about-group-shape .bg-shape-two {
  position: absolute;
  top: -15%;
  left: -6%;
  opacity: 0.2;
}
.about-group-shape .bg-shape-three {
  position: absolute;
  top: -15%;
  left: 0;
  opacity: 0.2;
}
.about-group-shape .bg-shape-four {
  position: absolute;
  top: -15%;
  left: 6%;
  opacity: 0.2;
}
.about-group-shape .bg-shape-five {
  position: absolute;
  top: -15%;
  left: 12%;
  opacity: 0.2;
}
.about-group-shape .about-shape {
  position: absolute;
  bottom: 10%;
  left: 15%;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .about-group-shape .about-shape {
    display: none;
  }
}
.about-group-shape .about-shape1 {
  position: absolute;
  top: 12%;
  right: 13%;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .about-group-shape .about-shape1 {
    display: none;
  }
}

.about-image-group-two {
  position: relative;
  right: 125px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 3;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-image-group-two {
    right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .about-image-group-two {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    right: 0;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .about-image-group-two {
    right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .about-image-group-two {
    margin-bottom: 175px;
    margin-bottom: 175px;
  }
}
.about-image-group-two .shape-1 {
  position: absolute;
  top: -35px;
  right: -32px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-image-group-two .shape-1 {
    top: -45px;
  }
}
.about-image-group-two .image-1 {
  max-width: 315px;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-image-group-two .image-1 {
    max-width: 230px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-image-group-two .image-1 {
    max-width: 200px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .about-image-group-two .image-1 {
    max-width: 250px;
  }
}
@media (max-width: 575px) {
  .about-image-group-two .image-1 {
    max-width: 140px;
  }
}
.about-image-group-two .image-group-1 {
  position: relative;
  margin-right: 30px;
  max-width: 335px;
  width: 100%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-image-group-two .image-group-1 {
    max-width: 200px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-image-group-two .image-group-1 {
    max-width: 250px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .about-image-group-two .image-group-1 {
    max-width: 200px;
  }
}
@media (max-width: 575px) {
  .about-image-group-two .image-group-1 {
    max-width: 140px;
    margin-right: 20px;
  }
}
.about-image-group-two .image-group-1::before {
  position: absolute;
  content: "";
  top: -20px;
  left: -25px;
  width: 329px;
  height: 485px;
  background: var(--tj-color-light-5);
  z-index: -1;
  border-radius: 200px 200px 0 0;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-image-group-two .image-group-1::before {
    width: 255px;
    height: 370px;
    top: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-image-group-two .image-group-1::before {
    top: -15px;
    left: -10px;
    width: 210px;
    height: 320px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-image-group-two .image-group-1::before {
    top: -10px;
    left: -20px;
    width: 270px;
    height: 395px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-image-group-two .image-group-1::before {
    top: -10px;
    left: -20px;
    width: 220px;
    height: 320px;
  }
}
@media (max-width: 575px) {
  .about-image-group-two .image-group-1::before {
    top: -15px;
    left: -20px;
    width: 160px;
    height: 235px;
  }
}
.about-image-group-two .image-group-1 .about-lg-image {
  border-radius: 200px 200px 0 0;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-image-group-two .image-group-1 {
    max-width: 230px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .about-image-group-two .image-group-1 .shape-1 {
    display: none;
  }
}
.about-image-group-two .about-circle-box {
  position: absolute;
  right: 0;
  border-radius: 50%;
  border: 1px solid var(--tj-color-theme-primary);
  padding: 10px;
  top: -18%;
  height: 130px;
  width: 130px;
  -webkit-box-shadow: drop-shadow(30px 15px 30px rgba(0, 0, 0, 0.05));
          box-shadow: drop-shadow(30px 15px 30px rgba(0, 0, 0, 0.05));
  background: var(--tj-color-common-white);
}
.about-image-group-two .about-circle-box svg {
  fill: var(--tj-color-theme-primary);
  height: auto;
  max-width: 105px;
  -webkit-transform-origin: center;
      -ms-transform-origin: center;
          transform-origin: center;
  width: 100%;
  font-weight: var(--tj-fw-bold);
}
@media (max-width: 575px) {
  .about-image-group-two .about-circle-box {
    height: 100px;
    width: 100px;
  }
}
@media (max-width: 575px) {
  .about-image-group-two .about-circle-box .shape-1 {
    max-width: 75px;
  }
}
.about-image-group-two .about-circle-box::before {
  position: absolute;
  content: "";
  width: 22px;
  height: 22px;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  z-index: 9;
}
.about-image-group-two .image-group-2 {
  position: relative;
  top: 130px;
  right: -20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-image-group-two .image-group-2 {
    right: 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .about-image-group-two .image-group-2 {
    right: 0;
  }
}
.about-image-group-two .image-group-2::before {
  position: absolute;
  content: "";
  top: -25px;
  left: 30px;
  width: 310px;
  height: 490px;
  background: var(--tj-color-light-5);
  z-index: -1;
  border-radius: 0 0 200px 200px;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-image-group-two .image-group-2::before {
    top: -20px;
    left: 0;
    width: 250px;
    height: 360px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-image-group-two .image-group-2::before {
    top: -15px;
    left: 0;
    width: 215px;
    height: 300px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-image-group-two .image-group-2::before {
    top: -20px;
    left: 0;
    width: 270px;
    height: 385px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-image-group-two .image-group-2::before {
    top: -20px;
    left: 0;
    width: 275px;
    height: 390px;
  }
}
@media (max-width: 575px) {
  .about-image-group-two .image-group-2::before {
    top: -20px;
    left: 0;
    width: 160px;
    height: 230px;
  }
}
.about-image-group-two .image-group-2 .shape-1 {
  position: absolute;
  top: 10%;
  left: 10%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-animation: rotateImg 10s infinite linear;
          animation: rotateImg 10s infinite linear;
}
@media (max-width: 575px) {
  .about-image-group-two .image-group-2 .shape-1 {
    top: 11.5%;
    left: 11.5%;
  }
}
.about-image-group-two .image-group-2 .shape-2 {
  position: absolute;
  bottom: -45px;
  left: 0;
  border-radius: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .about-image-group-two .image-group-2 .shape-2 {
    display: none;
  }
}
.about-image-group-two .image-group-2 img {
  border-radius: 0 0 200px 200px;
}

.about-content-two .sec-title {
  max-width: 590px;
}
.about-content-two .desc {
  margin-bottom: 30px;
  max-width: 600px;
}
.about-content-two .about-category-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid rgba(158, 146, 255, 0.5);
  border-radius: 10px;
  background: var(--tj-color-common-white);
  margin-bottom: 30px;
  padding: 15px 30px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .about-content-two .about-category-item {
    padding: 15px 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .about-content-two .about-category-item {
    margin-bottom: 30px;
  }
}
.about-content-two .about-category-item .about-icon {
  margin-right: 20px;
  line-height: 1;
}
.about-content-two .about-category-item .about-icon i {
  color: var(--tj-color-light-3);
  font-size: 40px;
  position: relative;
  top: 3px;
  -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
          transform: rotate(0);
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .about-content-two .about-category-item .about-icon {
    margin-right: 10px;
  }
}
.about-content-two .about-category-item .about-text .title {
  font-weight: var(--tj-fw-medium);
  line-height: 25px;
  margin-bottom: 0;
  font-size: 18px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-content-two .about-category-item .about-text .title {
    font-size: 16px;
  }
}
.about-content-two .about-category-item:hover {
  -webkit-box-shadow: 30px 15px 30px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 30px 15px 30px 0px rgba(0, 0, 0, 0.05);
}
.about-content-two .about-button {
  margin-top: 10px;
}

.about-content-three .desc {
  margin-bottom: 30px;
}
.about-content-three .about-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
}
@media (max-width: 575px) {
  .about-content-three .about-item {
    display: block;
    margin-bottom: 45px;
  }
}
.about-content-three .about-item .about-icon {
  font-size: 25px;
  color: var(--tj-color-light-3);
  width: 60px;
  height: 60px;
  line-height: 65px;
  text-align: center;
  background: var(--tj-color-light-2);
  position: relative;
  z-index: 3;
  display: inline-block;
  -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
          transform: rotate(0);
}
@media (max-width: 575px) {
  .about-content-three .about-item .about-icon {
    margin-bottom: 25px;
  }
}
.about-content-three .about-item .about-icon .border-shadow {
  content: "";
  display: block;
  position: absolute;
  z-index: 9;
}
.about-content-three .about-item .about-icon .icon-shape .shadow-1 {
  top: 0;
  left: 0;
  background: var(--tj-color-light-3);
  width: 1px;
  height: 60px;
}
.about-content-three .about-item .about-icon .icon-shape .shadow-2 {
  top: 0;
  left: 0;
  background: -webkit-gradient(linear, left top, right top, color-stop(-100%, var(--tj-color-light-3)), color-stop(64%, rgba(21, 22, 37, 0)));
  background: linear-gradient(to right, var(--tj-color-light-3) -100%, rgba(21, 22, 37, 0) 64%);
  width: 100px;
  height: 1px;
}
.about-content-three .about-item .about-icon .icon-shape .shadow-3 {
  bottom: 0;
  left: 0;
  background: -webkit-gradient(linear, left top, right top, color-stop(-100%, var(--tj-color-light-3)), color-stop(64%, rgba(21, 22, 37, 0)));
  background: linear-gradient(to right, var(--tj-color-light-3) -100%, rgba(21, 22, 37, 0) 64%);
  width: 100px;
  height: 1px;
}
.about-content-three .about-item .about-icon .icon-shape .shadow-4 {
  bottom: 0;
  right: 0;
  background: -webkit-gradient(linear, left top, right top, color-stop(-100%, var(--tj-color-light-3)), color-stop(64%, rgba(21, 22, 37, 0)));
  background: linear-gradient(to right, var(--tj-color-light-3) -100%, rgba(21, 22, 37, 0) 64%);
  width: 1px;
  height: 60px;
}
.about-content-three .about-item .about-text {
  max-width: 510px;
  width: 100%;
}
.about-content-three .about-item .about-text .title {
  margin-bottom: 10px;
}
.about-content-three .about-item:hover .about-icon {
  -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
          transform: rotate(360deg);
}
.about-content-three .about-button {
  margin-top: 30px;
}

.about-image-group-three {
  max-width: 820px;
  width: 100%;
  position: relative;
  margin-left: -165px;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-image-group-three {
    margin-left: 0;
    max-width: 520px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .about-image-group-three {
    max-width: 450px;
    margin-bottom: 60px;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .about-image-group-three {
    max-width: 350px;
  }
}
@media (max-width: 575px) {
  .about-image-group-three {
    max-width: 250px;
  }
}
.about-image-group-three .gradient-shape {
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-mask-size: contain;
          mask-size: contain;
  width: 150px;
  height: 125px;
  position: absolute;
  right: -80px;
  top: 45px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .about-image-group-three .gradient-shape {
    display: none;
  }
}
.about-image-group-three .border-shadow {
  content: "";
  display: block;
  position: absolute;
  z-index: 9;
}
.about-image-group-three .border-shape .shadow-1 {
  top: -25px;
  left: 24px;
  background: var(--tj-color-light-3);
  width: 1px;
  height: 110%;
  -webkit-transform: skewX(-11deg);
      -ms-transform: skewX(-11deg);
          transform: skewX(-11deg);
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-image-group-three .border-shape .shadow-1 {
    left: 10px;
    height: 113%;
  }
}
@media (max-width: 575px) {
  .about-image-group-three .border-shape .shadow-1 {
    left: 10px;
    height: 113%;
    top: -15px;
  }
}
.about-image-group-three .border-shape .shadow-2 {
  top: -25px;
  left: 83px;
  background: -webkit-gradient(linear, left top, right top, color-stop(-100%, var(--tj-color-light-3)), color-stop(64%, rgba(21, 22, 37, 0)));
  background: linear-gradient(to right, var(--tj-color-light-3) -100%, rgba(21, 22, 37, 0) 64%);
  width: 100%;
  height: 1px;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .about-image-group-three .border-shape .shadow-2 {
    left: 70px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-image-group-three .border-shape .shadow-2 {
    left: 45px;
  }
}
@media (max-width: 575px) {
  .about-image-group-three .border-shape .shadow-2 {
    left: 35px;
    top: -15px;
  }
}
.about-image-group-three .border-shape .shadow-3 {
  bottom: -30px;
  left: -35px;
  background: -webkit-gradient(linear, left top, right top, color-stop(-100%, var(--tj-color-light-3)), color-stop(64%, rgba(21, 22, 37, 0)));
  background: linear-gradient(to right, var(--tj-color-light-3) -100%, rgba(21, 22, 37, 0) 64%);
  width: 105%;
  height: 1px;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .about-image-group-three .border-shape .shadow-3 {
    bottom: -18px;
    left: -20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-image-group-three .border-shape .shadow-3 {
    bottom: -18px;
    left: -25px;
  }
}
@media (max-width: 575px) {
  .about-image-group-three .border-shape .shadow-3 {
    bottom: -15px;
    left: -15px;
  }
}
.about-image-group-three .border-shape .shadow-4 {
  bottom: -30px;
  right: 33%;
  background: -webkit-gradient(linear, left top, right top, color-stop(-100%, var(--tj-color-light-3)), color-stop(64%, rgba(21, 22, 37, 0)));
  background: linear-gradient(to right, var(--tj-color-light-3) -100%, rgba(21, 22, 37, 0) 64%);
  width: 1px;
  height: 100%;
  -webkit-transform: skewX(-11deg);
      -ms-transform: skewX(-11deg);
          transform: skewX(-11deg);
}
@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .about-image-group-three .border-shape .shadow-4 {
    bottom: -20px;
  }
}
@media (max-width: 575px) {
  .about-image-group-three .border-shape .shadow-4 {
    bottom: -15px;
  }
}
.about-image-group-three .image-group-1 {
  max-width: 580px;
  width: 100%;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-mask-size: contain;
          mask-size: contain;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .about-image-group-three .image-group-1 {
    max-width: 450px;
  }
}
.about-image-group-three .image-group-2 {
  max-width: 345px;
  width: 100%;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-mask-size: contain;
          mask-size: contain;
  position: absolute;
  bottom: 36px;
  right: -16.5%;
  z-index: 9;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .about-image-group-three .image-group-2 {
    max-width: 260px;
    right: -12.5%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-image-group-three .image-group-2 {
    max-width: 210px;
  }
}
@media (max-width: 575px) {
  .about-image-group-three .image-group-2 {
    max-width: 150px;
  }
}
.about-image-group-three .about-group-shape .shape-1 {
  position: absolute;
  width: 105px;
  height: 105px;
  left: -74px;
  top: -74px;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-mask-size: contain;
          mask-size: contain;
  background: var(--tj-color-light-3);
}
.about-image-group-three .about-group-shape .shape-2 {
  position: absolute;
  right: -85px;
  top: 50px;
  height: 115px;
  width: 145px;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-mask-size: contain;
          mask-size: contain;
  background: var(--tj-color-light-3);
}
.about-image-group-three .about-group-shape .shape-3 {
  position: absolute;
  right: -95px;
  bottom: -45px;
  height: 225px;
  width: 175px;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-mask-size: contain;
          mask-size: contain;
  background: var(--tj-color-light-3);
}

/* !END: Theme About CSS */
/**----------------------------------------
START: Theme Counter CSS
----------------------------------------*/
.tj-counter-section {
  background: -webkit-gradient(linear, left top, right top, color-stop(-25.81%, #1c1c1c), color-stop(116.04%, #373636));
  background: linear-gradient(90deg, #1c1c1c -25.81%, #373636 116.04%);
  padding: 57px 0;
  position: relative;
  overflow: hidden;
}

.counter-section-shape .counter_shape {
  position: absolute;
  left: 0;
  bottom: 0;
}
.counter-section-shape .counter_shape1 {
  position: absolute;
  right: 0;
  bottom: 0;
}

.counter-content-area {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  position: relative;
  z-index: 9;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .counter-content-area {
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
  }
}
@media (max-width: 575px) {
  .counter-content-area {
    grid-template-columns: repeat(1, 1fr);
    text-align: center;
  }
}
.counter-content-area .counter-item {
  border-right: 1.5px solid rgba(161, 172, 179, 0.2);
  position: relative;
  z-index: 9;
  padding-bottom: 14px;
  padding-left: 50px;
}
.counter-content-area .counter-item:first-child {
  padding-left: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .counter-content-area .counter-item {
    border-right: none;
    padding-left: 0;
    margin-bottom: 30px;
  }
}
.counter-content-area .counter-item:last-child {
  border-right: none;
}
.counter-content-area .counter-item .tj-count {
  font-size: 80px;
  font-family: var(--tj-ff-heading);
  font-weight: var(--tj-fw-bold);
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.5);
  line-height: 1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .counter-content-area .counter-item .tj-count {
    font-size: 55px;
  }
}
@media (max-width: 575px) {
  .counter-content-area .counter-item .tj-count {
    font-size: 50px;
  }
}
.counter-content-area .counter-item .sub-title {
  font-size: 22px;
  color: var(--tj-color-common-white);
  font-weight: var(--tj-fw-bold);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .counter-content-area .counter-item .sub-title {
    font-size: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .counter-content-area .counter-item .sub-title {
    font-size: 16px;
  }
}

.tj-counter-section-two {
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  padding: 60px 0;
  position: relative;
}
.tj-counter-section-two::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.counter-content-two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
  z-index: 9;
}
@media (max-width: 575px) {
  .counter-content-two {
    display: block;
    text-align: center;
  }
}
.counter-content-two .counter-item {
  border-right: 1.5px solid rgba(32, 217, 161, 0.3);
  position: relative;
  z-index: 9;
  margin-bottom: 20px;
}
.counter-content-two .counter-item:last-child {
  border-right: none;
}
.counter-content-two .counter-item .tj-count {
  font-size: 80px;
  font-weight: var(--tj-fw-bold);
  font-family: var(--tj-ff-heading);
  opacity: 0.1;
  color: var(--tj-color-theme-secondary);
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .counter-content-two .counter-item .tj-count {
    font-size: 40px;
  }
}
.counter-content-two .counter-item .sub-title {
  font-size: 22px;
  color: var(--tj-color-common-white);
  font-weight: var(--tj-fw-bold);
  position: absolute;
  top: 50%;
  left: 25%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}

.counter-item-two {
  margin-bottom: 30px;
}
@media (max-width: 575px) {
  .counter-item-two {
    margin-bottom: 15px;
  }
}
.counter-item-two .tj-count {
  font-size: 60px;
  font-family: var(--tj-ff-heading);
  font-weight: var(--tj-fw-bold);
  color: var(--tj-color-common-white);
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .counter-item-two .tj-count {
    font-size: 40px;
  }
}
.counter-item-two .sub-title {
  color: var(--tj-color-common-white);
  font-weight: var(--tj-fw-medium);
  font-family: var(--tj-ff-heading);
  font-size: 18px;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter-item-two .sub-title {
    font-size: 16px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .counter-item-two .sub-title {
    font-size: 15px;
  }
}

/* !END: Theme Counter CSS */
/**----------------------------------------
START: Theme Service CSS
----------------------------------------*/
.tj-service-section {
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-service-section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.tj-service-section .tj-sec-heading {
  max-width: 780px;
  margin-bottom: 110px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-service-section .tj-sec-heading {
    margin-bottom: 95px;
  }
}

.service-section-shape .service-bg-shape {
  position: absolute;
  top: 35px;
  right: 95px;
  z-index: 3;
  opacity: 0.3;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .service-section-shape .service-bg-shape {
    display: none;
  }
}
.service-section-shape .service-bg-shape1 {
  position: absolute;
  top: 50px;
  right: 6%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .service-section-shape .service-bg-shape1 {
    display: none;
  }
}
.service-section-shape .service-bg-shape2 {
  position: absolute;
  left: 3%;
  bottom: 20%;
  opacity: 0.3;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .service-section-shape .service-bg-shape2 {
    display: none;
  }
}
.service-section-shape .service-bg-shape3 {
  position: absolute;
  left: 10%;
  bottom: 26%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .service-section-shape .service-bg-shape3 {
    display: none;
  }
}
.service-section-shape .service-bg-shape4 {
  position: absolute;
  left: 60px;
  top: 25%;
  opacity: 0.3;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .service-section-shape .service-bg-shape4 {
    display: none;
  }
}

.tj-service-item {
  border-radius: 20px;
  margin-bottom: 30px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  position: relative;
  z-index: 3;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  border: 1px solid var(--tj-color-light-2);
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-service-item {
    margin-bottom: 80px;
  }
}
.tj-service-item .service-inner {
  background-color: var(--tj-color-light-2);
  border-radius: 10px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  padding-bottom: 0;
}
.tj-service-item .service-icon {
  position: relative;
  width: 100px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -50px;
  z-index: 1;
  color: var(--tj-color-common-white);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
          transform: rotate(0);
}
.tj-service-item .service-icon i {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 45px;
  height: 45px;
  font-size: 45px;
  line-height: 1;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transform: translate(-50%, -50%) rotate(0);
      -ms-transform: translate(-50%, -50%) rotate(0);
          transform: translate(-50%, -50%) rotate(0);
}
.tj-service-item .service-icon .image-1,
.tj-service-item .service-icon .image-2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  z-index: -2;
}
.tj-service-item .service-icon .image-2 {
  -webkit-transform: translateY(30px);
      -ms-transform: translateY(30px);
          transform: translateY(30px);
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.tj-service-item .title-link {
  margin-bottom: 25px;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: var(--tj-color-common-black);
  -webkit-transition: 0.1s;
  transition: 0.1s;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-service-item .title-link {
    margin-bottom: 15px;
  }
}
.tj-service-item .title-link:hover {
  color: transparent;
}
.tj-service-item .service-content {
  padding: 20px 30px 35px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-service-item .service-content {
    padding: 25px 15px 25px;
  }
}
.tj-service-item .service-content p {
  margin-bottom: 0;
}
.tj-service-item:hover {
  background-color: transparent;
  -webkit-box-shadow: 30px 15px 30px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 30px 15px 30px 0px rgba(0, 0, 0, 0.05);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-color: transparent;
}
.tj-service-item:hover .service-icon i {
  -webkit-transform: translate(-50%, -50%) rotate(360deg);
      -ms-transform: translate(-50%, -50%) rotate(360deg);
          transform: translate(-50%, -50%) rotate(360deg);
}
.tj-service-item:hover .service-icon .image-2 {
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
}

.service-button {
  margin-top: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .service-button {
    margin-top: -20px;
  }
}

.tj-service-section-two {
  padding-top: 115px;
  padding-bottom: 120px;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-service-section-two {
    padding-top: 75px;
    padding-bottom: 80px;
  }
}
.tj-service-section-two .tj-sec-heading {
  max-width: 500px;
  margin-left: 0;
}

.service-group-shape .service-shape {
  position: absolute;
  top: 12%;
  right: 14%;
}
.service-group-shape .service-shape1 {
  position: absolute;
  bottom: 30%;
  left: 60px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .service-group-shape {
    display: none;
  }
}

.tj-service-item-two {
  display: grid;
  background: transparent;
  padding: 60px 0;
  grid-template-columns: repeat(2, 1fr);
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-service-item-two {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-service-item-two {
    padding: 45px 0;
  }
}
.tj-service-item-two:first-child {
  padding-top: 0;
}
.tj-service-item-two::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
}
.tj-service-item-two .service-content-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-service-item-two .service-content-info {
    margin-bottom: 15px;
  }
}
.tj-service-item-two .service-content-info .service-left-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.tj-service-item-two .service-content-info .service-wrapper {
  position: absolute;
  top: -15px;
  -webkit-transform: rotate(-10deg);
      -ms-transform: rotate(-10deg);
          transform: rotate(-10deg);
  right: 60px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  z-index: 3;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-service-item-two .service-content-info .service-wrapper {
    display: none;
  }
}
.tj-service-item-two .service-content-info .service-wrapper::before {
  position: absolute;
  content: "";
  top: -15px;
  left: 10px;
  width: 195px;
  height: 300px;
  background: var(--tj-color-light-5);
  z-index: -1;
  border-radius: 0 0 200px 200px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .tj-service-item-two .service-content-info .service-wrapper::before {
    left: 0;
    width: 145px;
    height: 205px;
  }
}
.tj-service-item-two .service-content-info .service-wrapper img {
  width: 100%;
  border-radius: 0 0 200px 200px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .tj-service-item-two .service-content-info .service-wrapper {
    right: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .tj-service-item-two .service-content-info .service-wrapper {
    max-width: 130px;
  }
}
.tj-service-item-two .service-left-content .service-icon {
  margin-right: 25px;
}
.tj-service-item-two .service-left-content .service-icon i {
  font-size: 60px;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: var(--tj-color-common-black);
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 575px) {
  .tj-service-item-two .service-left-content .service-icon {
    margin-right: 15px;
  }
}
.tj-service-item-two .service-left-content .service-text .title-link {
  margin-bottom: 0;
}
.tj-service-item-two .service-left-content .service-text .title-link a {
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  font-size: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-service-item-two .service-left-content .service-text .title-link a {
    font-size: 30px;
  }
}
.tj-service-item-two .service-left-content .number {
  font-size: 50px;
  -webkit-text-fill-color: var(--tj-color-common-white);
  -webkit-text-stroke: 1px var(--tj-color-common-black);
  font-family: var(--tj-ff-heading);
  font-weight: var(--tj-fw-bold);
  opacity: 0.5;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-service-item-two .service-left-content .number {
    font-size: 35px;
  }
}
.tj-service-item-two .service-middle-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.tj-service-item-two .service-middle-content .service-text .desc {
  max-width: 560px;
}
.tj-service-item-two .service-middle-content .service-text .desc p {
  margin-bottom: 0;
}
.tj-service-item-two .service-middle-content .service-arrow {
  font-size: 50px;
  color: var(--tj-color-common-black);
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tj-service-item-two .service-middle-content .service-arrow i {
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: var(--tj-color-common-black);
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-service-item-two .service-middle-content .service-arrow {
    font-size: 35px;
  }
}
.tj-service-item-two .service-middle-content .service-tags {
  margin-top: 30px;
}
.tj-service-item-two:hover .title-link a {
  color: transparent;
}
.tj-service-item-two:hover .service-icon i {
  color: transparent;
}
.tj-service-item-two:hover .number {
  -webkit-text-fill-color: var(--tj-color-common-white);
  -webkit-text-stroke: 1px var(--tj-color-theme-secondary);
}
.tj-service-item-two:hover .service-wrapper {
  opacity: 1;
  visibility: visible;
}
.tj-service-item-two:hover .service-arrow {
  -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
          transform: rotate(0deg);
}
.tj-service-item-two:hover .service-arrow i {
  color: transparent;
}

.tj-service-section-three {
  padding-top: 150px;
  padding-bottom: 20px;
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tj-service-section-three {
    padding-bottom: 90px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-service-section-three {
    padding-top: 90px;
    padding-bottom: 85px;
  }
}
.tj-service-section-three .tj-sec-heading {
  margin-bottom: 40px;
  max-width: 580px;
  margin-left: 0;
}
.tj-service-section-three .service-section-shape {
  top: 40%;
  left: 83px;
  position: absolute;
  width: 150px;
  height: 150px;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-mask-size: contain;
          mask-size: contain;
  background: var(--tj-color-light-3);
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-service-section-three .service-section-shape {
    display: none;
  }
}

.tj-service-item-three {
  border: 1px solid var(--tj-color-grey-7);
  border-radius: 8px;
  padding: 30px;
  position: relative;
  z-index: 3;
  margin-bottom: 100px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background: var(--tj-color-common-white);
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-service-item-three {
    margin-bottom: 30px;
    padding: 20px;
  }
}
.tj-service-item-three::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 60%;
  height: 100%;
  background: var(--tj-color-grey-5);
  border-radius: 8px;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}
.tj-service-item-three::after {
  position: absolute;
  content: "";
  top: 0;
  right: 45px;
  width: 0;
  height: 100%;
  border-top: 270px solid var(--tj-color-common-white);
  border-left: 100px solid transparent;
  background: var(--tj-color-grey-5);
  border-radius: 8px;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tj-service-item-three::after {
    right: 85px;
    border-top: 222px solid var(--tj-color-common-white);
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tj-service-item-three::after {
    right: 85px;
    border-top: 240px solid var(--tj-color-common-white);
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tj-service-item-three::after {
    right: 85px;
    border-top: 243px solid var(--tj-color-common-white);
  }
}
@media (max-width: 575px) {
  .tj-service-item-three::after {
    right: 85px;
    border-top: 240px solid var(--tj-color-common-white);
  }
}
.tj-service-item-three .service-icon {
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  display: inline-block;
  background: var(--tj-color-light-7);
  border-radius: 5px;
  color: var(--tj-color-common-black);
  font-size: 30px;
  margin-bottom: 20px;
  -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
          transform: rotate(0);
}
.tj-service-item-three .title {
  margin-bottom: 20px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-service-item-three .title {
    margin-bottom: 10px;
  }
}
.tj-service-item-three .title:hover {
  color: var(--tj-color-theme-primary);
}
.tj-service-item-three p {
  margin-bottom: 0;
}
.tj-service-item-three .border-inner {
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-service-item-three .border-inner {
    display: none;
  }
}
.tj-service-item-three .border-inner::before {
  position: absolute;
  content: "";
  width: 145%;
  height: 1px;
  bottom: -80px;
  left: -75px;
  background: var(--tj-color-grey-7);
}
.tj-service-item-three .border-inner::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 15px solid var(--tj-color-grey-7);
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  left: 50%;
  top: 65px;
}
.tj-service-item-three .border-inner2 {
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-service-item-three .border-inner2 {
    display: none;
  }
}
.tj-service-item-three .border-inner2::before {
  position: absolute;
  content: "";
  width: 145%;
  height: 1px;
  top: -83px;
  right: -80px;
  background: var(--tj-color-grey-7);
}
.tj-service-item-three .border-inner2::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 15px solid var(--tj-color-grey-7);
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  left: 50%;
  bottom: 68px;
}
.tj-service-item-three:hover {
  border-color: var(--tj-color-light-3);
}
.tj-service-item-three:hover .service-icon {
  -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
          transform: rotate(360deg);
}
.tj-service-item-three:hover::before {
  opacity: 1;
  visibility: visible;
}
.tj-service-item-three:hover::after {
  opacity: 1;
  visibility: visible;
}
.tj-service-item-three:hover .border-inner::after {
  border-bottom: 15px solid var(--tj-color-light-3);
}
.tj-service-item-three:hover .border-inner2::after {
  border-top: 15px solid var(--tj-color-light-3);
}

.tj-service-video-content {
  position: relative;
  padding-left: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-service-video-content {
    padding-left: 0;
  }
}
.tj-service-video-content .desc {
  max-width: 485px;
  margin-bottom: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-service-video-content .desc {
    max-width: 100%;
  }
}
.tj-service-video-content .desc p {
  margin-bottom: 0;
}
.tj-service-video-content .video-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
}
.tj-service-video-content .video-area .video-play a {
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  font-size: 22px;
  color: var(--tj-color-common-black);
  display: inline-block;
  text-align: center;
  background: var(--tj-color-light-7);
}
.tj-service-video-content .video-text .title {
  color: var(--tj-color-text-body);
  font-size: 18px;
  font-weight: var(--tj-fw-medium);
}
.tj-service-video-content .video-shape {
  position: absolute;
  right: 0;
  top: 60px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-service-video-content .video-shape {
    display: none;
  }
}

/* !END: Theme Service CSS */
/**----------------------------------------
START: Theme Service CSS
----------------------------------------*/
.tj-service-details {
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;
  overflow: hidden;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-service-details {
    padding-top: 80px;
    padding-bottom: 55px;
  }
}
.tj-service-details .tj-services-shape .shape-one {
  position: absolute;
  bottom: 0;
  left: 0;
}
.tj-service-details .tj-services-shape .shape-two {
  position: absolute;
  bottom: 0;
  right: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-service-details .tj-services-shape {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-main-sidebar {
    margin-top: 60px;
  }
}
.service-inner {
  padding-bottom: 45px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .service-inner {
    padding-bottom: 15px;
  }
}
.service-inner .tj-service-item {
  margin-bottom: 80px;
}

.service-wrapper .services-item {
  padding-top: 45px;
  margin-bottom: 30px;
}
.service-wrapper .services-item p {
  margin-bottom: 0;
}
.service-wrapper .service-details-item {
  background: var(--tj-color-light-2);
  border-radius: 20px;
  padding: 0 30px 30px;
  margin-bottom: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .service-wrapper .service-details-item {
    padding: 0 20px 20px;
  }
}
@media (max-width: 575px) {
  .service-wrapper .service-details-item {
    margin-bottom: 70px;
  }
}
.service-wrapper .service-details-item .service-icon {
  background: var(--tj-color-light-7);
  width: 70px;
  height: 70px;
  line-height: 76px;
  text-align: center;
  display: inline-block;
  border-radius: 10px;
  margin-top: -35px;
  margin-bottom: 30px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
          transform: rotate(0);
}
.service-wrapper .service-details-item .service-icon i {
  font-size: 30px;
  color: var(--tj-color-light-3);
}
.service-wrapper .service-details-item .service-content .title {
  margin-bottom: 15px;
}
.service-wrapper .service-details-item .service-content p {
  margin-bottom: 0;
}
.service-wrapper .service-details-item:hover .service-icon {
  -webkit-transform: rotate(-360deg);
      -ms-transform: rotate(-360deg);
          transform: rotate(-360deg);
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .service-wrapper .blog-video-area .video-image {
    margin-bottom: 30px;
  }
}
.service-wrapper .blog-video-area .video-content .check-list ul li {
  font-weight: var(--tj-fw-bold);
}
.service-wrapper .tj-faq-area {
  padding-left: 0;
}

.tj-services-widget {
  background: -webkit-gradient(linear, left top, right top, from(rgba(32, 217, 161, 0.08)), to(rgba(95, 57, 255, 0.08)));
  background: linear-gradient(90deg, rgba(32, 217, 161, 0.08) 0%, rgba(95, 57, 255, 0.08) 100%);
  margin-bottom: 24px;
  border-radius: 10px;
  padding: 40px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-services-widget {
    padding: 35px 15px;
  }
}
.tj-services-widget .side-title {
  font-size: 25px;
  color: var(--tj-color-light-3);
  position: relative;
  margin-bottom: 35px;
}
.tj-services-widget .side-title::before {
  position: absolute;
  content: "";
  bottom: -10px;
  left: 0;
  width: 90px;
  height: 3px;
  background: var(--tj-color-light-3);
}

.sidebar-contact {
  position: relative;
}
.sidebar-contact .contact-icon {
  width: 70px;
  height: 70px;
  line-height: 65px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid var(--tj-color-light-3);
  margin-bottom: 20px;
  position: relative;
  z-index: 3;
}
.sidebar-contact .contact-icon img {
  max-width: 38px;
  width: 100%;
}
.sidebar-contact .contact-link {
  margin-bottom: 10px;
  position: relative;
  z-index: 3;
}
.sidebar-contact .contact-link a {
  font-size: 35px;
  font-weight: var(--tj-fw-bold);
  color: var(--tj-color-light-3);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.sidebar-contact .contact-link a:hover {
  color: var(--tj-color-common-black);
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .sidebar-contact .contact-link a {
    font-size: 25px;
  }
}
.sidebar-contact span {
  font-size: 18px;
  font-weight: var(--tj-fw-medium);
  position: relative;
  z-index: 3;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .sidebar-contact span {
    font-size: 16px;
  }
}
.sidebar-contact .contact-shape {
  position: absolute;
  bottom: -40px;
  left: -40px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .sidebar-contact .contact-shape {
    display: none;
  }
}

/* !END: Theme Service CSS */
/**----------------------------------------
START: Theme Testimonial CSS
----------------------------------------*/
.tj-testimonial-section {
  padding-bottom: 115px;
  padding-top: 120px;
  background: var(--tj-color-light-2);
  overflow-x: hidden;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-testimonial-section {
    padding-bottom: 75px;
    padding-top: 80px;
  }
}

.testimonial-section-shape .testmonial-shape1 {
  position: absolute;
  top: 5%;
  right: 30px;
  opacity: 0.3;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .testimonial-section-shape .testmonial-shape1 {
    display: none;
  }
}
.testimonial-section-shape .testmonial-shape2 {
  position: absolute;
  top: 8%;
  right: 5%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .testimonial-section-shape .testmonial-shape2 {
    display: none;
  }
}
.testimonial-section-shape .testmonial-shape3 {
  position: absolute;
  bottom: 20%;
  left: 12%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .testimonial-section-shape .testmonial-shape3 {
    display: none;
  }
}
.testimonial-section-shape .testmonial-shape4 {
  position: absolute;
  bottom: 10%;
  left: 7%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .testimonial-section-shape .testmonial-shape4 {
    display: none;
  }
}

.testimonial-slider {
  margin-left: calc((100% - 1340px) / 2);
  width: auto;
  min-width: auto;
  max-width: inherit;
  padding-right: 0;
  margin-right: -275px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .testimonial-slider {
    margin-left: calc((100% - 1140px) / 2);
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-slider {
    margin-left: calc((100% - 960px) / 2);
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-slider {
    margin-left: calc((100% - 745px) / 2);
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .testimonial-slider {
    margin-left: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .testimonial-slider {
    margin-right: 15px;
  }
}
.testimonial-slider .swiper-pagination {
  width: auto;
  right: 25%;
  left: auto;
  bottom: 15px;
  height: 8px;
  width: 168px;
  border-radius: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-slider .swiper-pagination {
    right: 30%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-slider .swiper-pagination {
    right: 35%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .testimonial-slider .swiper-pagination {
    right: auto;
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}
.testimonial-slider .swiper-pagination .swiper-pagination-bullet {
  display: inline-block;
}
.testimonial-slider .swiper-pagination .swiper-pagination-bullet:last-child {
  margin-bottom: 0;
}
.testimonial-slider .swiper-pagination-bullet {
  width: 48px;
  height: 8px;
  cursor: pointer;
  border-radius: 10px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background: transparent;
  position: relative;
  top: -9px;
  background: rgba(95, 57, 255, 0.5);
}
.testimonial-slider .swiper-pagination-bullet-active {
  width: 48px;
  height: 8px;
  position: relative;
  border-radius: 50px;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
}

.testimonial-top-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 60px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 20px;
  position: relative;
  z-index: 3;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .testimonial-top-area {
    margin-bottom: 50px;
  }
}
.testimonial-top-area .testimonial-right-content {
  margin-top: 15px;
}
.testimonial-top-area .testimonial-right-content p {
  max-width: 570px;
  margin-bottom: 0;
}
.testimonial-top-area .tj-sec-heading {
  margin: 0;
  max-width: 500px;
}
.testimonial-top-area .tj-sec-heading .sec-title {
  margin-bottom: 0;
}

.testimonial-item {
  border-radius: 30px;
  border: 1px solid transparent;
  opacity: 0.9;
  max-width: 610px;
  padding: 45px 50px;
  margin-bottom: 80px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background: var(--tj-color-common-white);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .testimonial-item {
    padding: 35px 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .testimonial-item {
    margin-bottom: 70px;
  }
}
.testimonial-item .testimonial-top-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.testimonial-item .testimonial-top-content .testimonial-left-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 20px;
  margin-bottom: 30px;
  position: relative;
}
.testimonial-item .testimonial-top-content .testimonial-left-content::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
  background: var(--tj-color-grey-1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.testimonial-item .testimonial-top-content .testimonial-left-content .auother-image {
  border: 1px solid var(--tj-color-theme-secondary);
  padding: 5px;
  border-radius: 50%;
  margin-right: 20px;
}
.testimonial-item .testimonial-top-content .testimonial-left-content .auother-image img {
  border-radius: 50%;
}
@media (max-width: 575px) {
  .testimonial-item .testimonial-top-content .testimonial-left-content .auother-image {
    margin-right: 0;
    margin-bottom: 15px;
  }
}
.testimonial-item .testimonial-quote {
  max-width: 60px;
  margin: auto;
}
.testimonial-item .title {
  margin-bottom: 25px;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: var(--tj-color-common-black);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.testimonial-item .testimonial-right-content .testimonial-rating {
  border-radius: 5px;
  background: var(--tj-color-common-white);
  -webkit-box-shadow: 30px 15px 30px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 30px 15px 30px 0px rgba(0, 0, 0, 0.05);
  padding: 8px 20px;
  margin-bottom: 30px;
  width: 150px;
  text-align: center;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .testimonial-item .testimonial-right-content .testimonial-rating {
    padding: 8px 10px;
  }
}
.testimonial-item .testimonial-right-content .testimonial-rating ul li {
  display: inline-block;
}
.testimonial-item .testimonial-right-content .testimonial-rating ul li i {
  color: var(--tj-color-extra-2);
}
.testimonial-item .test-title {
  font-size: 20px;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: var(--tj-color-common-black);
  display: inline-block;
}
.testimonial-item .auother-text .title {
  margin-bottom: 5px;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.testimonial-item p {
  margin-bottom: 0;
}
.testimonial-item:hover {
  border-color: var(--tj-color-theme-primary);
  border-radius: 30px;
  background: var(--tj-color-common-white);
  -webkit-box-shadow: 30px 15px 30px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 30px 15px 30px 0px rgba(0, 0, 0, 0.05);
}
.testimonial-item:hover .testimonial-left-content::before {
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
}
.testimonial-item:hover .testimonial-quote svg path {
  fill: var(--tj-color-theme-primary);
  opacity: 0.3;
}
.testimonial-item:hover .test-title {
  color: transparent;
}
.testimonial-item:hover .title {
  color: transparent;
}

.tj-testimonial-section-two {
  padding-top: 120px;
  padding-bottom: 120px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-testimonial-section-two {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.tj-testimonial-section-two .tj-sec-heading {
  max-width: 550px;
  margin-left: 0;
}

.tj-testimonial-item-two .testimonial-top-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 30px;
}
.tj-testimonial-item-two .testimonial-top-header .thumb-image {
  margin-right: 25px;
  position: relative;
}
.tj-testimonial-item-two .testimonial-top-header .thumb-image .thumb-1 {
  border: 1px solid var(--tj-color-theme-primary);
  padding: 7px;
  border-radius: 50%;
  position: relative;
}
.tj-testimonial-item-two .testimonial-top-header .thumb-image .quote-1 {
  position: absolute;
  left: -25px;
  bottom: 0;
  max-width: 50px;
}
.tj-testimonial-item-two .testimonial-review {
  margin-bottom: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.tj-testimonial-item-two .testimonial-review .left-content {
  margin-right: 60px;
}
@media (max-width: 575px) {
  .tj-testimonial-item-two .testimonial-review .left-content {
    margin-right: 0;
  }
}
.tj-testimonial-item-two .testimonial-review .left-content .title {
  margin-bottom: 0;
}
.tj-testimonial-item-two .testimonial-review .testimonial-rating {
  border-radius: 5px;
  background: var(--tj-color-common-white);
  -webkit-box-shadow: 30px 15px 30px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 30px 15px 30px 0px rgba(0, 0, 0, 0.05);
  padding: 10px 20px;
}
.tj-testimonial-item-two .testimonial-review .testimonial-rating ul li {
  display: inline-block;
  color: var(--tj-color-extra-2);
}
.tj-testimonial-item-two .desc {
  margin-bottom: 45px;
}
.tj-testimonial-item-two .desc p {
  font-size: 18px;
  font-family: var(--tj-ff-heading);
  font-weight: var(--tj-fw-medium);
  line-height: 35px;
}

.tj-testimonial-slider2 {
  padding-left: 30px;
}
.tj-testimonial-slider2 .swiper-pagination {
  width: auto;
  left: 65px;
  bottom: 0;
  -webkit-transform: translate(-50%);
      -ms-transform: translate(-50%);
          transform: translate(-50%);
}
.tj-testimonial-slider2 .swiper-pagination .swiper-pagination-bullet {
  display: inline-block;
}
.tj-testimonial-slider2 .swiper-pagination .swiper-pagination-bullet:last-child {
  margin-bottom: 0;
}
.tj-testimonial-slider2 .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  cursor: pointer;
  border-radius: 50%;
  background: var(--tj-color-text-body);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin: 0 7px !important;
}
.tj-testimonial-slider2 .swiper-pagination-bullet-active {
  width: 10px;
  height: 10px;
  position: relative;
  border-radius: 50%;
  background-color: var(--tj-color-common-white);
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
}
.tj-testimonial-slider2 .swiper-pagination-bullet-active::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border: 2px solid var(--tj-color-theme-secondary);
  width: 22px;
  height: 22px;
  border-radius: 50%;
}

.testimonial-image-group {
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .testimonial-image-group {
    margin-bottom: 50px;
  }
}
.testimonial-image-group .group-image {
  border-radius: 50%;
  padding: 18px;
}
@media (max-width: 575px) {
  .testimonial-image-group .group-image {
    padding: 0;
  }
}
.testimonial-image-group .group-image::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}
@media (max-width: 575px) {
  .testimonial-image-group .group-image::before {
    display: none;
  }
}
.testimonial-image-group .group-image img {
  border-radius: 50%;
}
.testimonial-image-group .shape-1 {
  position: absolute;
  right: 10%;
  bottom: 10%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-image-group .shape-1 {
    right: 25%;
  }
}
.testimonial-image-group .shape-2 {
  position: absolute;
  right: 22%;
  bottom: 30%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-image-group .shape-2 {
    right: 35%;
  }
}

.tj-testimonial-section-three {
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-testimonial-section-three {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.tj-testimonial-section-three .thumb-area {
  max-width: 420px;
  width: 100%;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-testimonial-section-three .thumb-area {
    margin-bottom: 40px;
  }
}
.tj-testimonial-section-three .thumb-area .swiper-wrapper {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-testimonial-section-three .thumb-area .swiper-wrapper {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.tj-testimonial-section-three .thumb-area .thumb_slider {
  margin-bottom: 20px;
}
.tj-testimonial-section-three .thumb-area .thumb_slider .thumb_slide {
  width: 122px !important;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tj-testimonial-section-three .thumb-area .thumb_slider .thumb_slide {
    width: 110px !important;
  }
}
@media (max-width: 575px) {
  .tj-testimonial-section-three .thumb-area .thumb_slider .thumb_slide {
    width: 100px !important;
  }
}
.tj-testimonial-section-three .thumb-area .thumb_slider .thumb_slide .thumb-1 {
  position: relative;
  border-radius: 50%;
  border: 3.11px solid var(--tj-color-light-3);
  max-width: 100px;
  width: 100%;
  cursor: pointer;
}
@media (max-width: 575px) {
  .tj-testimonial-section-three .thumb-area .thumb_slider .thumb_slide .thumb-1 {
    max-width: 60px;
  }
}
.tj-testimonial-section-three .thumb-area .thumb_slider .thumb_slide .thumb-1::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
}
.tj-testimonial-section-three .thumb-area .thumb_slider .thumb_slide .thumb-1 img {
  width: 100%;
  border-radius: 50%;
  max-width: 100px;
}
.tj-testimonial-section-three .thumb-area .thumb_slider .thumb_slide .thumb-arrow {
  position: absolute;
  bottom: 0;
  right: 15px;
  color: var(--tj-color-light-3);
  width: 37px;
  height: 37px;
  line-height: 43px;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  background: var(--tj-color-light-8);
}
.tj-testimonial-section-three .thumb-area .thumb_slider .thumb_slide .thumb-arrow::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
}
.tj-testimonial-section-three .thumb-area .thumb_slider .thumb_slide.swiper-slide-thumb-active {
  margin-right: 30px !important;
}
@media (max-width: 575px) {
  .tj-testimonial-section-three .thumb-area .thumb_slider .thumb_slide.swiper-slide-thumb-active {
    margin-right: 20px !important;
  }
}
.tj-testimonial-section-three .thumb-area .thumb_slider .thumb_slide.swiper-slide-thumb-active .thumb-1 {
  max-width: 110px;
  width: 100%;
  border-radius: 50%;
}
.tj-testimonial-section-three .thumb-area .thumb_slider .thumb_slide.swiper-slide-thumb-active .thumb-1 img {
  border-radius: 50%;
  max-width: 110px;
  width: 100%;
}
.tj-testimonial-section-three .thumb-area .thumb_slider .thumb_slide.swiper-slide-thumb-active .thumb-1::before {
  display: none;
}
@media (max-width: 575px) {
  .tj-testimonial-section-three .thumb-area .thumb_slider .thumb_slide.swiper-slide-thumb-active .thumb-1 {
    max-width: 80px;
  }
}
.tj-testimonial-section-three .thumb-area .thumb_slider .thumb_slide.swiper-slide-thumb-active .thumb-arrow {
  width: 51px;
  height: 51px;
  line-height: 63px;
  right: -5px;
}
.tj-testimonial-section-three .thumb-area .thumb_slider .thumb_slide.swiper-slide-thumb-active .thumb-arrow::before {
  display: none;
}
.tj-testimonial-section-three .thumb-area .testimonial-info {
  text-align: center;
}
.tj-testimonial-section-three .thumb-area .testimonial-info .title {
  font-size: 25px;
  margin-bottom: 7px;
}
.tj-testimonial-section-three .thumb-area .testimonial-info .sub-tilte {
  font-size: 15px;
  font-weight: var(--tj-fw-medium);
}
.tj-testimonial-section-three .testimonial-navigation .swiper-button-next,
.tj-testimonial-section-three .testimonial-navigation .swiper-button-prev {
  position: absolute;
  bottom: 0;
  top: auto;
  left: 0;
  border-radius: 3px;
  width: 67px;
  height: 67px;
  line-height: 67px;
  text-align: center;
  color: var(--tj-color-light-3);
  background: rgba(214, 209, 255, 0.1);
  font-size: 25px;
  z-index: 1;
  border-radius: 50%;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-testimonial-section-three .testimonial-navigation .swiper-button-next,
  .tj-testimonial-section-three .testimonial-navigation .swiper-button-prev {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 22px;
  }
}
.tj-testimonial-section-three .testimonial-navigation .swiper-button-next::before,
.tj-testimonial-section-three .testimonial-navigation .swiper-button-prev::before {
  position: absolute;
  content: "\f060";
  font-family: "Font Awesome 6 Pro";
}
.tj-testimonial-section-three .testimonial-navigation .swiper-button-next:hover,
.tj-testimonial-section-three .testimonial-navigation .swiper-button-prev:hover {
  background-color: var(--tj-color-light-3);
  color: var(--tj-color-common-white);
}
.tj-testimonial-section-three .testimonial-navigation .swiper-button-next {
  left: 85px;
}
.tj-testimonial-section-three .testimonial-navigation .swiper-button-next::before {
  content: "\f061";
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-testimonial-section-three .testimonial-navigation .swiper-button-next {
    left: 65px;
  }
}
.tj-testimonial-section-three .testimonial-navigation .swiper-button-prev:after,
.tj-testimonial-section-three .testimonial-navigation .swiper-button-next:after {
  display: none;
}
.tj-testimonial-section-three .testimonial-sec-shape .shape-one {
  position: absolute;
  top: 15%;
  right: 5%;
}
.tj-testimonial-section-three .testimonial-sec-shape .shape-two {
  position: absolute;
  top: 15%;
  right: 10%;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-mask-size: contain;
          mask-size: contain;
  background: var(--tj-color-light-3);
  height: 225px;
  width: 175px;
  opacity: 0.3;
}
.tj-testimonial-section-three .testimonial-sec-shape .shape-three {
  position: absolute;
  bottom: 4%;
  left: 4%;
  -webkit-transform: rotate(-80deg);
      -ms-transform: rotate(-80deg);
          transform: rotate(-80deg);
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-testimonial-section-three .testimonial-sec-shape {
    display: none;
  }
}

.testimonial-slider-item {
  margin-bottom: 105px;
}
.testimonial-slider-item .testimonial-rating {
  border-radius: 25px;
  background: var(--tj-color-common-white);
  -webkit-box-shadow: 0px 4.4px 20px -1px rgba(19, 16, 34, 0.05);
          box-shadow: 0px 4.4px 20px -1px rgba(19, 16, 34, 0.05);
  display: inline-block;
  padding: 8px 15px;
  margin-bottom: 30px;
}
.testimonial-slider-item .testimonial-rating ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 2px;
}
.testimonial-slider-item .testimonial-rating ul li {
  color: var(--tj-color-extra-2);
}
.testimonial-slider-item p {
  font-size: 20px;
  font-weight: var(--tj-fw-medium);
  margin-bottom: 24px;
}
.testimonial-slider-item p:last-child {
  margin-bottom: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .testimonial-slider-item p {
    font-size: 18px;
  }
}

/* !END: Theme Testimonial CSS */
/**----------------------------------------
START: Theme Team CSS
----------------------------------------*/
.tj-team-section {
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-team-section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.tj-team-section .tj-sec-heading {
  max-width: 760px;
  position: relative;
  z-index: 3;
}
.tj-team-section .team-button {
  margin-top: 30px;
  position: relative;
  z-index: 3;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-team-section .team-button {
    margin-top: 20px;
  }
}

.tj-team-section-two {
  background: var(--tj-color-light-2);
  padding-top: 120px;
  padding-bottom: 90px;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-team-section-two {
    padding-top: 80px;
    padding-bottom: 50px;
  }
}
.tj-team-section-two .tj-sec-heading {
  max-width: 760px;
  position: relative;
  z-index: 3;
}
.tj-team-section-two .team-shape {
  position: absolute;
  left: 10%;
  top: 15%;
  opacity: 0.5;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-team-section-two .team-shape {
    display: none;
  }
}

.team-section-shape .team-bg-shape {
  position: absolute;
  top: 0;
  right: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .team-section-shape .team-bg-shape {
    width: 50%;
  }
}
.team-section-shape .team-bg-shape1 {
  position: absolute;
  bottom: 0;
  left: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .team-section-shape .team-bg-shape1 {
    display: none;
  }
}
.team-section-shape .team-bg-shape2 {
  position: absolute;
  top: 10%;
  left: 8%;
  opacity: 0.3;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .team-section-shape .team-bg-shape2 {
    display: none;
  }
}

.tj-team-item {
  margin-bottom: 30px;
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  z-index: 3;
  overflow: hidden;
}
.tj-team-item .team-image {
  position: relative;
  overflow: hidden;
  border-radius: 11px;
}
.tj-team-item .team-image a {
  display: inline-block;
  width: 100%;
}
.tj-team-item .team-image img {
  border-radius: 11px;
  width: 100%;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
}
.tj-team-item .tj-team-content {
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  text-align: center;
  -webkit-transform: translate(-50%, -50%) scale(0);
      -ms-transform: translate(-50%, -50%) scale(0);
          transform: translate(-50%, -50%) scale(0);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tj-team-item .tj-team-content::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  opacity: 0.6;
  width: 0;
  height: 0;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  -webkit-transition: 0.6s;
  transition: 0.6s;
}
.tj-team-item .tj-team-content .team-header {
  position: relative;
  z-index: 1;
  margin-bottom: 15px;
}
.tj-team-item .tj-team-content .team-header .title-link {
  margin-bottom: 0;
}
.tj-team-item .tj-team-content .team-header .title-link a {
  color: var(--tj-color-common-white);
}
.tj-team-item .tj-team-content .team-header .sub-title {
  color: var(--tj-color-common-white);
  font-size: 14px;
  font-weight: var(--tj-fw-medium);
}
.tj-team-item .tj-team-content .team-share {
  background: var(--tj-color-common-white);
  display: inline-block;
  border-radius: 25px;
  padding: 12px 25px;
  position: relative;
  z-index: 3;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .tj-team-item .tj-team-content .team-share {
    padding: 10px 10px 10px;
  }
}
.tj-team-item .tj-team-content .team-share ul li {
  display: inline-block;
  position: relative;
  margin-right: 24px;
  width: 15px;
}
.tj-team-item .tj-team-content .team-share ul li:last-child {
  margin-right: 0;
}
.tj-team-item .tj-team-content .team-share ul li::before {
  position: absolute;
  content: "";
  top: 5px;
  right: -15px;
  height: 16px;
  width: 1px;
  opacity: 0.2;
  background: var(--tj-color-common-black);
}
.tj-team-item .tj-team-content .team-share ul li:last-child::before {
  display: none;
}
.tj-team-item .tj-team-content .team-share ul li a {
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: var(--tj-color-common-black);
  -webkit-transition: 0.1s;
  transition: 0.1s;
  min-width: 25px;
}
.tj-team-item .tj-team-content .team-share ul li a:hover {
  color: transparent;
}
.tj-team-item:hover .team-image img {
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
}
.tj-team-item:hover .tj-team-content {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(-50%, -50%) scale(1);
      -ms-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
}
.tj-team-item:hover .tj-team-content::before {
  width: 230px;
  height: 230px;
}

.tj-team-item-two {
  position: relative;
  border-radius: 10px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-bottom: 30px;
}
.tj-team-item-two::before {
  position: absolute;
  content: "";
  border-radius: 10px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(35deg, var(--tj-color-light-3) 22.13%, rgba(112, 95, 255, 0) 85.06%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tj-team-item-two .team-image img {
  border-radius: 10px;
  width: 100%;
}
.tj-team-item-two .tj-team-content .team-header {
  position: absolute;
  bottom: 0;
  left: 30px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tj-team-item-two .tj-team-content .team-header .title-link {
  margin-bottom: 0;
}
.tj-team-item-two .tj-team-content .team-header .title-link a {
  color: var(--tj-color-common-white);
}
.tj-team-item-two .tj-team-content .team-header .sub-title {
  color: var(--tj-color-common-white);
  font-size: 14px;
  font-weight: var(--tj-fw-regular);
}
.tj-team-item-two .tj-team-content .team-share {
  background: var(--tj-color-common-white);
  display: inline-block;
  border-radius: 0 0 10px 10px;
  position: relative;
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tj-team-item-two .tj-team-content .team-share ul li {
  position: relative;
  padding: 9px 16px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tj-team-item-two .tj-team-content .team-share ul li {
    padding: 5px 12px;
  }
}
.tj-team-item-two .tj-team-content .team-share ul li::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  height: 1px;
  width: 16px;
  background: rgba(58, 58, 58, 0.2);
}
.tj-team-item-two .tj-team-content .team-share ul li:last-child::before {
  display: none;
}
.tj-team-item-two .tj-team-content .team-share ul li a {
  color: var(--tj-color-common-black);
  -webkit-transition: 0.1s;
  transition: 0.1s;
  font-size: 18px;
}
.tj-team-item-two .tj-team-content .team-share ul li a:hover {
  color: var(--tj-color-light-3);
}
.tj-team-item-two:hover::before {
  opacity: 1;
  visibility: visible;
}
.tj-team-item-two:hover .team-header {
  bottom: 30px;
  opacity: 1;
  visibility: visible;
}
.tj-team-item-two:hover .team-share {
  left: 30px;
  opacity: 1;
  visibility: visible;
}

/* !END: Theme Team CSS */
/**----------------------------------------
START: Theme Portfolio CSS
----------------------------------------*/
.tj-portfolio-section {
  background: linear-gradient(117deg, var(--tj-color-grey-2) 8.79%, var(--tj-color-grey-3) 98.63%);
  padding-top: 115px;
  padding-bottom: 105px;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-portfolio-section {
    padding-top: 75px;
    padding-bottom: 65px;
  }
}
.tj-portfolio-section .tj-sec-heading {
  margin-bottom: 0;
}
.tj-portfolio-section .tj-sec-heading .sec-title {
  margin-bottom: 0;
  color: var(--tj-color-common-white);
}
.tj-portfolio-section .portfolio-header {
  margin-bottom: 40px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-portfolio-section .portfolio-header {
    margin-bottom: 30px;
  }
}
.tj-portfolio-section .portfolio-header .filter-menu {
  text-align: end;
  position: relative;
  z-index: 3;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-portfolio-section .portfolio-header .filter-menu {
    text-align: left;
    margin-top: 30px;
  }
}
.tj-portfolio-section .portfolio-header .filter-menu .active {
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
  font-weight: var(--tj-fw-medium);
}
.tj-portfolio-section .portfolio-header .filter-menu .filter {
  color: var(--tj-color-common-white);
  font-size: 18px;
  font-weight: var(--tj-fw-medium);
  margin-right: 30px;
}
.tj-portfolio-section .portfolio-header .filter-menu .filter:last-child {
  margin-right: 0;
}

.tj-portfolio-page {
  padding-top: 120px;
  padding-bottom: 90px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-portfolio-page {
    padding-top: 80px;
    padding-bottom: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-portfolio-page .tj-portfolio-item-two {
    margin-bottom: 30px;
  }
}
.tj-portfolio-page .tj-sec-heading {
  max-width: 775px;
}

.portfolio-gallery .portfolio-items {
  position: relative;
  z-index: 3;
  margin-left: -12px;
  margin-right: -12px;
}
.portfolio-gallery .portfolio-items .portfolio-single-item {
  float: left;
  width: 50%;
  padding: 12px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .portfolio-gallery .portfolio-items .portfolio-single-item {
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .portfolio-gallery .portfolio-items .portfolio-single-item {
    width: 100%;
  }
}
.portfolio-gallery .portfolio-items .portfolio-single-item .portfolio-image {
  margin-bottom: 25px;
  position: relative;
}
.portfolio-gallery .portfolio-items .portfolio-single-item .portfolio-image::before {
  background: rgba(255, 255, 255, 0.5);
  bottom: 0;
  content: "";
  left: 50%;
  position: absolute;
  right: 50%;
  top: 0;
  opacity: 0;
  visibility: hidden;
  height: 100%;
  pointer-events: none;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
  border-radius: 20px;
  z-index: 3;
}
.portfolio-gallery .portfolio-items .portfolio-single-item .portfolio-image a {
  display: inline-block;
  width: 100%;
}
.portfolio-gallery .portfolio-items .portfolio-single-item .portfolio-image img {
  border-radius: 24px;
  width: 100%;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .portfolio-gallery .portfolio-items .portfolio-single-item .portfolio-image {
    margin-bottom: 20px;
  }
}
.portfolio-gallery .portfolio-items .portfolio-single-item .portfolio-content {
  padding-left: 35px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .portfolio-gallery .portfolio-items .portfolio-single-item .portfolio-content {
    padding-left: 0;
  }
}
.portfolio-gallery .portfolio-items .portfolio-single-item .portfolio-content .title-link {
  margin-bottom: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .portfolio-gallery .portfolio-items .portfolio-single-item .portfolio-content .title-link {
    font-size: 24px;
    margin-bottom: 0;
  }
}
.portfolio-gallery .portfolio-items .portfolio-single-item .portfolio-content .title-link a {
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: var(--tj-color-common-white);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  display: inline-block;
}
.portfolio-gallery .portfolio-items .portfolio-single-item .portfolio-content .title-link a:hover {
  color: transparent;
}
.portfolio-gallery .portfolio-items .portfolio-single-item .portfolio-content .sub-title {
  color: var(--tj-color-common-white);
  color: rgba(255, 255, 255, 0.8);
  display: block;
}
.portfolio-gallery .portfolio-items .portfolio-single-item:hover .portfolio-image::before {
  left: 0;
  right: 0;
  opacity: 1;
  visibility: visible;
}

.portfolio-grid {
  position: relative;
  z-index: 3;
}

.portfolio-slider {
  margin-left: calc((100% - 1340px) / 2);
  width: auto;
  min-width: auto;
  max-width: inherit;
  padding-right: 0;
  margin-right: -20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .portfolio-slider {
    margin-left: 0;
    margin-right: 12px;
  }
}
.portfolio-slider .tj-portfolio-slider {
  padding-top: 120px;
}

.portfolio-section-shape .portfolio-bg-shape {
  position: absolute;
  right: 0;
  top: 0;
}
.portfolio-section-shape .portfolio-bg-shape1 {
  position: absolute;
  left: 0;
  bottom: 60px;
}
.portfolio-section-shape .portfolio-bg-shape2 {
  position: absolute;
  right: 0;
  top: 48%;
  opacity: 0.4;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .portfolio-section-shape .portfolio-bg-shape2 {
    display: none;
  }
}
.portfolio-section-shape .portfolio-bg-shape3 {
  position: absolute;
  right: 0;
  top: 48%;
  opacity: 0.4;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .portfolio-section-shape .portfolio-bg-shape3 {
    display: none;
  }
}
.portfolio-section-shape .portfolio-bg-shape4 {
  position: absolute;
  right: 10%;
  bottom: 10px;
  opacity: 0.4;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .portfolio-section-shape .portfolio-bg-shape4 {
    display: none;
  }
}
.portfolio-section-shape .portfolio-bg-shape5 {
  position: absolute;
  right: 10%;
  bottom: 4%;
  opacity: 0.4;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .portfolio-section-shape .portfolio-bg-shape5 {
    display: none;
  }
}
.portfolio-section-shape .portfolio-bg-shape6 {
  position: absolute;
  top: 25%;
  left: 50px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .portfolio-section-shape .portfolio-bg-shape6 {
    display: none;
  }
}

.portfolio-top-content {
  margin-bottom: 50px;
}

.gutter-sizer {
  width: 2%;
}

.tj-portfolio-filter {
  text-align: right;
}
.tj-portfolio-filter button {
  font-size: 18px;
  font-weight: var(--tj-fw-medium);
  color: var(--tj-color-common-white);
  padding-left: 30px;
}
.tj-portfolio-filter button.active {
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.tj-portfolio-filter button:first-child {
  padding-left: 0;
}

.tj-portfolio-section-two {
  background: var(--tj-color-grey-5);
  padding-top: 120px;
  padding-bottom: 90px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-portfolio-section-two {
    padding-bottom: 80px;
    padding-top: 80px;
  }
}
.tj-portfolio-section-two .tj-sec-heading {
  max-width: 700px;
  margin-left: 0;
  margin-bottom: -60px;
}

.tj-portfolio-item-two {
  border-radius: 30px 30px 30px 0px;
  margin-bottom: 30px;
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  overflow: hidden;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-portfolio-item-two {
    margin-bottom: 100px;
  }
}
.tj-portfolio-item-two::before {
  position: absolute;
  content: "";
  bottom: -50px;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 30px 30px 30px 0px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(32, 217, 161, 0.14)), color-stop(87.25%, rgba(95, 57, 255, 0.8)));
  background: linear-gradient(180deg, rgba(32, 217, 161, 0.14) 0%, rgba(95, 57, 255, 0.8) 87.25%);
  opacity: 0;
  visibility: hidden;
}
.tj-portfolio-item-two .portfolio-image a {
  display: inline-block;
}
.tj-portfolio-item-two .portfolio-image img {
  border-radius: 30px 30px 30px 0;
  width: 100%;
}
.tj-portfolio-item-two .portfolio-content {
  position: absolute;
  left: 40px;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  max-width: 400px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-portfolio-item-two .portfolio-content {
    padding: 20px;
  }
}
.tj-portfolio-item-two .portfolio-content .sub-title {
  color: var(--tj-color-common-white);
  font-size: 14px;
  font-family: var(--tj-ff-heading);
  font-weight: var(--tj-fw-regular);
}
.tj-portfolio-item-two .portfolio-content .title {
  margin-bottom: 0;
}
.tj-portfolio-item-two .portfolio-content .title a {
  color: var(--tj-color-common-white);
  font-size: 26px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-portfolio-item-two .portfolio-content .title a {
    font-size: 22px;
  }
}
.tj-portfolio-item-two:hover::before {
  opacity: 1;
  visibility: visible;
  bottom: 0;
}
.tj-portfolio-item-two:hover .portfolio-content {
  opacity: 1;
  visibility: visible;
  bottom: 40px;
}

.tj-portfolio-section-two .swiper-button-next,
.tj-portfolio-section-two .swiper-button-prev {
  position: absolute;
  top: 10px;
  right: 20%;
  left: auto;
  border-radius: 3px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tj-portfolio-section-two .swiper-button-next,
  .tj-portfolio-section-two .swiper-button-prev {
    right: 10%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-portfolio-section-two .swiper-button-next,
  .tj-portfolio-section-two .swiper-button-prev {
    top: auto;
    bottom: 30px;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    left: 50%;
  }
}
@media (max-width: 575px) {
  .tj-portfolio-section-two .swiper-button-next,
  .tj-portfolio-section-two .swiper-button-prev {
    left: 62%;
  }
}
.tj-portfolio-section-two .swiper-button-next::before,
.tj-portfolio-section-two .swiper-button-prev::before {
  position: absolute;
  content: "\f104";
  font-family: "Font Awesome 6 Pro";
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  top: 15px;
  right: 70px;
  font-weight: 300;
  border: 2px solid var(--tj-color-light-8);
  color: var(--tj-color-light-8);
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  font-size: 30px;
  z-index: 1;
  border-radius: 50%;
}
.tj-portfolio-section-two .swiper-button-next:hover::before,
.tj-portfolio-section-two .swiper-button-prev:hover::before {
  border-color: var(--tj-color-theme-secondary);
  color: transparent;
}
.tj-portfolio-section-two .swiper-button-next::before {
  position: absolute;
  content: "\f105";
  font-family: "Font Awesome 6 Pro";
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: var(--tj-color-light-8);
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  top: 15px;
  border: 2px solid var(--tj-color-light-8);
  right: 0;
  z-index: 1;
  border-radius: 50%;
  font-size: 30px;
}
.tj-portfolio-section-two .swiper-button-prev:after,
.tj-portfolio-section-two .swiper-button-next:after {
  display: none;
}

.tj-portfolio-section-three {
  background: var(--tj-color-light-2);
  padding-bottom: 85px;
  padding-top: 120px;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tj-portfolio-section-three {
    padding-bottom: 35px;
    padding-top: 80px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-portfolio-section-three {
    padding-bottom: 60px;
    padding-top: 80px;
  }
}
.tj-portfolio-section-three .portfolio-top {
  margin-bottom: 60px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-portfolio-section-three .portfolio-top {
    margin-bottom: 35px;
  }
}
.tj-portfolio-section-three .tj-sec-heading {
  margin-bottom: 0;
}
.tj-portfolio-section-three .filter-menu {
  text-align: end;
  position: relative;
  z-index: 3;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-portfolio-section-three .filter-menu {
    text-align: left;
    margin-top: 30px;
  }
}
.tj-portfolio-section-three .filter-menu .filter {
  color: var(--tj-color-common-black);
  font-size: 18px;
  font-weight: var(--tj-fw-medium);
  margin-right: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-portfolio-section-three .filter-menu .filter {
    margin-right: 20px;
  }
}
.tj-portfolio-section-three .filter-menu .filter:last-child {
  margin-right: 0;
}
.tj-portfolio-section-three .filter-menu .filter.active {
  color: var(--tj-color-light-3);
}
.tj-portfolio-section-three .portfolio-single-item {
  width: 33.33%;
  padding: 12px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tj-portfolio-section-three .portfolio-single-item {
    width: 50%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tj-portfolio-section-three .portfolio-single-item {
    width: 100%;
    padding: 0;
  }
}
@media (max-width: 575px) {
  .tj-portfolio-section-three .portfolio-single-item {
    width: 100%;
    padding: 0 12px;
  }
}
.tj-portfolio-section-three .portfolio-single-item .portfolio-inner {
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-bottom: 24px;
  z-index: 3;
}
.tj-portfolio-section-three .portfolio-single-item .portfolio-inner .portfolio-image img {
  border-radius: 10px;
  width: 100%;
}
.tj-portfolio-section-three .portfolio-single-item .portfolio-inner .portfolio-content {
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  border-radius: 8px;
  background: var(--21, linear-gradient(62deg, var(--tj-color-light-3) 36.25%, rgba(112, 95, 255, 0) 89.24%));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 85px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 20px 23px;
  min-width: 380px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tj-portfolio-section-three .portfolio-single-item .portfolio-inner .portfolio-content {
    min-width: 320px;
    gap: 35px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-portfolio-section-three .portfolio-single-item .portfolio-inner .portfolio-content {
    min-width: 280px;
    gap: 15px;
    padding: 18px 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .tj-portfolio-section-three .portfolio-single-item .portfolio-inner .portfolio-content {
    left: 30%;
  }
}
@media (max-width: 575px) {
  .tj-portfolio-section-three .portfolio-single-item .portfolio-inner .portfolio-content {
    left: 45%;
  }
}
.tj-portfolio-section-three .portfolio-single-item .portfolio-inner .portfolio-content .portfolio-header .sub-title {
  color: var(--tj-color-common-white);
  display: block;
  margin-bottom: 5px;
}
.tj-portfolio-section-three .portfolio-single-item .portfolio-inner .portfolio-content .portfolio-header .title-link {
  margin-bottom: 0;
}
.tj-portfolio-section-three .portfolio-single-item .portfolio-inner .portfolio-content .portfolio-header .title-link a {
  color: var(--tj-color-common-white);
  font-size: 20px;
}
.tj-portfolio-section-three .portfolio-single-item .portfolio-inner .portfolio-content .portfolio-arrow a {
  width: 65px;
  height: 45px;
  line-height: 50px;
  border-radius: 3px;
  background: var(--tj-color-light-2);
  display: inline-block;
  text-align: center;
  font-size: 25px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-portfolio-section-three .portfolio-single-item .portfolio-inner .portfolio-content .portfolio-arrow a {
    width: 55px;
    height: 35px;
    line-height: 40px;
    font-size: 20px;
  }
}
.tj-portfolio-section-three .portfolio-single-item .portfolio-inner .portfolio-content .portfolio-arrow a i {
  color: var(--tj-color-theme-primary);
}
.tj-portfolio-section-three .portfolio-single-item .portfolio-inner:hover .portfolio-content {
  opacity: 1;
  visibility: visible;
  bottom: 20px;
}
.tj-portfolio-section-three .portfolio-bg-shape .portfolio-shape {
  position: absolute;
  bottom: 15%;
  left: 0;
}
.tj-portfolio-section-three .portfolio-bg-shape .portfolio-shape1 {
  position: absolute;
  top: 10%;
  right: 65px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-portfolio-section-three .portfolio-bg-shape {
    display: none;
  }
}

/* !END: Theme Portfolio CSS */
/**----------------------------------------
START: Theme Portfolio CSS
----------------------------------------*/
.tj-portfolio-details {
  padding-top: 120px;
  padding-bottom: 100px;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-portfolio-details {
    padding-top: 80px;
    padding-bottom: 60px;
  }
}
.tj-portfolio-details .tj-portfolio-shape .group-one .shape-1 {
  position: absolute;
  left: 80px;
  top: 10%;
}
.tj-portfolio-details .tj-portfolio-shape .group-one .shape-2 {
  position: absolute;
  left: 0;
  top: 15%;
}
.tj-portfolio-details .tj-portfolio-shape .group-two .shape-1 {
  position: absolute;
  left: 50px;
  bottom: 70px;
  opacity: 0.5;
}
.tj-portfolio-details .tj-portfolio-shape .group-two .shape-2 {
  position: absolute;
  left: 0;
  bottom: 50px;
  opacity: 0.5;
}
.tj-portfolio-details .tj-portfolio-shape .group-three .shape-1 {
  position: absolute;
  right: 60px;
  top: 10%;
}
.tj-portfolio-details .tj-portfolio-shape .group-three .shape-2 {
  position: absolute;
  right: 60px;
  top: 15%;
}
.tj-portfolio-details .tj-portfolio-shape .group-four .shape-1 {
  position: absolute;
  right: 30px;
  top: 40%;
}
.tj-portfolio-details .tj-portfolio-shape .group-four .shape-2 {
  position: absolute;
  right: 30px;
  top: 45%;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-portfolio-details .tj-portfolio-shape {
    display: none;
  }
}

.portfolio-wrapper {
  position: relative;
  z-index: 3;
}
.portfolio-wrapper .portfolio-lg-image {
  margin-bottom: 30px;
  position: relative;
  z-index: 3;
}
.portfolio-wrapper .portfolio-lg-image img {
  border-radius: 20px;
}
.portfolio-wrapper .tagcloud {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 15px;
  margin-bottom: 30px;
}
.portfolio-wrapper .tagcloud span {
  font-size: 18px;
  color: var(--tj-color-theme-primary);
  font-weight: var(--tj-fw-medium);
  margin-right: 10px;
}
.portfolio-wrapper .tagcloud a {
  background: var(--tj-color-light-8);
  font-size: 14px;
  font-weight: 300;
  color: var(--tj-color-common-black);
  border-radius: 6px;
  padding: 10px 20px;
  font-weight: var(--tj-fw-bold);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .portfolio-wrapper .tagcloud a {
    padding: 7px 15px;
  }
}
.portfolio-wrapper .tagcloud a i {
  font-size: 18px;
  color: var(--tj-color-common-black);
  margin-right: 10px;
  position: relative;
  top: 4px;
}
.portfolio-wrapper .tagcloud a:hover {
  color: var(--tj-color-common-white);
  background: var(--tj-color-theme-primary);
}
.portfolio-wrapper .tagcloud a:hover i {
  color: var(--tj-color-common-white);
}
.portfolio-wrapper .portfolio-content .title {
  font-size: 30px;
}
.portfolio-wrapper .portfolio-content .check-list {
  margin-bottom: 10px;
}
.portfolio-wrapper .portfolio-content .check-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.portfolio-wrapper .portfolio-content .check-list ul li {
  color: var(--tj-color-light-3);
  font-weight: var(--tj-fw-medium);
  font-family: var(--tj-ff-body);
  margin-bottom: 10px;
  position: relative;
  padding-left: 35px;
}
.portfolio-wrapper .portfolio-content .check-list ul li:last-child {
  margin-bottom: 0;
}
.portfolio-wrapper .portfolio-content .check-list ul li i {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-size: 12px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  font-weight: var(--tj-fw-sbold);
  color: var(--tj-color-common-white);
  background: var(--tj-color-light-3);
}
.portfolio-wrapper .portfolio-content .portfolio-left-content .title {
  margin-bottom: 30px;
}
.portfolio-wrapper .portfolio-content .need-title {
  margin-bottom: 24px;
}
.portfolio-wrapper .portfolio-content .need-title .tj-primary-btn {
  width: 100%;
  border-radius: 10px;
  text-align: center;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .portfolio-wrapper .portfolio-content .need-title {
    margin-top: 30px;
  }
}
.portfolio-wrapper .portfolio-content .sidebar-contact {
  position: relative;
  background: -webkit-gradient(linear, left top, right top, from(rgba(32, 217, 161, 0.08)), to(rgba(95, 57, 255, 0.08)));
  background: linear-gradient(90deg, rgba(32, 217, 161, 0.08) 0%, rgba(95, 57, 255, 0.08) 100%);
  margin-bottom: 24px;
  border-radius: 10px;
  padding: 40px;
  overflow: hidden;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .portfolio-wrapper .portfolio-content .sidebar-contact {
    padding: 35px 15px;
  }
}
.portfolio-wrapper .portfolio-content .sidebar-contact .contact-item {
  position: relative;
  z-index: 3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
}
.portfolio-wrapper .portfolio-content .sidebar-contact .contact-item:last-child {
  margin-bottom: 0;
}
.portfolio-wrapper .portfolio-content .sidebar-contact .contact-item .contact-icon {
  border: none;
  margin-bottom: 0;
  position: relative;
  top: -14px;
}
.portfolio-wrapper .portfolio-content .sidebar-contact .contact-item .contact-icon i {
  min-width: 35px;
  height: 35px;
  line-height: 40px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  background: var(--tj-color-light-8);
  color: var(--tj-color-light-3);
  font-size: 17px;
  margin-right: 10px;
}
.portfolio-wrapper .portfolio-content .sidebar-contact .contact-item .contact-content .title {
  font-size: 22px;
  color: var(--tj-color-light-3);
}
.portfolio-wrapper .portfolio-content .sidebar-contact .contact-item span {
  font-size: 16px;
  font-weight: var(--tj-fw-regular);
}
.portfolio-wrapper .portfolio-content .sidebar-contact .contact-share {
  margin-top: 20px;
  padding-left: 55px;
  position: relative;
  z-index: 3;
}
.portfolio-wrapper .portfolio-content .sidebar-contact .contact-share ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 15px;
}
.portfolio-wrapper .portfolio-content .sidebar-contact .contact-share ul li a {
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  border-radius: 50%;
  background: var(--tj-color-common-black);
  color: var(--tj-color-common-white);
  text-align: center;
}
.portfolio-wrapper .portfolio-content .sidebar-contact .contact-share ul li a svg path {
  fill: var(--tj-color-common-white);
}
.portfolio-wrapper .portfolio-content .sidebar-contact .contact-share ul li svg {
  position: relative;
  top: -2px;
}
.portfolio-wrapper .portfolio-content .sidebar-contact .contact-share ul li:hover a {
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
}
.portfolio-wrapper .portfolio-content .sidebar-contact .contact-svg {
  position: absolute;
  right: 0;
  bottom: 0;
}

/* !END: Theme Portfolio CSS */
/**----------------------------------------
START: Theme FAQ CSS
----------------------------------------*/
.tj-faq-section {
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;
  overflow: hidden;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-faq-section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.tj-faq-section .tj-sec-heading {
  max-width: 610px;
  margin: 0;
  margin-bottom: 55px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-faq-section .tj-sec-heading {
    margin-bottom: 75px;
  }
}

.faq-page .tj-sec-heading {
  max-width: 610px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 55px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .faq-page .tj-sec-heading {
    margin-bottom: 75px;
  }
}

.faq-section-shape .faq-bg-shape {
  position: absolute;
  top: 0;
  right: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .faq-section-shape .faq-bg-shape {
    width: 50%;
  }
}
.faq-section-shape .faq-bg-shape1 {
  position: absolute;
  left: 0;
  bottom: 0;
}

.faq-image-group {
  position: relative;
  z-index: 3;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .faq-image-group {
    margin-bottom: 100px;
  }
}
@media (max-width: 575px) {
  .faq-image-group {
    margin-bottom: 180px;
  }
}
.faq-image-group .faq-image1 .group-image {
  bottom: -10%;
  position: absolute;
  right: 15%;
  z-index: 3;
  max-width: 385px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .faq-image-group .faq-image1 .group-image {
    right: 10%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .faq-image-group .faq-image1 .group-image {
    max-width: 280px;
    right: 10%;
    bottom: -10%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq-image-group .faq-image1 .group-image {
    right: 25%;
    bottom: -5%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .faq-image-group .faq-image1 .group-image {
    bottom: -12%;
    right: 25%;
    max-width: 260px;
  }
}
@media (max-width: 575px) {
  .faq-image-group .faq-image1 .group-image {
    bottom: -20%;
    right: 12%;
    max-width: 230px;
  }
}
.faq-image-group .faq-image1 .group-image::before {
  position: absolute;
  content: "";
  top: 53.5%;
  left: 57%;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}
.faq-image-group .faq-image1 .group-image img {
  border-radius: 50%;
  padding: 40px;
  max-width: 390px;
  width: 100%;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .faq-image-group .faq-image1 .group-image img {
    max-width: 330px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .faq-image-group .faq-image1 .group-image img {
    max-width: 280px;
    padding: 35px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .faq-image-group .faq-image1 .group-image img {
    max-width: 300px;
    padding: 35px;
  }
}
@media (max-width: 575px) {
  .faq-image-group .faq-image1 .group-image img {
    max-width: 230px;
    padding: 30px;
  }
}
.faq-image-group .faq-image1 .group-shape {
  position: absolute;
  top: 0;
  left: 50%;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .faq-image-group .faq-image1 .group-shape {
    top: 45px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .faq-image-group .faq-image1 .group-shape {
    display: none;
  }
}
.faq-image-group .faq-image1 .group-shape1 {
  position: absolute;
  top: -25px;
  left: 45%;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .faq-image-group .faq-image1 .group-shape1 {
    top: 45px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .faq-image-group .faq-image1 .group-shape1 {
    display: none;
  }
}
.faq-image-group .faq-image1 .group-shape2 {
  position: absolute;
  right: 7%;
  top: 10%;
  border: 1px solid var(--tj-color-theme-primary);
  border-radius: 50%;
  padding: 7px;
  max-width: 100px;
  width: 100%;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .faq-image-group .faq-image1 .group-shape2 {
    right: 5%;
    top: 12%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .faq-image-group .faq-image1 .group-shape2 {
    top: 6%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq-image-group .faq-image1 .group-shape2 {
    right: 15%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .faq-image-group .faq-image1 .group-shape2 {
    right: 12%;
    top: 0;
  }
}
@media (max-width: 575px) {
  .faq-image-group .faq-image1 .group-shape2 {
    right: 6%;
    top: -6%;
  }
}
.faq-image-group .faq-image2 {
  position: relative;
}
.faq-image-group .faq-image2 .group-image {
  left: 10px;
  bottom: -50px;
  position: relative;
  z-index: 3;
  max-width: 180px;
  width: 100%;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .faq-image-group .faq-image2 .group-image {
    left: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .faq-image-group .faq-image2 .group-image {
    max-width: 140px;
    left: 5px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq-image-group .faq-image2 .group-image {
    max-width: 140px;
    left: 16%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .faq-image-group .faq-image2 .group-image {
    max-width: 125px;
    left: 4%;
  }
}
@media (max-width: 575px) {
  .faq-image-group .faq-image2 .group-image {
    max-width: 120px;
    bottom: -120px;
  }
}
.faq-image-group .faq-image2 .group-image img {
  border: 1px solid var(--tj-color-theme-primary);
  border-radius: 50%;
  padding: 10px;
}
.faq-image-group .faq-image2 .group-shape {
  position: absolute;
  top: 0;
  left: 35px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .faq-image-group .faq-image2 .group-shape {
    display: none;
  }
}
.faq-image-group .faq-image2 .group-shape1 {
  position: absolute;
  top: 25px;
  left: -15px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .faq-image-group .faq-image2 .group-shape1 {
    display: none;
  }
}
.faq-image-group .faq-image3 {
  position: relative;
  top: -50px;
  left: -15px;
  max-width: 210px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .faq-image-group .faq-image3 {
    left: 20px;
    top: -65px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .faq-image-group .faq-image3 {
    top: -20px;
    left: 50px;
    max-width: 180px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .faq-image-group .faq-image3 {
    top: -35px;
    left: 30px;
    max-width: 130px;
  }
}
.faq-image-group .faq-image3 img {
  border: 1px solid var(--tj-color-theme-secondary);
  border-radius: 50%;
  padding: 10px;
}

.tj-faq-area {
  padding-left: 40px;
  position: relative;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-faq-area {
    padding-left: 0;
  }
}
.tj-faq-area .tj-faq-style {
  border: 1px solid var(--tj-color-theme-secondary);
  -webkit-box-shadow: 60px 15px 68px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 60px 15px 68px 0px rgba(0, 0, 0, 0.05);
  background: var(--tj-color-common-white);
  border-radius: 20px;
  padding: 50px 60px;
  position: relative;
  z-index: 3;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-faq-area .tj-faq-style {
    padding: 25px 35px;
  }
}
@media (max-width: 575px) {
  .tj-faq-area .tj-faq-style {
    padding: 20px 15px;
  }
}
.tj-faq-area .tj-faq-style .accordion-item {
  border: none;
  border-radius: 5px;
  -webkit-box-shadow: none;
          box-shadow: none;
  position: relative;
  z-index: 9;
  border-bottom: 1px solid var(--tj-color-grey-4);
  border-radius: 0;
}
.tj-faq-area .tj-faq-style .accordion-item:last-child {
  border-bottom: none;
}
.tj-faq-area .tj-faq-style .accordion-item .accordion-header {
  line-height: 35px;
}
.tj-faq-area .tj-faq-style .accordion-item .accordion-header .accordion-button {
  font-size: 22px;
  font-family: var(--tj-ff-heading);
  font-weight: var(--tj-fw-sbold);
  padding: 20px 35px 20px 0;
  background-color: transparent;
  position: relative;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}
.tj-faq-area .tj-faq-style .accordion-item .accordion-header .accordion-button.collapsed {
  color: var(--tj-color-common-black);
}
.tj-faq-area .tj-faq-style .accordion-item .accordion-header .accordion-button:before {
  position: absolute;
  top: 30px;
  right: 0;
  content: "\f107";
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
  width: 20px;
  height: 20px;
  font-size: 15px;
  color: var(--tj-color-common-white);
  line-height: 22px;
  text-align: center;
  border-radius: 50%;
  background: var(--tj-color-common-black);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tj-faq-area .tj-faq-style .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.tj-faq-area .tj-faq-style .accordion-item .accordion-header .accordion-button:not(.collapsed):before {
  -webkit-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
          transform: rotate(-180deg);
  color: var(--tj-color-common-white);
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
}
.tj-faq-area .tj-faq-style .accordion-item .accordion-header .accordion-button:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.tj-faq-area .tj-faq-style .accordion-item .accordion-header .accordion-button:after {
  display: none;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .tj-faq-area .tj-faq-style .accordion-item .accordion-header .accordion-button {
    font-size: 20px;
  }
}
@media (max-width: 575px) {
  .tj-faq-area .tj-faq-style .accordion-item .accordion-header .accordion-button {
    font-size: 18px;
    line-height: 1.5;
  }
}
.tj-faq-area .tj-faq-style .accordion-item .accordion-body {
  padding-left: 0;
  padding: 0 0 20px 0;
  max-width: 460px;
}
.tj-faq-area .tj-faq-style .accordion-item .accordion-body strong {
  font-size: 16px;
  font-weight: 400;
  color: var(--tj-color-text-body);
}
.tj-faq-area .accordion-shape {
  position: absolute;
  top: -38%;
  right: -10%;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-faq-area .accordion-shape {
    display: none;
  }
}

.tj-faq-section-two {
  padding-top: 120px;
  padding-bottom: 100px;
  overflow: hidden;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-faq-section-two {
    padding-bottom: 60px;
    padding-top: 80px;
  }
}
.tj-faq-section-two .tj-sec-heading {
  max-width: 600px;
  margin-left: 0;
}

.faq-group-shape {
  opacity: 0.2;
}
@media (max-width: 575px) {
  .faq-group-shape {
    display: none;
  }
}
.faq-group-shape .bg-shape-one {
  position: absolute;
  top: -60px;
  right: -60px;
}
.faq-group-shape .bg-shape-two {
  position: absolute;
  top: -65px;
  right: -55px;
}
.faq-group-shape .bg-shape-three {
  position: absolute;
  top: -65px;
  right: -40px;
}
.faq-group-shape .bg-shape-four {
  position: absolute;
  top: -65px;
  right: 5px;
}
.faq-group-shape .bg-shape-five {
  position: absolute;
  top: -65px;
  right: 5px;
}

.faq-group-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .faq-group-image {
    max-width: 570px;
    margin-bottom: 180px;
  }
}
.faq-group-image .group-image-one {
  position: relative;
  margin-right: 30px;
  max-width: 335px;
  width: 100%;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .faq-group-image .group-image-one {
    max-width: 200px;
    margin-right: 0;
    right: -35px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .faq-group-image .group-image-one {
    max-width: 200px;
    margin-right: 0;
    left: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq-group-image .group-image-one {
    margin-right: 0;
    left: 20px;
    max-width: 250px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .faq-group-image .group-image-one {
    max-width: 200px;
    margin-right: 0;
    left: 20px;
  }
}
@media (max-width: 575px) {
  .faq-group-image .group-image-one {
    max-width: 150px;
    margin-right: 0;
    left: 20px;
  }
}
.faq-group-image .group-image-one::before {
  position: absolute;
  content: "";
  top: -15px;
  left: -18px;
  width: 335px;
  height: 377px;
  background: var(--tj-color-light-7);
  z-index: 2;
  border-radius: 0 0 200px 200px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .faq-group-image .group-image-one::before {
    width: 218px;
    height: 260px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .faq-group-image .group-image-one::before {
    width: 218px;
    height: 250px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq-group-image .group-image-one::before {
    width: 268px;
    height: 310px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .faq-group-image .group-image-one::before {
    width: 218px;
    height: 260px;
  }
}
@media (max-width: 575px) {
  .faq-group-image .group-image-one::before {
    width: 168px;
    height: 195px;
  }
}
.faq-group-image .group-image-one .lg-image {
  border-radius: 0 0 200px 200px;
  position: relative;
  z-index: 3;
}
.faq-group-image .shape-1 {
  position: absolute;
  bottom: -10px;
  left: -60px;
}
.faq-group-image .shape-2 {
  position: absolute;
  bottom: -45px;
  left: -30px;
}
.faq-group-image .group-image-two {
  position: relative;
}
.faq-group-image .group-image-two .shape-1 {
  position: absolute;
  top: -70px;
  left: 20%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: -1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .faq-group-image .group-image-two .shape-1 {
    left: 10%;
  }
}
@media (max-width: 575px) {
  .faq-group-image .group-image-two .shape-1 {
    max-width: 100px;
  }
}
.faq-group-image .group-image-two .shape-2 {
  position: absolute;
  top: -110px;
  left: 20%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: -1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .faq-group-image .group-image-two .shape-2 {
    left: 10%;
  }
}
@media (max-width: 575px) {
  .faq-group-image .group-image-two .shape-2 {
    max-width: 75px;
    top: -80px;
  }
}

.group-image-two {
  position: relative;
  top: 110px;
  max-width: 325px;
}
.group-image-two .lg-image {
  border-radius: 200px 200px 0 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .group-image-two {
    max-width: 250px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .group-image-two {
    max-width: 200px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .group-image-two {
    max-width: 250px;
  }
}
@media (max-width: 575px) {
  .group-image-two {
    max-width: 150px;
    right: 15px;
  }
}
.group-image-two::before {
  position: absolute;
  content: "";
  top: -10px;
  left: -20px;
  width: 291px;
  height: 380px;
  background: var(--tj-color-light-7);
  z-index: -1;
  border-radius: 200px 200px 0 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .group-image-two::before {
    width: 270px;
    height: 355px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .group-image-two::before {
    width: 220px;
    height: 290px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .group-image-two::before {
    width: 270px;
    height: 355px;
  }
}
@media (max-width: 575px) {
  .group-image-two::before {
    left: -15px;
    width: 165px;
    height: 220px;
  }
}
.group-image-two::before .shape-1 {
  position: absolute;
  top: -70px;
  left: 40%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: -1;
}
@media (max-width: 575px) {
  .group-image-two::before .shape-1 {
    display: none;
  }
}
.group-image-two::before .shape-2 {
  position: absolute;
  top: -110px;
  left: 25%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: -1;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .group-image-two::before .shape-2 {
    top: -80px;
    left: 35%;
  }
}
@media (max-width: 575px) {
  .group-image-two::before .shape-2 {
    display: none;
  }
}

.tj-faq-style-two {
  padding-left: 50px;
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tj-faq-style-two {
    padding-left: 25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-faq-style-two {
    padding-left: 0;
  }
}
.tj-faq-style-two .tj-faq-style {
  position: relative;
  z-index: 3;
}
.tj-faq-style-two .tj-faq-style .accordion-item {
  -webkit-box-shadow: none;
          box-shadow: none;
  position: relative;
  z-index: 9;
  padding: 0;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 20px 20px 20px 0;
  padding: 0 30px;
}
.tj-faq-style-two .tj-faq-style .accordion-item.active {
  -webkit-box-shadow: 10px 15px 30px 0px rgba(0, 0, 0, 0.08);
          box-shadow: 10px 15px 30px 0px rgba(0, 0, 0, 0.08);
  background: var(--tj-color-light-2);
  border-color: var(--tj-color-theme-primary);
  padding: 25px 30px;
  margin-bottom: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-faq-style-two .tj-faq-style .accordion-item.active {
    padding: 20px 20px;
  }
}
.tj-faq-style-two .tj-faq-style .accordion-item .accordion-header {
  line-height: 35px;
}
.tj-faq-style-two .tj-faq-style .accordion-item .accordion-header .accordion-button {
  font-size: 22px;
  font-family: var(--tj-ff-heading);
  font-weight: var(--tj-fw-sbold);
  padding: 0 35px 20px 45px;
  background-color: transparent;
  position: relative;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}
.tj-faq-style-two .tj-faq-style .accordion-item .accordion-header .accordion-button.collapsed {
  color: var(--tj-color-common-black);
}
.tj-faq-style-two .tj-faq-style .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.tj-faq-style-two .tj-faq-style .accordion-item .accordion-header .accordion-button:not(.collapsed):before {
  position: absolute;
  content: "";
  top: 10px;
  right: -95%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.tj-faq-style-two .tj-faq-style .accordion-item .accordion-header .accordion-button:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.tj-faq-style-two .tj-faq-style .accordion-item .accordion-header .accordion-button:after {
  display: none;
}
.tj-faq-style-two .tj-faq-style .accordion-item .accordion-header .accordion-button span {
  -webkit-text-fill-color: var(--tj-color-common-white);
  -webkit-text-stroke: 1px var(--tj-color-common-black);
  position: absolute;
  left: 0;
  top: 0;
  font-weight: var(--tj-fw-bold);
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-faq-style-two .tj-faq-style .accordion-item .accordion-header .accordion-button {
    font-size: 19px;
    line-height: 1.5;
  }
}
.tj-faq-style-two .tj-faq-style .accordion-item .accordion-body {
  padding: 0;
  max-width: 460px;
}
.tj-faq-style-two .tj-faq-style .accordion-item .accordion-body strong {
  font-size: 16px;
  font-weight: 400;
  color: var(--tj-color-text-body);
}
.tj-faq-style-two .accordion-shape {
  position: absolute;
  top: 50%;
  right: -30%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
}
@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-faq-style-two .accordion-shape {
    display: none;
  }
}
.tj-faq-style-two .accordion-shape1 {
  position: absolute;
  top: 45%;
  right: -40%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
}
@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-faq-style-two .accordion-shape1 {
    display: none;
  }
}

/* !END: Theme FAQ CSS */
/**----------------------------------------
START: Theme Blog CSS
----------------------------------------*/
.tj-blog-section {
  padding-top: 120px;
  padding-bottom: 70px;
  background: var(--tj-color-light-2);
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-blog-section {
    padding-top: 80px;
    padding-bottom: 40px;
  }
}
.tj-blog-section .tj-sec-heading {
  max-width: 780px;
}

.blog-section-shape .blog-bg-shape {
  position: absolute;
  top: 0;
  right: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .blog-section-shape .blog-bg-shape {
    width: 50%;
  }
}
.blog-section-shape .blog-bg-shape1 {
  position: absolute;
  bottom: 0;
  left: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .blog-section-shape .blog-bg-shape1 {
    width: 50%;
  }
}

.tj-blog-item {
  position: relative;
  z-index: 3;
  margin-bottom: 50px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  overflow: hidden;
}
.tj-blog-item .blog-image {
  margin-bottom: 20px;
  position: relative;
  border-radius: 20px;
}
.tj-blog-item .blog-image::before {
  background: rgba(255, 255, 255, 0.5);
  bottom: 0;
  content: "";
  left: 50%;
  position: absolute;
  right: 50%;
  top: 0;
  opacity: 0;
  visibility: hidden;
  height: 100%;
  pointer-events: none;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
  border-radius: 20px;
  z-index: 3;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-blog-item .blog-image {
    width: 100%;
  }
}
.tj-blog-item .blog-image a {
  display: inline-block;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-blog-item .blog-image a {
    width: 100%;
  }
}
.tj-blog-item .blog-image img {
  border-radius: 20px;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-blog-item .blog-image img {
    width: 100%;
  }
}
.tj-blog-item .blog-content-area {
  padding: 0 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-blog-item .blog-content-area {
    padding: 0 0;
  }
}
.tj-blog-item .blog-content-area .title-link {
  margin-bottom: 45px;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-blog-item .blog-content-area .title-link {
    font-size: 20px;
  }
}
.tj-blog-item .blog-content-area .title-link::before {
  position: absolute;
  content: "";
  width: 130px;
  height: 1px;
  bottom: -20px;
  left: 0;
  background: rgba(95, 57, 255, 0.5);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tj-blog-item .blog-content-area .title-link a {
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: var(--tj-color-common-black);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  line-height: 1.6;
}
.tj-blog-item .blog-content-area .title-link a:hover {
  color: transparent;
}
.tj-blog-item .blog-content-area p {
  margin-bottom: 0;
}
.tj-blog-item .tj-blog-button {
  position: relative;
  margin-top: 25px;
}
.tj-blog-item .tj-blog-button .tj-secondary-btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 150px;
  font-size: 16px;
  font-weight: var(--tj-fw-sbold);
  font-family: var(--tj-ff-body);
  line-height: 2;
  text-transform: capitalize;
  color: var(--tj-color-theme-primary);
  padding: 12px 35px;
  border: none;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-primary)), to(var(--tj-color-theme-secondary)));
  background: linear-gradient(90deg, var(--tj-color-theme-primary) 0%, var(--tj-color-theme-secondary) 100%);
  background-size: 150% auto;
}
.tj-blog-item .tj-blog-button .tj-secondary-btn:hover, .tj-blog-item .tj-blog-button .tj-secondary-btn:focus {
  color: var(--tj-color-theme-primary);
  background-position: right center;
}
.tj-blog-item .tj-blog-button .tj-secondary-btn.btn-border {
  position: relative;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  -webkit-box-shadow: 0 0 0 0;
          box-shadow: 0 0 0 0;
  padding: 1px;
}
.tj-blog-item .tj-blog-button .tj-secondary-btn.btn-border span {
  padding: 12px 35px;
  background: var(--tj-color-light-2);
  border-radius: 58px;
  color: var(--tj-color-theme-primary);
}
.tj-blog-item .tj-blog-button .tj-secondary-btn.btn-border:hover span {
  background: transparent;
  color: var(--tj-color-common-white);
}
.tj-blog-item:hover .blog-image::before {
  left: 0;
  right: 0;
  opacity: 1;
  visibility: visible;
}
.tj-blog-item:hover .title-link::before {
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
}

.blog-meta {
  margin-bottom: 15px;
}
.blog-meta span {
  font-size: 14px;
  margin-right: 35px;
  font-weight: var(--tj-fw-bold);
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .blog-meta span {
    margin-right: 15px;
  }
}
.blog-meta span:last-child {
  margin-right: 0;
}
.blog-meta span a {
  color: var(--tj-color-text-body);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.blog-meta span a:hover {
  color: var(--tj-color-theme-primary);
}
.blog-meta i {
  font-size: 15px;
  margin-right: 10px;
  position: relative;
  top: 3px;
}

.blog-meta-two {
  margin-bottom: 15px;
}
.blog-meta-two span {
  font-size: 16px;
  margin-right: 35px;
}
.blog-meta-two span:last-child {
  margin-right: 0;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .blog-meta-two span {
    margin-right: 15px;
  }
}
.blog-meta-two span a {
  color: var(--tj-color-text-body);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.blog-meta-two span a:hover {
  color: var(--tj-color-theme-primary);
}
.blog-meta-two i {
  color: var(--tj-color-text-body);
  font-size: 15px;
  margin-right: 10px;
}

.blog-meta-three {
  margin-bottom: 15px;
  position: relative;
}
.blog-meta-three::before {
  position: absolute;
  content: "";
  left: -50px;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 32px;
  height: 2px;
  background: var(--tj-color-common-black);
}
.blog-meta-three span {
  margin-right: 10px;
}
.blog-meta-three span:last-child {
  margin-right: 0;
}
.blog-meta-three span a {
  color: var(--tj-color-common-black);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.blog-meta-three span a:hover {
  color: var(--tj-color-theme-primary);
}

.tj-blog-section-two {
  background: var(--tj-color-grey-5);
  padding-top: 120px;
  padding-bottom: 90px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-blog-section-two {
    padding-top: 80px;
    padding-bottom: 50px;
  }
}

.tj-blog-item-two {
  margin-bottom: 30px;
  border-radius: 20px 20px 20px 0px;
  padding: 20px;
  background: var(--tj-color-common-white);
  border: 1px solid var(--tj-color-light-8);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tj-blog-item-two .blog-image {
  margin-bottom: 30px;
  position: relative;
  border-radius: 15px;
}
.tj-blog-item-two .blog-image::before {
  background: rgba(255, 255, 255, 0.5);
  bottom: 0;
  content: "";
  left: 50%;
  position: absolute;
  right: 50%;
  top: 0;
  opacity: 0;
  visibility: hidden;
  height: 100%;
  pointer-events: none;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
  border-radius: 15px;
  z-index: 3;
}
.tj-blog-item-two .blog-image a {
  display: inline-block;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-blog-item-two .blog-image a {
    width: 100%;
  }
}
.tj-blog-item-two .blog-image img {
  border-radius: 15px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-blog-item-two .blog-image img {
    width: 100%;
  }
}
.tj-blog-item-two .blog-image .active-text {
  position: absolute;
  left: 20px;
  bottom: 20px;
  border-radius: 0px 5px 5px 5px;
  padding: 20px 10px;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  color: var(--tj-color-common-white);
  -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
  z-index: 3;
}
.tj-blog-item-two .blog-content-area .title-link {
  margin-bottom: 15px;
}
.tj-blog-item-two .blog-content-area .title-link a {
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: var(--tj-color-common-black);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  font-size: 25px;
}
.tj-blog-item-two .blog-content-area .title-link a:hover {
  color: transparent;
}
.tj-blog-item-two .blog-content-area p {
  margin-bottom: 0;
}
.tj-blog-item-two .tj-read-more-button {
  position: relative;
  padding-top: 40px;
}
.tj-blog-item-two:hover {
  border-color: var(--tj-color-theme-primary);
}
.tj-blog-item-two:hover .blog-image::before {
  left: 0;
  right: 0;
  opacity: 1;
  visibility: visible;
}

.tj-blog-section-three {
  padding-top: 120px;
  padding-bottom: 70px;
  position: relative;
  overflow: hidden;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-blog-section-three {
    padding-top: 80px;
    padding-bottom: 30px;
  }
}
.tj-blog-section-three .tj-sec-heading {
  max-width: 780px;
}
.tj-blog-section-three .blog-sec-shape .shape-one {
  position: absolute;
  left: 80px;
  top: 10%;
  opacity: 0.3;
}
.tj-blog-section-three .blog-sec-shape .shape-two {
  position: absolute;
  right: 0;
  bottom: 20%;
}
.tj-blog-section-three .blog-sec-shape .shape-three {
  position: absolute;
  right: 0;
  right: 5%;
  bottom: 40%;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-mask-size: contain;
          mask-size: contain;
  background: var(--tj-color-light-3);
  height: 225px;
  width: 175px;
  opacity: 0.3;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-blog-section-three .blog-sec-shape {
    display: none;
  }
}

.tj-blog-item-three {
  position: relative;
  z-index: 3;
  margin-bottom: 50px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  overflow: hidden;
}
.tj-blog-item-three .blog-image {
  margin-bottom: 30px;
  position: relative;
  border-radius: 20px;
}
.tj-blog-item-three .blog-image::before {
  background: rgba(255, 255, 255, 0.5);
  bottom: 0;
  content: "";
  left: 50%;
  position: absolute;
  right: 50%;
  top: 0;
  opacity: 0;
  visibility: hidden;
  height: 100%;
  pointer-events: none;
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
  border-radius: 20px;
  z-index: 3;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-blog-item-three .blog-image {
    width: 100%;
  }
}
.tj-blog-item-three .blog-image a {
  display: inline-block;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-blog-item-three .blog-image a {
    width: 100%;
  }
}
.tj-blog-item-three .blog-image img {
  border-radius: 20px;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-blog-item-three .blog-image img {
    width: 100%;
  }
}
.tj-blog-item-three .blog-content-area {
  padding: 0 50px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-blog-item-three .blog-content-area {
    padding: 0 0;
  }
}
.tj-blog-item-three .blog-content-area .title-link {
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-blog-item-three .blog-content-area .title-link {
    font-size: 20px;
  }
}
.tj-blog-item-three .blog-content-area .title-link a {
  color: var(--tj-color-common-black);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  line-height: 1.6;
}
.tj-blog-item-three .blog-content-area .title-link a:hover {
  color: var(--tj-color-light-3);
}
.tj-blog-item-three .blog-content-area p {
  margin-bottom: 0;
}
.tj-blog-item-three .tj-blog-button {
  margin-top: 30px;
}
.tj-blog-item-three:hover .blog-meta-three::before {
  background: var(--tj-color-light-3);
}
.tj-blog-item-three:hover .blog-image::before {
  left: 0;
  right: 0;
  opacity: 1;
  visibility: visible;
}

/* !END: Theme Blog CSS */
/**----------------------------------------
START: Theme Blog CSS
----------------------------------------*/
.sc-blog-grid-section {
  padding-top: 120px;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .sc-blog-grid-section {
    padding-top: 80px;
  }
}
.sc-blog-grid-section .tj-sec-heading {
  max-width: 780px;
}
.sc-blog-grid-section .blog-page-shape .shape-one {
  position: absolute;
  top: 0;
  right: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .sc-blog-grid-section .blog-page-shape .shape-one {
    width: 50%;
  }
}
.sc-blog-grid-section .blog-page-shape .shape-two {
  position: absolute;
  top: 10%;
  right: 50px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .sc-blog-grid-section .blog-page-shape .shape-two {
    display: none;
  }
}
.sc-blog-grid-section .blog-page-shape .shape-three {
  position: absolute;
  top: 18%;
  right: -50px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .sc-blog-grid-section .blog-page-shape .shape-three {
    display: none;
  }
}
.sc-blog-grid-section .blog-page-shape .shape-four {
  position: absolute;
  bottom: 20%;
  left: -50px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .sc-blog-grid-section .blog-page-shape .shape-four {
    display: none;
  }
}

.tj-blog-details {
  padding-top: 110px;
  padding-bottom: 120px;
  position: relative;
  overflow: hidden;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-blog-details {
    padding-top: 80px;
    padding-bottom: 60px;
  }
}
.tj-blog-details .blog-section-shape .shape-1 {
  position: absolute;
  top: 0;
  left: 0;
}
.tj-blog-details .blog-section-shape .shape-2 {
  position: absolute;
  top: 15%;
  right: 30px;
}
.tj-blog-details .blog-section-shape .shape-3 {
  position: absolute;
  top: 18%;
  right: -80px;
}
.tj-blog-details .blog-section-shape .shape-4 {
  position: absolute;
  bottom: 15%;
  left: -50px;
}
.tj-blog-details .blog-section-shape .shape-5 {
  position: absolute;
  bottom: 10%;
  left: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-blog-details .blog-section-shape {
    display: none;
  }
}

.sidebar-wrapper {
  position: relative;
  z-index: 3;
}
.sidebar-wrapper .blog-thumb-image {
  margin-bottom: 20px;
}
.sidebar-wrapper .blog-thumb-image img {
  border-radius: 20px;
}
.sidebar-wrapper .details-title {
  margin-bottom: 15px;
}
.sidebar-wrapper .details-title .title {
  font-size: 30px;
  margin-bottom: 0;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .sidebar-wrapper .details-title .title {
    font-size: 22px;
  }
}
.sidebar-wrapper .blog-video-area {
  margin-bottom: 50px;
}
.sidebar-wrapper .blog-video-area .video-title {
  margin-bottom: 26px;
}
.sidebar-wrapper .blog-video-area .video-title p {
  margin-bottom: 0;
}
.sidebar-wrapper .blog-video-area .video-box {
  margin-bottom: 15px;
}
.sidebar-wrapper .blog-video-area .video-image {
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .sidebar-wrapper .blog-video-area .video-image {
    margin-bottom: 30px;
  }
}
.sidebar-wrapper .blog-video-area .video-image img {
  border-radius: 20px;
  width: 100%;
}
.sidebar-wrapper .blog-video-area .video-image a {
  display: inline-block;
}
.sidebar-wrapper .blog-video-area .video-image .video-play {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
  line-height: 80px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .sidebar-wrapper .blog-video-area .video-image .video-play {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
}
.sidebar-wrapper .blog-video-area .video-image .video-play i {
  color: var(--tj-color-common-white);
  font-size: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .sidebar-wrapper .blog-video-area .video-image .video-play i {
    font-size: 20px;
  }
}
.sidebar-wrapper .blog-video-area .check-list {
  margin-top: 15px;
}
.sidebar-wrapper .blog-video-area .check-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.sidebar-wrapper .blog-video-area .check-list ul li {
  color: var(--tj-color-light-3);
  font-weight: var(--tj-fw-sbold);
  font-family: var(--tj-ff-body);
  margin-bottom: 10px;
}
.sidebar-wrapper .blog-video-area .check-list ul li:last-child {
  margin-bottom: 0;
}
.sidebar-wrapper .blog-video-area .check-list ul li i {
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-size: 12px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  font-weight: var(--tj-fw-sbold);
  color: var(--tj-color-common-white);
  background: var(--tj-color-light-3);
  margin-right: 5px;
}
.sidebar-wrapper .blog-video-area p {
  margin-bottom: 0;
}
.sidebar-wrapper .tj-tags-post {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-top: 2px solid var(--tj-color-light-7);
  border-bottom: 2px solid var(--tj-color-light-7);
  padding-top: 32px;
  padding-bottom: 32px;
  margin-bottom: 45px;
}
.sidebar-wrapper .tj-tags-post .post-share ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 14px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.sidebar-wrapper .tj-tags-post .post-share ul li span {
  font-size: 18px;
  color: var(--tj-color-theme-primary);
  font-weight: var(--tj-fw-medium);
  margin-right: 10px;
}
.sidebar-wrapper .tj-tags-post .post-share ul li a {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  background: var(--tj-color-common-black);
  color: var(--tj-color-common-white);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.sidebar-wrapper .tj-tags-post .post-share ul li a:hover {
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .sidebar-wrapper .tagcloud {
    gap: 10px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .sidebar-wrapper .tagcloud {
    margin-bottom: 25px;
  }
}

.tj-latest-comment {
  border-bottom: 2px solid var(--tj-color-light-7);
  margin-bottom: 60px;
}
.tj-latest-comment .title {
  color: var(--tj-color-theme-primary);
  font-size: 30px;
  margin-bottom: 34px;
}
.tj-latest-comment .comment-content-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 34px;
}
@media (max-width: 575px) {
  .tj-latest-comment .comment-content-area {
    display: block;
  }
}
.tj-latest-comment .comment-content-area:last-child {
  padding-left: 80px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-latest-comment .comment-content-area:last-child {
    padding-left: 30px;
  }
}
.tj-latest-comment .comment-content-area .comment-auother {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 575px) {
  .tj-latest-comment .comment-content-area .comment-auother {
    display: block;
  }
}
.tj-latest-comment .comment-avatar {
  max-width: 60px;
  width: 100%;
  margin-right: 20px;
}
@media (max-width: 575px) {
  .tj-latest-comment .comment-avatar {
    margin-right: 0;
    margin-bottom: 25px;
  }
}
.tj-latest-comment .comment-text {
  padding-right: 45px;
}
@media (max-width: 575px) {
  .tj-latest-comment .comment-text {
    padding-left: 0;
  }
}
.tj-latest-comment .comment-text .auother-header {
  margin-bottom: 10px;
}
.tj-latest-comment .comment-text .auother-header .title-link a {
  font-size: 18px;
  color: var(--tj-color-theme-primary);
  font-weight: var(--tj-fw-medium);
}
.tj-latest-comment .comment-text p {
  margin-bottom: 0;
}
@media (max-width: 575px) {
  .tj-latest-comment .comment-reply {
    margin-top: 30px;
  }
}
.tj-latest-comment .comment-reply a {
  background: var(--tj-color-theme-primary);
  border-radius: 58px;
  -webkit-box-shadow: 15px 9px 30px 0px rgba(117, 77, 233, 0.15);
          box-shadow: 15px 9px 30px 0px rgba(117, 77, 233, 0.15);
  font-size: 12px;
  color: var(--tj-color-common-white);
  font-family: var(--tj-ff-body);
  padding: 7px 20px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tj-latest-comment .comment-reply a:hover {
  background-color: var(--tj-color-common-black);
  color: var(--tj-color-common-white);
}

.tj-comment-respond {
  background: -webkit-gradient(linear, left top, right top, from(rgba(32, 217, 161, 0.08)), to(rgba(95, 57, 255, 0.08)));
  background: linear-gradient(90deg, rgba(32, 217, 161, 0.08) 0%, rgba(95, 57, 255, 0.08) 100%);
  padding: 40px;
  border-radius: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-comment-respond {
    padding: 25px 15px;
  }
}
.tj-comment-respond .comment-header {
  margin-bottom: 20px;
}
.tj-comment-respond .comment-header .title {
  color: var(--tj-color-theme-primary);
  font-size: 30px;
}
.tj-comment-respond .comment-header p {
  margin-bottom: 0;
}
.tj-comment-respond .form-input {
  margin-bottom: 20px;
}
.tj-comment-respond .form-input textarea,
.tj-comment-respond .form-input input[type=email],
.tj-comment-respond .form-input input[type=text] {
  border: 1px solid var(--tj-color-border-1);
  border-radius: 10px;
  padding: 14px 20px;
}
.tj-comment-respond .form-input textarea::-webkit-input-placeholder, .tj-comment-respond .form-input input[type=email]::-webkit-input-placeholder, .tj-comment-respond .form-input input[type=text]::-webkit-input-placeholder {
  color: var(--tj-color-grey-9);
}
.tj-comment-respond .form-input textarea::-moz-placeholder, .tj-comment-respond .form-input input[type=email]::-moz-placeholder, .tj-comment-respond .form-input input[type=text]::-moz-placeholder {
  color: var(--tj-color-grey-9);
}
.tj-comment-respond .form-input textarea:-ms-input-placeholder, .tj-comment-respond .form-input input[type=email]:-ms-input-placeholder, .tj-comment-respond .form-input input[type=text]:-ms-input-placeholder {
  color: var(--tj-color-grey-9);
}
.tj-comment-respond .form-input textarea::-ms-input-placeholder, .tj-comment-respond .form-input input[type=email]::-ms-input-placeholder, .tj-comment-respond .form-input input[type=text]::-ms-input-placeholder {
  color: var(--tj-color-grey-9);
}
.tj-comment-respond .form-input textarea::placeholder,
.tj-comment-respond .form-input input[type=email]::placeholder,
.tj-comment-respond .form-input input[type=text]::placeholder {
  color: var(--tj-color-grey-9);
}
.tj-comment-respond .form-input textarea::-ms-input-placeholder,
.tj-comment-respond .form-input input[type=email]::-ms-input-placeholder,
.tj-comment-respond .form-input input[type=text]::-ms-input-placeholder {
  color: var(--tj-color-grey-9);
}
.tj-comment-respond .form-input textarea {
  min-height: 154px;
  resize: none;
}
.tj-comment-respond .tj-blog-details-button .tj-primary-btn {
  width: 100%;
  border-radius: 10px;
  font-size: 14px;
  font-weight: var(--tj-fw-bold);
  font-family: var(--tj-ff-heading);
}

.tj-blog-widget {
  background: -webkit-gradient(linear, left top, right top, from(rgba(32, 217, 161, 0.08)), to(rgba(95, 57, 255, 0.08)));
  background: linear-gradient(90deg, rgba(32, 217, 161, 0.08) 0%, rgba(95, 57, 255, 0.08) 100%);
  margin-bottom: 24px;
  border-radius: 10px;
  padding: 40px;
  position: relative;
  z-index: 3;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-blog-widget {
    padding: 35px 15px;
  }
}
.tj-blog-widget .side-title {
  font-size: 25px;
  color: var(--tj-color-light-3);
  position: relative;
  margin-bottom: 35px;
}
.tj-blog-widget .side-title::before {
  position: absolute;
  content: "";
  bottom: -10px;
  left: 0;
  width: 90px;
  height: 3px;
  background: var(--tj-color-light-3);
}

.sidebar-search form {
  position: relative;
  width: 100%;
  border: 1.5px solid rgba(112, 95, 255, 0.8);
  border-radius: 8px;
}
.sidebar-search form input[type=text] {
  border-radius: 8px;
  width: 100%;
  padding: 14px 20px;
  font-size: 18px !important;
  font-weight: var(--tj-fw-medium);
}
.sidebar-search form input[type=text]::-webkit-input-placeholder {
  color: var(--tj-color-grey-9);
}
.sidebar-search form input[type=text]::-moz-placeholder {
  color: var(--tj-color-grey-9);
}
.sidebar-search form input[type=text]:-ms-input-placeholder {
  color: var(--tj-color-grey-9);
}
.sidebar-search form input[type=text]::-ms-input-placeholder {
  color: var(--tj-color-grey-9);
}
.sidebar-search form input[type=text]::placeholder {
  color: var(--tj-color-grey-9);
}
.sidebar-search form input[type=text]::-ms-input-placeholder {
  color: var(--tj-color-grey-9);
}
.sidebar-search form :focus {
  outline: none;
  border: none;
  -webkit-box-shadow: 0 0 0;
  box-shadow: 0 0 0;
}
.sidebar-search form button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 20px;
}
.sidebar-search form button i {
  font-size: 18px;
  -webkit-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  color: rgba(112, 95, 255, 0.7);
}
.sidebar-search form button i:hover {
  color: var(--tj-color-gradient-2);
}

.sidebar-catagory ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.sidebar-catagory ul li {
  font-size: 18px;
  font-weight: var(--tj-fw-medium);
  margin-bottom: 20px;
}
.sidebar-catagory ul li:last-child {
  margin-bottom: 0;
}
.sidebar-catagory ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.sidebar-catagory ul li a span {
  width: 18px;
  height: 18px;
  line-height: 18px;
  border-radius: 50%;
  display: inline-block;
  font-size: 12px;
  text-align: center;
  color: var(--tj-color-common-white);
  background: var(--tj-color-common-black);
}
.sidebar-catagory ul li a:hover {
  color: var(--tj-color-light-3);
}
.sidebar-catagory ul li a:hover span {
  background-color: var(--tj-color-light-3);
  color: var(--tj-color-common-white);
}

.sidebar-recent-post .single-post {
  margin-bottom: 25px;
}
.sidebar-recent-post .single-post:last-child {
  margin-bottom: 0;
}
.sidebar-recent-post .single-post .post-image {
  margin-right: 20px;
  max-width: 75px;
  width: 100%;
}
.sidebar-recent-post .single-post .post-image a {
  display: inline-block;
  width: 100%;
}
.sidebar-recent-post .single-post .post-content .sub-title {
  font-size: 14px;
  font-weight: var(--tj-fw-bold);
  display: block;
  margin-bottom: 10px;
}
.sidebar-recent-post .single-post .post-content .sub-title i {
  color: var(--tj-color-light-3);
  font-size: 18px;
  margin-right: 15px;
}
.sidebar-recent-post .single-post .post-content .title-link {
  margin-bottom: 0;
}
.sidebar-recent-post .single-post .post-content .title-link a {
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.sidebar-recent-post .single-post .post-content .title-link a:hover {
  color: var(--tj-color-light-3);
}

.tagcloud {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 15px;
}
.tagcloud span {
  font-size: 18px;
  color: var(--tj-color-theme-primary);
  font-weight: var(--tj-fw-medium);
  margin-right: 10px;
}
.tagcloud a {
  background: var(--tj-color-light-7);
  font-size: 14px;
  font-weight: 300;
  color: var(--tj-color-common-black);
  border-radius: 5px;
  padding: 10px 20px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tagcloud a:hover {
  color: var(--tj-color-common-white);
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
}

.details-item {
  margin-bottom: 60px;
}
.details-item:last-child {
  margin-bottom: 0;
}
.details-item .details-title .title {
  font-size: 25px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .details-item .details-title .title {
    font-size: 22px;
  }
}
.details-item .blog-button {
  margin-top: 30px;
}
.details-item .blog-thumb-image {
  position: relative;
}
.details-item .blog-thumb-image .video-play {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.details-item .blog-thumb-image .video-play {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
  line-height: 80px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .details-item .blog-thumb-image .video-play {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
}
.details-item .blog-thumb-image .video-play i {
  color: var(--tj-color-common-white);
  font-size: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .details-item .blog-thumb-image .video-play i {
    font-size: 20px;
  }
}

blockquote {
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  border-radius: 10px;
  position: relative;
  padding: 30px 33px;
  margin-bottom: 60px;
  z-index: 3;
}
blockquote::before {
  content: "\f10d";
  font-family: "Font Awesome 6 Pro";
  background-size: contain;
  display: inline-block;
  font-size: 50px;
  color: var(--tj-color-common-white);
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  blockquote::before {
    width: 45px;
    height: 30px;
    margin-bottom: 10px;
  }
}
blockquote p {
  color: var(--tj-color-common-white);
  font-family: var(--tj-ff-body);
  font-weight: var(--tj-fw-sbold);
  margin-bottom: 10px;
}
blockquote cite {
  color: var(--tj-color-common-white);
  font-family: var(--tj-ff-body);
  font-weight: var(--tj-fw-bold);
  position: relative;
  padding-left: 85px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  blockquote cite {
    padding-left: 55px;
  }
}
blockquote cite::before {
  position: absolute;
  content: "";
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  width: 62px;
  height: 2px;
  background: var(--tj-color-light-9);
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  blockquote cite::before {
    width: 40px;
  }
}

.blog-pagination ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
}
.blog-pagination ul li .page-numbers {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 45px;
  height: 45px;
  border: 1px solid var(--tj-color-theme-primary);
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  font-size: 18px;
  background-color: transparent;
  font-weight: var(--tj-fw-medium);
  color: var(--tj-color-common-black);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.blog-pagination ul li .page-numbers:hover, .blog-pagination ul li .page-numbers.current {
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  border-color: transparent;
  color: var(--tj-color-common-white);
}

/* !END: Theme Blog CSS */
/**----------------------------------------
START: Theme Contact CSS
----------------------------------------*/
.tj-contact-section {
  padding-bottom: 120px;
  padding-top: 120px;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-contact-section {
    padding-bottom: 80px;
    padding-top: 80px;
  }
}

.contact-page {
  padding-top: 80px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .contact-page {
    padding-top: 40px;
  }
}

.contact-section-shape .contact-bg-shape {
  position: absolute;
  right: 0;
  top: 40%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}
.contact-section-shape .contact-bg-shape1 {
  position: absolute;
  right: 50px;
  top: 30%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}
.contact-section-shape .contact-bg-shape2 {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}
.contact-section-shape .contact-bg-shape3 {
  position: absolute;
  left: 50px;
  top: 40%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}

.contact-form-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-radius: 40px;
  background: var(--tj-color-grey-5);
  padding: 60px;
  position: relative;
  z-index: 3;
  overflow: hidden;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-form-area {
    padding: 35px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .contact-form-area {
    padding: 30px 15px;
  }
}

.contact-left-content {
  max-width: 600px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .contact-left-content {
    max-width: 520px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-left-content {
    max-width: 450px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .contact-left-content {
    margin-bottom: 50px;
  }
}
.contact-left-content .tj-sec-heading {
  margin-bottom: 34px;
}
.contact-left-content .tj-sec-heading .sec-title {
  max-width: 520px;
}
.contact-left-content .comntact-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 30px;
}
.contact-left-content .comntact-list:last-child {
  margin-bottom: 0;
}
.contact-left-content .comntact-list .contact-icon {
  width: 55px;
  height: 55px;
  line-height: 48px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  font-size: 25px;
  color: var(--tj-color-common-white);
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  margin-right: 20px;
}
.contact-left-content .comntact-list .contact-icon img {
  max-width: 27px;
  width: 100%;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .contact-left-content .comntact-list .contact-icon {
    width: 40px;
    height: 40px;
    line-height: 35px;
    margin-right: 15px;
  }
}
.contact-left-content .comntact-list .contact-icon img {
  max-width: 27px;
  width: 100%;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .contact-left-content .comntact-list .contact-icon img {
    width: 20px;
  }
}
.contact-left-content .desc {
  max-width: 650px;
}
.contact-left-content .contact-header a {
  color: var(--tj-color-common-black);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  font-size: 18px;
  font-weight: var(--tj-fw-medium);
}
.contact-left-content .contact-header a:hover {
  color: var(--tj-color-theme-primary);
}
.contact-left-content .contact-header span {
  line-height: 1.2;
}
.contact-left-content .tj-contact-button {
  margin-top: 40px;
}
.contact-left-content .tj-contact-button .btn {
  margin-right: 15px;
}
.contact-left-content .tj-contact-button .btn:last-child {
  margin-right: 0;
}

.contact-form-box {
  max-width: 510px;
  width: 100%;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .contact-form-box {
    max-width: 450px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-form-box {
    max-width: 400px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .contact-form-box {
    max-width: 100%;
  }
}
.contact-form-box .form-input {
  margin-bottom: 20px;
}
.contact-form-box .form-input label {
  margin-bottom: 10px;
  color: var(--tj-color-common-black);
}
.contact-form-box .form-input input[type=text] {
  width: 100%;
  border-radius: 8px;
  padding: 17px 20px;
  background: var(--tj-color-grey-5);
  border: 1px solid var(--tj-color-light-7);
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .contact-form-box .form-input input[type=text] {
    padding: 13px 20px;
  }
}
.contact-form-box .form-input input[type=email] {
  width: 100%;
  border-radius: 8px;
  padding: 17px 20px;
  background: var(--tj-color-grey-5);
  border: 1px solid var(--tj-color-light-7);
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .contact-form-box .form-input input[type=email] {
    padding: 13px 20px;
  }
}
.contact-form-box .form-textarea {
  margin-bottom: 20px;
}
.contact-form-box .form-textarea label {
  margin-bottom: 10px;
  color: var(--tj-color-common-black);
}
.contact-form-box .form-textarea textarea {
  width: 100%;
  background: var(--tj-color-grey-5);
  border: 1px solid var(--tj-color-light-7);
  border-radius: 10px;
  padding: 17px 20px;
  min-height: 140px;
  resize: none;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .contact-form-box .form-textarea textarea {
    min-height: 110px;
  }
}
.contact-form-box .tj-contact-button .tj-primary-btn {
  border-radius: 10px;
  width: 100%;
}

.contact-form-box .form-input input:focus {
  border: 1px solid var(--tj-color-theme-primary);
}

.contact-form-box .form-textarea textarea:focus {
  border: 1px solid var(--tj-color-theme-primary);
}

.tj-contact-section-two {
  padding-top: 120px;
  padding-bottom: 120px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: var(--tj-color-light-8);
  overflow: hidden;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-contact-section-two {
    padding-top: 85px;
    padding-bottom: 50px;
  }
}
.tj-contact-section-two .contact-sec-shape {
  position: absolute;
  top: -50px;
  right: 0;
  opacity: 0.1;
}

.contact-form-box2 {
  border-radius: 20px;
  background: var(--tj-color-common-white);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  padding: 50px;
  position: relative;
  z-index: 3;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .contact-form-box2 {
    padding: 25px 15px;
    margin-bottom: 50px;
  }
}
.contact-form-box2 .form-input {
  margin-bottom: 20px;
}
.contact-form-box2 .form-input input[type=text] {
  width: 100%;
  border-radius: 8px;
  padding: 17px 20px;
  background: var(--tj-color-grey-5);
}
.contact-form-box2 .form-input input[type=email] {
  width: 100%;
  border-radius: 8px;
  padding: 17px 20px;
  background: var(--tj-color-grey-5);
}
.contact-form-box2 .form-textarea {
  margin-bottom: 20px;
}
.contact-form-box2 .form-textarea textarea {
  width: 100%;
  background: var(--tj-color-grey-5);
  border-radius: 10px;
  padding: 17px 20px;
  min-height: 140px;
  resize: none;
}
.contact-form-box2 .tj-contact-button .tj-primary-btn {
  width: 100%;
  border-radius: 8px;
}

.contact-form-box3 {
  background: transparent;
  position: relative;
  z-index: 3;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .contact-form-box3 {
    padding: 25px 15px;
    margin-bottom: 50px;
  }
}
.contact-form-box3 .form-input {
  margin-bottom: 20px;
}
.contact-form-box3 .form-input input[type=text] {
  width: 100%;
  border-radius: 15px;
  padding: 17px 20px;
  border: 1px solid var(--tj-color-border-2);
  background: transparent;
  color: var(--tj-color-common-white);
}
.contact-form-box3 .form-input input[type=email] {
  width: 100%;
  border-radius: 15px;
  padding: 17px 20px;
  background: transparent;
  border: 1px solid var(--tj-color-border-2);
  color: var(--tj-color-common-white);
}
.contact-form-box3 .form-textarea {
  margin-bottom: 20px;
}
.contact-form-box3 .form-textarea textarea {
  width: 100%;
  background: transparent;
  border-radius: 10px;
  padding: 17px 20px;
  min-height: 140px;
  resize: none;
  border: 1px solid var(--tj-color-border-2);
  color: var(--tj-color-common-white);
}
.contact-form-box3 .tj-contact-button .tj-black-btn-two {
  width: 100%;
  border-radius: 15px;
}
.contact-form-box3 .tj-contact-button .tj-black-btn-two::before {
  border-radius: 15px;
}

.contact-form-box3 .form-input input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.contact-form-box3 .form-input input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.contact-form-box3 .form-input input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.contact-form-box3 .form-input input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.contact-form-box3 .form-input input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.contact-form-box3 .form-textarea textarea::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.contact-form-box3 .form-textarea textarea::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.contact-form-box3 .form-textarea textarea::-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.contact-form-box3 .form-textarea textarea:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.contact-form-box3 .form-textarea textarea::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.contact-right-content {
  padding-left: 75px;
  position: relative;
  z-index: 3;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-right-content {
    padding-left: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .contact-right-content {
    padding-left: 0;
  }
}
.contact-right-content .tj-sec-heading {
  margin-bottom: 25px;
}
.contact-right-content .tj-sec-heading .sec-title {
  max-width: 520px;
}
.contact-right-content .comntact-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 30px;
}
.contact-right-content .comntact-list:last-child {
  margin-bottom: 0;
}
.contact-right-content .comntact-list .contact-icon {
  width: 46px;
  height: 46px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  color: var(--tj-color-common-white);
  background: var(--tj-color-common-black);
  margin-right: 20px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .contact-right-content .comntact-list .contact-icon {
    width: 40px;
    height: 40px;
    line-height: 35px;
    margin-right: 15px;
  }
}
.contact-right-content .comntact-list .contact-icon img {
  max-width: 25px;
  width: 100%;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .contact-right-content .comntact-list .contact-icon img {
    width: 20px;
  }
}
.contact-right-content .desc {
  margin-bottom: 25px;
}
.contact-right-content .contact-header span {
  font-size: 22px;
  font-weight: var(--tj-fw-bold);
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1;
}
.contact-right-content .contact-header a {
  color: var(--tj-color-common-black);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  font-size: 22px;
}
.contact-right-content .contact-header a:hover {
  color: var(--tj-color-theme-primary);
}
.contact-right-content .tj-contact-button {
  margin-top: 40px;
}
.contact-right-content .tj-contact-button .btn {
  margin-right: 15px;
}
.contact-right-content .tj-contact-button .btn:last-child {
  margin-right: 0;
}

.form-input input::-ms-input-placeholder {
  color: rgba(58, 58, 58, 0.3);
}

.form-input input::-webkit-input-placeholder {
  color: rgba(58, 58, 58, 0.3);
}

.form-input input::-moz-placeholder {
  color: rgba(58, 58, 58, 0.3);
}

.form-input input:-ms-input-placeholder {
  color: rgba(58, 58, 58, 0.3);
}

.form-input input::placeholder {
  color: rgba(58, 58, 58, 0.3);
}

.form-textarea textarea::-ms-input-placeholder {
  color: rgba(58, 58, 58, 0.3);
}

.form-textarea textarea::-webkit-input-placeholder {
  color: rgba(58, 58, 58, 0.3);
}

.form-textarea textarea::-moz-placeholder {
  color: rgba(58, 58, 58, 0.3);
}

.form-textarea textarea:-ms-input-placeholder {
  color: rgba(58, 58, 58, 0.3);
}

.form-textarea textarea::placeholder {
  color: rgba(58, 58, 58, 0.3);
}

/* !END: Theme Contact CSS */
/**----------------------------------------
START: Theme Footer CSS
----------------------------------------*/
.tj-footer-area {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--tj-color-grey-6);
  position: relative;
  overflow: hidden;
}
.tj-footer-area .footer-menu-area {
  padding-top: 120px;
  padding-bottom: 65px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-footer-area .footer-menu-area {
    padding-top: 80px;
    padding-bottom: 10px;
  }
}
.tj-footer-area .footer-menu-area .footer-content-info {
  max-width: 340px;
  position: relative;
  z-index: 3;
}
.tj-footer-area .footer-menu-area .footer-content-info .footer-logo {
  max-width: 185px;
  width: 100%;
  margin-bottom: 35px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-footer-area .footer-menu-area .footer-content-info .footer-logo {
    max-width: 150px;
  }
}
.tj-footer-area .footer-menu-area .footer-content-info .footer-logo a {
  display: inline-block;
}
.tj-footer-area .footer-menu-area .footer-content-info p {
  color: var(--tj-color-grey-5);
  font-family: var(--tj-ff-heading);
  margin-bottom: 30px;
}
.tj-footer-area .footer-menu-area .footer-content-info .footer-share ul li {
  display: inline-block;
  margin-right: 10px;
  border-radius: 50%;
}
.tj-footer-area .footer-menu-area .footer-content-info .footer-share ul li:last-child {
  margin-right: 0;
}
.tj-footer-area .footer-menu-area .footer-content-info .footer-share ul li a {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: var(--tj-color-common-white);
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  color: var(--tj-color-common-black);
  font-size: 20px;
}
.tj-footer-area .footer-menu-area .footer-content-info .footer-share ul li a:hover {
  background-color: var(--tj-color-theme-primary);
  color: var(--tj-color-common-white);
}
.tj-footer-area .footer-menu-area .footer-content-info .footer-share ul li a:hover svg path {
  fill: var(--tj-color-common-white);
}
.tj-footer-area .footer-menu-area .footer_title {
  color: var(--tj-color-common-white);
  margin-bottom: 40px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tj-footer-area .footer-menu-area .footer_title {
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-footer-area .footer-menu-area .footer_title {
    margin-bottom: 25px;
  }
}
.tj-footer-area .footer-menu-area .widget_nav_menu {
  position: relative;
  z-index: 3;
}
.tj-footer-area .footer-menu-area .widget_nav_menu .footer-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.tj-footer-area .footer-menu-area .widget_nav_menu .footer-menu ul li {
  margin-bottom: 15px;
}
.tj-footer-area .footer-menu-area .widget_nav_menu .footer-menu ul li a {
  color: var(--tj-color-light-4);
  font-family: var(--tj-ff-heading);
  font-weight: var(--tj-fw-regular);
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tj-footer-area .footer-menu-area .widget_nav_menu .footer-menu ul li a:hover {
  color: transparent;
}
.tj-footer-area .footer-menu-area .widget_contact_menu {
  position: relative;
  z-index: 3;
}
.tj-footer-area .footer-menu-area .widget_contact_menu .footer-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.tj-footer-area .footer-menu-area .widget_contact_menu .footer-menu ul li {
  padding-left: 45px;
  margin-bottom: 20px;
  position: relative;
  z-index: 9;
}
.tj-footer-area .footer-menu-area .widget_contact_menu .footer-menu ul li a {
  color: var(--tj-color-light-4);
  font-family: var(--tj-ff-heading);
  font-weight: var(--tj-fw-regular);
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tj-footer-area .footer-menu-area .widget_contact_menu .footer-menu ul li span {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 35px;
  height: 35px;
  line-height: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.4);
  color: var(--tj-color-common-white);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tj-footer-area .footer-menu-area .widget_contact_menu .footer-menu ul li span::before {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  content: "";
  width: 35px;
  height: 35px;
  line-height: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}
.tj-footer-area .footer-menu-area .widget_contact_menu .footer-menu ul li:hover a {
  color: transparent;
}
.tj-footer-area .footer-menu-area .widget_contact_menu .footer-menu ul li:hover span {
  border-color: transparent;
}
.tj-footer-area .footer-menu-area .widget_contact_menu .footer-menu ul li:hover span::before {
  opacity: 1;
  visibility: visible;
}
.tj-footer-area .copyright-area {
  padding: 20px 0;
}
.tj-footer-area .copyright-content-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
  z-index: 3;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-footer-area .copyright-content-area {
    text-align: center;
    display: block;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-footer-area .copyright-content-area .copyright-left-content {
    margin-bottom: 20px;
  }
}
.tj-footer-area .copyright-content-area .copyright-left-content p {
  font-family: var(--tj-ff-heading);
  color: var(--tj-color-grey-5);
  margin-bottom: 0;
}
.tj-footer-area .copyright-content-area .copyright-left-content a {
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: var(--tj-color-grey-5);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tj-footer-area .copyright-content-area .copyright-left-content a:hover {
  color: transparent;
}
.tj-footer-area .copyright-content-area .copyright-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.tj-footer-area .copyright-content-area .copyright-menu ul li {
  display: inline-block;
  margin-left: 25px;
}
.tj-footer-area .copyright-content-area .copyright-menu ul li:first-child {
  margin-left: 0;
}
.tj-footer-area .copyright-content-area .copyright-menu ul li a {
  font-family: var(--tj-ff-heading);
  font-weight: var(--tj-fw-regular);
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: var(--tj-color-grey-5);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tj-footer-area .copyright-content-area .copyright-menu ul li a:hover {
  color: transparent;
}

.tj-footer-two {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--tj-color-common-white);
  position: relative;
  overflow: hidden;
}
.tj-footer-two .footer-menu-area {
  padding-top: 120px;
  padding-bottom: 65px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-footer-two .footer-menu-area {
    padding-top: 80px;
    padding-bottom: 10px;
  }
}
.tj-footer-two .footer-menu-area .footer-content-info {
  max-width: 340px;
  position: relative;
  z-index: 3;
}
.tj-footer-two .footer-menu-area .footer-content-info .footer-logo {
  max-width: 185px;
  width: 100%;
  margin-bottom: 35px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-footer-two .footer-menu-area .footer-content-info .footer-logo {
    max-width: 150px;
  }
}
.tj-footer-two .footer-menu-area .footer-content-info .footer-logo a {
  display: inline-block;
}
.tj-footer-two .footer-menu-area .footer-content-info p {
  color: var(--tj-color-text-body);
  font-family: var(--tj-ff-heading);
  margin-bottom: 30px;
}
.tj-footer-two .footer-menu-area .footer-content-info .footer-share ul li {
  display: inline-block;
  margin-right: 10px;
  border-radius: 50%;
}
.tj-footer-two .footer-menu-area .footer-content-info .footer-share ul li:last-child {
  margin-right: 0;
}
.tj-footer-two .footer-menu-area .footer-content-info .footer-share ul li a {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: var(--tj-color-common-black);
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  z-index: 3;
  color: var(--tj-color-common-white);
  font-size: 20px;
}
.tj-footer-two .footer-menu-area .footer-content-info .footer-share ul li a::before {
  position: absolute;
  content: "";
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  color: var(--tj-color-common-black);
  -webkit-transition: 0.1s;
  transition: 0.1s;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}
.tj-footer-two .footer-menu-area .footer-content-info .footer-share ul li a:hover {
  color: var(--tj-color-common-white);
}
.tj-footer-two .footer-menu-area .footer-content-info .footer-share ul li a:hover::before {
  opacity: 1;
  visibility: visible;
}
.tj-footer-two .footer-menu-area .footer_title {
  color: var(--tj-color-common-black);
  margin-bottom: 40px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tj-footer-two .footer-menu-area .footer_title {
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-footer-two .footer-menu-area .footer_title {
    margin-bottom: 25px;
  }
}
.tj-footer-two .footer-menu-area .widget_nav_menu {
  position: relative;
  z-index: 3;
}
.tj-footer-two .footer-menu-area .widget_nav_menu .footer-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.tj-footer-two .footer-menu-area .widget_nav_menu .footer-menu ul li {
  margin-bottom: 15px;
}
.tj-footer-two .footer-menu-area .widget_nav_menu .footer-menu ul li a {
  color: var(--tj-color-text-body);
  font-family: var(--tj-ff-heading);
  font-weight: var(--tj-fw-regular);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tj-footer-two .footer-menu-area .widget_nav_menu .footer-menu ul li a:hover {
  color: var(--tj-color-theme-primary);
}
.tj-footer-two .footer-menu-area .widget_contact_menu {
  position: relative;
  z-index: 3;
}
.tj-footer-two .footer-menu-area .widget_contact_menu .footer-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.tj-footer-two .footer-menu-area .widget_contact_menu .footer-menu ul li {
  padding-left: 45px;
  margin-bottom: 20px;
  position: relative;
  z-index: 9;
}
.tj-footer-two .footer-menu-area .widget_contact_menu .footer-menu ul li a {
  color: var(--tj-color-text-body);
  font-family: var(--tj-ff-heading);
  font-weight: var(--tj-fw-regular);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tj-footer-two .footer-menu-area .widget_contact_menu .footer-menu ul li span {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 35px;
  height: 35px;
  line-height: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  border: 1px solid var(--tj-color-light-3);
  color: var(--tj-color-light-3);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tj-footer-two .footer-menu-area .widget_contact_menu .footer-menu ul li span::before {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  content: "";
  width: 35px;
  height: 35px;
  line-height: 1;
  border-radius: 50%;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}
.tj-footer-two .footer-menu-area .widget_contact_menu .footer-menu ul li:hover a {
  color: var(--tj-color-theme-primary);
}
.tj-footer-two .footer-menu-area .widget_contact_menu .footer-menu ul li:hover span {
  border-color: transparent;
  color: var(--tj-color-common-white);
}
.tj-footer-two .footer-menu-area .widget_contact_menu .footer-menu ul li:hover span::before {
  opacity: 1;
  visibility: visible;
}
.tj-footer-two .copyright-area {
  padding: 20px 0;
}
.tj-footer-two .copyright-content-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
  z-index: 3;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-footer-two .copyright-content-area {
    text-align: center;
    display: block;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-footer-two .copyright-content-area .copyright-left-content {
    margin-bottom: 20px;
  }
}
.tj-footer-two .copyright-content-area .copyright-left-content p {
  font-family: var(--tj-ff-heading);
  color: var(--tj-color-text-body);
  margin-bottom: 0;
}
.tj-footer-two .copyright-content-area .copyright-left-content a {
  color: var(--tj-color-text-body);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tj-footer-two .copyright-content-area .copyright-left-content a:hover {
  color: var(--tj-color-theme-primary);
}
.tj-footer-two .copyright-content-area .copyright-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.tj-footer-two .copyright-content-area .copyright-menu ul li {
  display: inline-block;
  margin-left: 25px;
}
.tj-footer-two .copyright-content-area .copyright-menu ul li:first-child {
  margin-left: 0;
}
.tj-footer-two .copyright-content-area .copyright-menu ul li a {
  font-family: var(--tj-ff-heading);
  font-weight: var(--tj-fw-regular);
  color: var(--tj-color-text-body);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tj-footer-two .copyright-content-area .copyright-menu ul li a:hover {
  color: var(--tj-color-theme-primary);
}
.tj-footer-two .footer-section-shape1 .footer-group-shape {
  position: absolute;
  right: 0;
  top: -60px;
}
.tj-footer-two .footer-section-shape1 .footer-group-shape1 {
  position: absolute;
  left: 0;
  bottom: 0;
}

.tj-footer-three {
  background-color: var(--tj-color-light-10);
  position: relative;
  overflow: hidden;
}
.tj-footer-three::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  mix-blend-mode: soft-light;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.tj-footer-three .footer-top-area {
  padding-top: 120px;
  padding-bottom: 60px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-footer-three .footer-top-area {
    padding-top: 80px;
    padding-bottom: 20px;
  }
}
.tj-footer-three .footer-top-area .contact-right-content {
  padding-left: 0;
  max-width: 590px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-footer-three .footer-top-area .contact-right-content {
    margin-bottom: 45px;
  }
}
.tj-footer-three .footer-top-area .contact-right-content .tj-sec-heading {
  margin-bottom: 0;
}
.tj-footer-three .footer-top-area .contact-right-content .title {
  color: var(--tj-color-common-white);
  margin-bottom: 20px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-footer-three .footer-top-area .contact-right-content .title {
    font-size: 35px;
  }
}
.tj-footer-three .footer-top-area .contact-right-content .desc p {
  color: var(--tj-color-common-white);
}
.tj-footer-three .footer-top-area .contact-right-content .comntact-list .contact-icon {
  width: 60px;
  height: 60px;
  line-height: 56px;
  text-align: center;
  border-radius: 50%;
  background: var(--tj-color-common-white);
}
.tj-footer-three .footer-top-area .contact-right-content .contact-header p {
  color: var(--tj-color-common-white);
  font-size: 22px;
}
.tj-footer-three .footer-top-area .contact-right-content .contact-header a {
  color: var(--tj-color-common-white);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  font-weight: var(--tj-fw-bold);
}
.tj-footer-three .footer-top-area .contact-right-content .contact-header a:hover {
  color: var(--tj-color-common-black);
}
.tj-footer-three .footer1-col-3,
.tj-footer-three .footer1-col-5 {
  padding-left: 0;
}
.tj-footer-three .footer1-col-2 {
  padding-left: 50px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-footer-three .footer1-col-2 {
    padding-left: 0;
  }
}
.tj-footer-three .footer1-col-4 {
  padding-left: 65px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-footer-three .footer1-col-4 {
    padding-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-footer-three .contact-form-box3 {
    margin-bottom: 35px;
    padding: 0;
  }
}
.tj-footer-three .footer-menu-area {
  position: relative;
  padding-top: 80px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-footer-three .footer-menu-area {
    padding-top: 50px;
  }
}
.tj-footer-three .footer-menu-area::before {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 0;
  height: 2px;
  width: 1300px;
  background: rgba(255, 255, 255, 0.5);
}
.tj-footer-three .footer-menu-area .footer-content-info {
  position: relative;
  z-index: 3;
}
.tj-footer-three .footer-menu-area .footer-content-info .footer-logo {
  max-width: 185px;
  width: 100%;
  margin-bottom: 35px;
}
.tj-footer-three .footer-menu-area .footer-content-info .footer-logo a {
  display: inline-block;
}
.tj-footer-three .footer-menu-area .footer-content-info p {
  color: var(--tj-color-common-white);
  font-family: var(--tj-ff-heading);
  margin-bottom: 30px;
}
.tj-footer-three .footer-menu-area .footer-content-info .footer-share ul li {
  display: inline-block;
  margin-right: 10px;
  border-radius: 50%;
}
.tj-footer-three .footer-menu-area .footer-content-info .footer-share ul li:last-child {
  margin-right: 0;
}
.tj-footer-three .footer-menu-area .footer-content-info .footer-share ul li a {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: var(--tj-color-common-white);
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  z-index: 3;
  color: var(--tj-color-light-3);
  font-size: 20px;
}
.tj-footer-three .footer-menu-area .footer-content-info .footer-share ul li a:hover {
  background-color: var(--tj-color-common-black);
  color: var(--tj-color-common-white);
}
.tj-footer-three .footer-menu-area .footer_title {
  color: var(--tj-color-common-white);
  margin-bottom: 40px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tj-footer-three .footer-menu-area .footer_title {
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-footer-three .footer-menu-area .footer_title {
    margin-bottom: 25px;
  }
}
.tj-footer-three .footer-menu-area .widget_nav_menu {
  position: relative;
  z-index: 3;
}
.tj-footer-three .footer-menu-area .widget_nav_menu .footer-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.tj-footer-three .footer-menu-area .widget_nav_menu .footer-menu ul li {
  margin-bottom: 15px;
}
.tj-footer-three .footer-menu-area .widget_nav_menu .footer-menu ul li a {
  color: var(--tj-color-common-white);
  font-family: var(--tj-ff-heading);
  font-weight: var(--tj-fw-regular);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tj-footer-three .footer-menu-area .widget_nav_menu .footer-menu ul li a:hover {
  color: var(--tj-color-theme-primary);
}
.tj-footer-three .footer-menu-area .widget_contact_menu {
  position: relative;
  z-index: 3;
}
.tj-footer-three .footer-menu-area .widget_contact_menu .footer-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.tj-footer-three .footer-menu-area .widget_contact_menu .footer-menu ul li {
  padding-left: 45px;
  margin-bottom: 20px;
  position: relative;
  z-index: 9;
}
.tj-footer-three .footer-menu-area .widget_contact_menu .footer-menu ul li a {
  color: var(--tj-color-grey-5);
  font-family: var(--tj-ff-heading);
  font-weight: var(--tj-fw-regular);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tj-footer-three .footer-menu-area .widget_contact_menu .footer-menu ul li span {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 35px;
  height: 35px;
  line-height: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.4);
  color: var(--tj-color-common-white);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tj-footer-three .footer-menu-area .widget_contact_menu .footer-menu ul li span::before {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  content: "";
  width: 35px;
  height: 35px;
  line-height: 1;
  border-radius: 50%;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}
.tj-footer-three .footer-menu-area .widget_contact_menu .footer-menu ul li:hover a {
  color: var(--tj-color-theme-primary);
}
.tj-footer-three .footer-menu-area .widget_contact_menu .footer-menu ul li:hover span {
  border-color: transparent;
}
.tj-footer-three .footer-menu-area .widget_contact_menu .footer-menu ul li:hover span::before {
  opacity: 1;
  visibility: visible;
}
.tj-footer-three .widget_gallery {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  border-radius: 10px;
}
.tj-footer-three .widget_gallery .footer-gallery-item {
  -webkit-transition: 0.4s;
  transition: 0.4s;
  display: inline-block;
  position: relative;
  z-index: 1;
}
.tj-footer-three .widget_gallery .footer-gallery-item::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  left: 0;
  top: 0;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  border-radius: 10px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tj-footer-three .widget_gallery .footer-gallery-item .gallery-icon {
  position: absolute;
  color: var(--tj-color-common-white);
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tj-footer-three .widget_gallery .footer-gallery-item img {
  border-radius: 10px;
  max-width: 110px;
  width: 100%;
}
.tj-footer-three .widget_gallery .footer-gallery-item:hover::before {
  opacity: 0.5;
  visibility: visible;
  width: 100%;
  height: 100%;
}
.tj-footer-three .widget_gallery .footer-gallery-item:hover .gallery-icon {
  opacity: 1;
  visibility: visible;
}
.tj-footer-three .copyright-area {
  padding: 20px 0;
}
.tj-footer-three .copyright-content-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
  z-index: 3;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-footer-three .copyright-content-area {
    text-align: center;
    display: block;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-footer-three .copyright-content-area .copyright-left-content {
    margin-bottom: 20px;
  }
}
.tj-footer-three .copyright-content-area .copyright-left-content p {
  font-family: var(--tj-ff-heading);
  color: var(--tj-color-grey-5);
  margin-bottom: 0;
}
.tj-footer-three .copyright-content-area .copyright-left-content a {
  color: var(--tj-color-grey-5);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tj-footer-three .copyright-content-area .copyright-left-content a:hover {
  color: var(--tj-color-theme-primary);
}
.tj-footer-three .copyright-content-area .copyright-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.tj-footer-three .copyright-content-area .copyright-menu ul li {
  display: inline-block;
  margin-left: 25px;
}
.tj-footer-three .copyright-content-area .copyright-menu ul li:first-child {
  margin-left: 0;
}
.tj-footer-three .copyright-content-area .copyright-menu ul li a {
  font-family: var(--tj-ff-heading);
  font-weight: var(--tj-fw-regular);
  color: var(--tj-color-grey-5);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tj-footer-three .copyright-content-area .copyright-menu ul li a:hover {
  color: var(--tj-color-theme-primary);
}

.mfp-bottom-bar {
  -webkit-transition: all 0.6s ease-out;
  transition: all 0.6s ease-out;
}
.mfp-bottom-bar .mfp-title {
  display: none !important;
}
.mfp-bottom-bar .mfp-close {
  display: none;
}
.mfp-bottom-bar img {
  border-radius: 10px;
}

.mfp-image-holder .mfp-close {
  display: none;
}

.mfp-iframe-holder .mfp-close {
  display: none;
}

.footer-section-shape .footer-bg-shape {
  position: absolute;
  left: 0;
  bottom: 0;
}
.footer-section-shape .footer-bg-shape1 {
  position: absolute;
  right: 0;
  bottom: 0;
}
.footer-section-shape .footer-bg-shape2 {
  position: absolute;
  bottom: 5%;
  right: 6%;
  opacity: 0.3;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .footer-section-shape .footer-bg-shape2 {
    display: none;
  }
}
.footer-section-shape .footer-bg-shape3 {
  position: absolute;
  bottom: 10%;
  right: 6%;
  opacity: 0.9;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .footer-section-shape .footer-bg-shape3 {
    display: none;
  }
}

.footer-sec-shape .shape-1 {
  position: absolute;
  left: 60px;
  top: 15%;
}
.footer-sec-shape .shape-2 {
  position: absolute;
  left: 0;
  top: 22%;
}
.footer-sec-shape .shape-3 {
  position: absolute;
  left: 75px;
  bottom: 10%;
}
.footer-sec-shape .shape-4 {
  position: absolute;
  top: 60px;
  right: 0;
}
.footer-sec-shape .shape-5 {
  position: absolute;
  bottom: 0;
  right: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .footer-sec-shape {
    display: none;
  }
}

.footer-widget {
  margin-bottom: 40px;
  position: relative;
  z-index: 3;
}

.footer1-col-2,
.footer1-col-3,
.footer1-col-4,
.footer1-col-5 {
  padding-left: 45px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer1-col-2,
  .footer1-col-3,
  .footer1-col-4,
  .footer1-col-5 {
    padding-left: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .footer1-col-2,
  .footer1-col-3,
  .footer1-col-4,
  .footer1-col-5 {
    padding-left: 0;
  }
}

/* !END: Theme Footer CSS */
/**----------------------------------------
START: Theme CTA CSS
----------------------------------------*/
.tj-cta-section {
  padding-top: 115px;
  padding-bottom: 120px;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  position: relative;
}
.tj-cta-section::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-cta-section {
    padding-top: 75px;
    padding-bottom: 80px;
  }
}
.tj-cta-section .tj-sec-heading {
  margin-bottom: 0;
}

.cta-content-area {
  max-width: 1020px;
  margin: auto;
}
.cta-content-area .title {
  color: var(--tj-color-common-white);
  margin-bottom: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta-content-area .title {
    font-size: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .cta-content-area .title {
    font-size: 32px;
  }
}
.cta-content-area .cta-button {
  margin-top: 40px;
}

/* !END: Theme CTA CSS */
/**----------------------------------------
START: Theme Price CSS
----------------------------------------*/
.tj-price-section {
  padding-top: 120px;
  padding-bottom: 90px;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-price-section {
    padding-top: 75px;
    padding-bottom: 50px;
  }
}
.tj-price-section .tj-sec-heading {
  max-width: 600px;
  text-align: center;
}

.price-group-shape .price-shape-one {
  position: absolute;
  right: 8%;
  top: 13%;
}
.price-group-shape .price-shape-two {
  position: absolute;
  right: 4.5%;
  top: 18%;
  opacity: 0.2;
}
.price-group-shape .price-shape-three {
  position: absolute;
  left: 60px;
  bottom: 20%;
}
.price-group-shape .price-shape-four {
  position: absolute;
  left: 15px;
  bottom: 12%;
  opacity: 0.4;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .price-group-shape {
    display: none;
  }
}

.tj-price-item {
  border-radius: 20px;
  border: 0.5px solid var(--tj-color-light-3);
  background: var(--tj-color-common-white);
  padding: 20px 20px 25px;
  margin-bottom: 30px;
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  z-index: 3;
}
.tj-price-item .tj-price-content-area {
  border-radius: 15px;
  padding-bottom: 20px;
  border: 1px solid rgba(112, 95, 255, 0.1);
}
.tj-price-item .price-top-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: var(--tj-color-light-3);
  border-radius: 15px 15px 0px 15px;
  padding: 35px 20px 25px;
  margin-bottom: 35px;
  position: relative;
  z-index: 3;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-price-item .price-top-content {
    padding: 25px 15px 15px;
  }
}
.tj-price-item .price-top-content::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 15px 15px 0px 15px;
  margin-bottom: 35px;
  background: linear-gradient(122deg, var(--tj-color-theme-secondary) 1.36%, var(--tj-color-theme-primary) 100%);
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
}
.tj-price-item .price-top-content .price-icon {
  font-size: 60px;
  color: rgba(255, 255, 255, 0.3);
  line-height: 1;
  margin-right: 25px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-price-item .price-top-content .price-icon {
    font-size: 45px;
    margin-right: 15px;
  }
}
.tj-price-item .price-top-content .title {
  color: var(--tj-color-common-white);
  margin-bottom: 0;
  font-size: 28px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-price-item .price-top-content .title {
    font-size: 24px;
  }
}
.tj-price-item .price-top-content .price {
  font-size: 45px;
  color: var(--tj-color-common-white);
  font-weight: var(--tj-fw-bold);
  font-family: var(--tj-ff-heading);
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-price-item .price-top-content .price {
    font-size: 35px;
  }
}
.tj-price-item .price-bottom-content {
  position: relative;
  z-index: 3;
  padding-left: 20px;
}
.tj-price-item .price-bottom-content .price-list ul li {
  color: var(--tj-color-common-black);
  position: relative;
  margin-bottom: 15px;
  padding-left: 30px;
}
.tj-price-item .price-bottom-content .price-list ul li:last-child {
  margin-bottom: 0;
}
.tj-price-item .price-bottom-content .price-list ul li i {
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: var(--tj-color-common-black);
  position: absolute;
  top: 60%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
}
.tj-price-item .price-button {
  margin-top: 35px;
}
.tj-price-item .price-button .tj-black-btn {
  font-weight: var(--tj-fw-regular);
}
.tj-price-item.price-active, .tj-price-item:hover {
  -webkit-box-shadow: 30px 15px 30px 0px rgba(0, 0, 0, 0.03);
          box-shadow: 30px 15px 30px 0px rgba(0, 0, 0, 0.03);
}
.tj-price-item.price-active .price-top-content::before, .tj-price-item:hover .price-top-content::before {
  opacity: 1;
  visibility: visible;
}
.tj-price-item.price-active .price-icon, .tj-price-item:hover .price-icon {
  color: var(--tj-color-common-white);
}
.tj-price-item.price-active .price-list ul li i, .tj-price-item:hover .price-list ul li i {
  color: transparent;
}

/* !END: Theme Price CSS */
/**----------------------------------------
START: Theme Video CSS
----------------------------------------*/
.tj-video-section {
  background: linear-gradient(72deg, var(--tj-color-light-3) 0%, rgba(112, 95, 255, 0) 99.2%);
  padding-top: 210px;
  padding-bottom: 210px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-video-section {
    padding-top: 110px;
    padding-bottom: 110px;
  }
}
.tj-video-section .tj-video-area .video-icon {
  border: 1px solid rgba(255, 255, 255, 0.4);
  padding: 15px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}
.tj-video-section .tj-video-area .video-icon::before {
  position: absolute;
  content: "";
  width: 90px;
  height: 90px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 50%;
}
.tj-video-section .tj-video-area .popup-videos-button {
  background: rgba(58, 58, 58, 0.7);
  width: 74px;
  height: 74px;
  line-height: 74px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  position: relative;
  z-index: 3;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-video-section .video-sec-shape {
    display: none;
  }
}
.tj-video-section .video-sec-shape .shape-1 {
  position: absolute;
  left: 12%;
  bottom: 20px;
}
.tj-video-section .video-sec-shape .shape-2 {
  position: absolute;
  right: 12%;
  top: 0;
}
.tj-video-section .video-sec-shape .shape-3 {
  position: absolute;
  left: 5%;
  top: 10%;
}

/* !END: Theme Video CSS */
/**----------------------------------------
START: Theme Scroll CSS
----------------------------------------*/
.webency-scroll-top {
  position: fixed;
  right: 30px;
  bottom: 25px;
  width: 45px;
  height: 45px;
  cursor: pointer;
  display: block;
  border-radius: 100%;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(0.75rem);
      -ms-transform: translateY(0.75rem);
          transform: translateY(0.75rem);
  -webkit-box-shadow: inset 0 0 0 0.1rem rgba(128, 130, 134, 0.25);
          box-shadow: inset 0 0 0 0.1rem rgba(128, 130, 134, 0.25);
  -webkit-transition: all 0.2s linear, margin-right 0s;
  transition: all 0.2s linear, margin-right 0s;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .webency-scroll-top {
    right: 15px;
    bottom: 15px;
  }
}

.webency-scroll-top.progress-done {
  visibility: visible;
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
}

.webency-scroll-top-icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 18px;
  color: var(--tj-color-theme-primary);
}

.webency-scroll-top > svg path {
  fill: none;
}

.webency-scroll-top > svg.progress-circle path {
  stroke: var(--tj-color-theme-primary);
  stroke-width: 4;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

@media (min-width: 1px) {
  .webency-scroll-top.progress-done {
    opacity: 1;
  }
}
@-webkit-keyframes letters-loading {
  0%, 75%, 100% {
    opacity: 0;
    -webkit-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
  }
  25%, 50% {
    opacity: 1;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}
@keyframes letters-loading {
  0%, 75%, 100% {
    opacity: 0;
    -webkit-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
  }
  25%, 50% {
    opacity: 1;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}
/* !END: Theme Scroll CSS */
/**----------------------------------------
START: Theme Product CSS
----------------------------------------*/
.tj-product-section {
  background: var(--tj-color-grey-5);
  padding-top: 130px;
  padding-bottom: 120px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-product-section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.tj-product-section .tj-sec-heading {
  max-width: 550px;
  margin-left: 0;
}

.product-left-content {
  padding-right: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .product-left-content {
    padding-right: 0;
    margin-bottom: 110px;
  }
}
@media (max-width: 575px) {
  .product-left-content {
    padding-right: 0;
    margin-bottom: 140px;
  }
}

.tj-product-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 40px;
  border: 1px solid var(--tj-color-theme-primary);
  border-radius: 20px;
  margin-bottom: 25px;
  border: 1px solid transparent;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-product-item {
    padding: 25px;
  }
}
@media (max-width: 575px) {
  .tj-product-item {
    display: block;
    border: 1px solid var(--tj-color-theme-primary);
    margin-bottom: 30px;
  }
}
.tj-product-item:last-child {
  margin-bottom: 0;
}
.tj-product-item .product-icon {
  margin-right: 40px;
  font-size: 50px;
  color: var(--tj-color-light-3);
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  line-height: 1;
  display: inline-block;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-product-item .product-icon {
    margin-right: 20px;
  }
}
@media (max-width: 575px) {
  .tj-product-item .product-icon {
    margin-right: 0;
    margin-bottom: 15px;
    background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
    background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.tj-product-item .skillbar {
  background-image: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-primary)), color-stop(51%, var(--tj-color-theme-primary)), to(var(--tj-color-theme-secondary)));
  background-image: linear-gradient(to right, var(--tj-color-theme-primary) 0%, var(--tj-color-theme-primary) 51%, var(--tj-color-theme-secondary) 100%);
  width: 0px;
  height: 10px;
  border-radius: 20px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  background-size: 200%;
}
@media (max-width: 575px) {
  .tj-product-item .skillbar {
    background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
    background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  }
}
.tj-product-item .product-content .title {
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: var(--tj-color-light-3);
  margin-bottom: 10px;
  display: inline-block;
}
@media (max-width: 575px) {
  .tj-product-item .product-content .title {
    background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
    background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.tj-product-item .product-content p {
  margin-bottom: 0;
}
.tj-product-item:hover {
  border-color: var(--tj-color-theme-primary);
  -webkit-box-shadow: 30px 15px 30px 0px rgba(0, 0, 0, 0.02);
          box-shadow: 30px 15px 30px 0px rgba(0, 0, 0, 0.02);
}
.tj-product-item:hover .product-content .title {
  color: transparent;
}
.tj-product-item:hover .product-icon {
  color: transparent;
}
.tj-product-item:hover .skillbar {
  background-position: -100%;
}

.tj-progress-bar {
  margin-top: 20px;
  width: 400px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .tj-progress-bar {
    width: 300px;
  }
}
@media (max-width: 575px) {
  .tj-progress-bar {
    width: 220px;
  }
}
.tj-progress-bar .skills {
  background-color: var(--tj-color-light-7);
  height: 10px;
  position: relative;
  border-radius: 20px;
}
.tj-progress-bar .skills span {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  right: -60px;
  font-size: 22px;
  font-weight: var(--tj-fw-bold);
  font-family: var(--tj-ff-heading);
  color: var(--tj-color-light-3);
}
.tj-progress-bar .skills .skillbar {
  background-color: var(--tj-color-light-3);
  width: 0px;
  height: 10px;
  border-radius: 20px;
}

.product-image-group {
  position: relative;
}
.product-image-group .group-image-1 {
  position: relative;
  margin-bottom: 30px;
  padding-left: 50px;
  max-width: 335px;
  width: 100%;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px) {
  .product-image-group .group-image-1 {
    max-width: 220px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-image-group .group-image-1 {
    max-width: 180px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .product-image-group .group-image-1 {
    max-width: 250px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-image-group .group-image-1 {
    max-width: 210px;
  }
}
@media (max-width: 575px) {
  .product-image-group .group-image-1 {
    max-width: 160px;
  }
}
.product-image-group .group-image-1::before {
  position: absolute;
  content: "";
  top: -20px;
  left: 33px;
  width: 302px;
  height: 360px;
  background: var(--tj-color-light-7);
  border-radius: 0 0 200px 200px;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px) {
  .product-image-group .group-image-1::before {
    width: 187px;
    height: 220px;
    top: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-image-group .group-image-1::before {
    width: 148px;
    height: 175px;
    top: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-image-group .group-image-1::before {
    width: 217px;
    height: 255px;
    top: -15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-image-group .group-image-1::before {
    width: 178px;
    height: 210px;
    top: -15px;
  }
}
@media (max-width: 575px) {
  .product-image-group .group-image-1::before {
    top: -10px;
    left: 33px;
    width: 127px;
    height: 140px;
  }
}
.product-image-group .group-image-1 img {
  border-radius: 0 0 200px 200px;
  position: relative;
  z-index: 3;
}
.product-image-group .group-image-2 {
  position: relative;
  max-width: 410px;
  width: 100%;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-image-group .group-image-2 {
    max-width: 220px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-image-group .group-image-2 {
    max-width: 200px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .product-image-group .group-image-2 {
    max-width: 250px;
  }
}
@media (max-width: 575px) {
  .product-image-group .group-image-2 {
    max-width: 150px;
  }
}
.product-image-group .group-image-2::before {
  position: absolute;
  content: "";
  top: 3px;
  top: 0;
  right: -15px;
  width: 425px;
  height: 465px;
  background: var(--tj-color-light-7);
  border-radius: 200px 200px 0 0;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px) {
  .product-image-group .group-image-2::before {
    width: 230px;
    height: 250px;
    right: -10px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-image-group .group-image-2::before {
    width: 215px;
    height: 230px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .product-image-group .group-image-2::before {
    width: 265px;
    height: 285px;
  }
}
@media (max-width: 575px) {
  .product-image-group .group-image-2::before {
    width: 165px;
    height: 175px;
  }
}
.product-image-group .group-image-2 img {
  border-radius: 200px 200px 0 0;
  position: relative;
  z-index: 3;
}
.product-image-group .group-image-2 .shape-1 {
  position: absolute;
  top: -45px;
  left: 10px;
}
.product-image-group .group-image-3 {
  position: absolute;
  top: 10%;
  right: -35%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  max-width: 325px;
  width: 100%;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px) {
  .product-image-group .group-image-3 {
    max-width: 210px;
    right: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-image-group .group-image-3 {
    max-width: 160px;
    right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-image-group .group-image-3 {
    max-width: 250px;
    right: 25%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-image-group .group-image-3 {
    max-width: 220px;
    right: 5%;
  }
}
@media (max-width: 575px) {
  .product-image-group .group-image-3 {
    max-width: 150px;
    right: 5%;
  }
}
.product-image-group .group-image-3::before {
  position: absolute;
  content: "";
  top: -10px;
  right: 0;
  width: 325px;
  height: 425px;
  background: var(--tj-color-light-7);
  border-radius: 0 0 200px 200px;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px) {
  .product-image-group .group-image-3::before {
    right: -15px;
    width: 225px;
    height: 290px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-image-group .group-image-3::before {
    right: -15px;
    width: 175px;
    height: 225px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-image-group .group-image-3::before {
    right: -20px;
    width: 270px;
    height: 340px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-image-group .group-image-3::before {
    right: -15px;
    width: 235px;
    height: 300px;
  }
}
@media (max-width: 575px) {
  .product-image-group .group-image-3::before {
    top: -10px;
    right: -10px;
    width: 159px;
    height: 205px;
  }
}
.product-image-group .group-image-3 img {
  border-radius: 0 0 200px 200px;
  position: relative;
  z-index: 3;
}
.product-image-group .group-image-3 .shape-1 {
  position: absolute;
  top: -80px;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: -1;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-image-group .group-image-3 .shape-1 {
    left: 20%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-image-group .group-image-3 .shape-1 {
    left: 10%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .product-image-group .group-image-3 .shape-1 {
    left: 25%;
  }
}
@media (max-width: 575px) {
  .product-image-group .group-image-3 .shape-1 {
    max-width: 100px;
  }
}
.product-image-group .group-image-3 .shape-2 {
  position: absolute;
  top: -110px;
  left: 35%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: -1;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-image-group .group-image-3 .shape-2 {
    left: 5%;
  }
}
@media (max-width: 575px) {
  .product-image-group .group-image-3 .shape-2 {
    left: 0;
  }
}

/* !END: Theme Product CSS */
/**----------------------------------------
START: Theme Roll CSS
----------------------------------------*/
.tj-roll-section {
  position: relative;
  z-index: 9;
  background-color: var(--tj-color-light-10);
  padding: 45px 0;
  overflow: hidden;
  width: 110%;
  left: -3px;
  -webkit-transform: rotate(-2deg);
      -ms-transform: rotate(-2deg);
          transform: rotate(-2deg);
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-roll-section {
    padding: 25px 0;
  }
}
.tj-roll-section::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.tj-roll-section .slide-roll {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.tj-roll-section .slide-roll .roll-box {
  position: relative;
  -webkit-animation: slide-har 80s linear infinite;
  animation: slide-har 80s linear infinite;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.tj-roll-section .slide-roll .roll-box .roll-item .roll-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 33px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.tj-roll-section .slide-roll .roll-box .roll-item .roll-content .roll-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  gap: 80px;
}
.tj-roll-section .slide-roll .roll-box .roll-item .roll-content .roll-text::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 52.3%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 50px;
  width: 7px;
  background: var(--tj-color-common-black);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tj-roll-section .slide-roll .roll-box .roll-item .roll-content .roll-text::before {
    height: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-roll-section .slide-roll .roll-box .roll-item .roll-content .roll-text::before {
    height: 30px;
    width: 4px;
  }
}
.tj-roll-section .slide-roll .roll-box .roll-item .roll-content .roll-text .title {
  white-space: nowrap;
  line-height: 1;
  font-size: 50px;
  color: var(--tj-color-common-white);
  margin: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tj-roll-section .slide-roll .roll-box .roll-item .roll-content .roll-text .title {
    font-size: 35px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-roll-section .slide-roll .roll-box .roll-item .roll-content .roll-text .title {
    font-size: 25px;
  }
}
.tj-roll-section .slide-roll .roll-box .roll-item .roll-content .roll-icon {
  margin-left: 33px;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-roll-section .slide-roll .roll-box .roll-item .roll-content .roll-icon {
    margin-left: 15px;
  }
}
.tj-roll-section .slide-roll .roll-box .roll-item .roll-content .roll-icon img {
  min-width: 60px;
  width: 100%;
  -webkit-animation: rotateImg 6s infinite linear;
          animation: rotateImg 6s infinite linear;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-roll-section .slide-roll .roll-box .roll-item .roll-content .roll-icon img {
    min-width: 40px;
  }
}
.tj-roll-section .slide-roll .roll-box-revers {
  position: relative;
  -webkit-animation: slide-har-revers 80s linear infinite;
  animation: slide-har-revers 80s linear infinite;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@-webkit-keyframes slide-har {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
@keyframes slide-har {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
@-webkit-keyframes slide-har-revers {
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
@keyframes slide-har-revers {
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
/**----------------------------------------
START: Theme Animation CSS
----------------------------------------*/
@-webkit-keyframes rotateImg {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotateImg {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.shake-y {
  -webkit-animation: shakeY 2.5s ease-in-out infinite;
  animation: shakeY 2.5s ease-in-out infinite;
}

@-webkit-keyframes shakeY {
  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
}
@keyframes shakeY {
  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
}
.pulse {
  -webkit-animation: pulse 2.5s ease-in-out infinite;
  animation: pulse 2.5s ease-in-out infinite;
}

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.skew-spin {
  -webkit-animation: skewSpin 5s linear infinite;
  animation: skewSpin 5s linear infinite;
}

@-webkit-keyframes skewSpin {
  0% {
    -webkit-transform: skewX(-10deg) skewY(-15deg) rotateY(-40deg) rotate(0);
    transform: skewX(-10deg) skewY(-15deg) rotateY(-40deg) rotate(0);
  }
  100% {
    -webkit-transform: skewX(-10deg) skewY(-15deg) rotateY(-40deg) rotate(360deg);
    transform: skewX(-10deg) skewY(-15deg) rotateY(-40deg) rotate(360deg);
  }
}
@keyframes skewSpin {
  0% {
    -webkit-transform: skewX(-10deg) skewY(-15deg) rotateY(-40deg) rotate(0);
    transform: skewX(-10deg) skewY(-15deg) rotateY(-40deg) rotate(0);
  }
  100% {
    -webkit-transform: skewX(-10deg) skewY(-15deg) rotateY(-40deg) rotate(360deg);
    transform: skewX(-10deg) skewY(-15deg) rotateY(-40deg) rotate(360deg);
  }
}
.left-right-rotate {
  -webkit-animation: rotated-style3 2s infinite alternate;
          animation: rotated-style3 2s infinite alternate;
}

@-webkit-keyframes rotated-style3 {
  0% {
    transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
  }
  100% {
    transform: rotate(10deg);
    -webkit-transform: rotate(10deg);
  }
}

@keyframes rotated-style3 {
  0% {
    transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
  }
  100% {
    transform: rotate(10deg);
    -webkit-transform: rotate(10deg);
  }
}
@-webkit-keyframes criss-cross-left {
  0% {
    left: -20px;
  }
  50% {
    left: 50%;
    width: 20px;
    height: 20px;
  }
  100% {
    left: 50%;
    width: 375px;
    height: 375px;
  }
}
@keyframes criss-cross-left {
  0% {
    left: -20px;
  }
  50% {
    left: 50%;
    width: 20px;
    height: 20px;
  }
  100% {
    left: 50%;
    width: 375px;
    height: 375px;
  }
}
@-webkit-keyframes criss-cross-right {
  0% {
    right: -20px;
  }
  50% {
    right: 50%;
    width: 20px;
    height: 20px;
  }
  100% {
    right: 50%;
    width: 375px;
    height: 375px;
  }
}
@keyframes criss-cross-right {
  0% {
    right: -20px;
  }
  50% {
    right: 50%;
    width: 20px;
    height: 20px;
  }
  100% {
    right: 50%;
    width: 375px;
    height: 375px;
  }
}
/* !END: Theme Animation CSS */
/**----------------------------------------
START: Theme Mainmenu CSS
----------------------------------------*/
.tj-menu-area ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}
.tj-menu-area li {
  display: inline-block;
  position: relative;
  margin-right: 55px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tj-menu-area li {
    margin-right: 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tj-menu-area li {
    margin-right: 15px;
  }
}
.tj-menu-area li.has-dropdown a {
  padding: 15px 20px 15px 0;
}
.tj-menu-area li.has-dropdown a::after {
  position: absolute;
  top: 3px;
  right: 0;
  line-height: 1;
  content: "+";
  font-family: "Font Awesome 6 Pro";
  font-size: 10px;
  -webkit-transition: all 0.1s ease-in-out 0s;
  transition: all 0.1s ease-in-out 0s;
}
.tj-menu-area li.has-dropdown:hover a::after {
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
}
.tj-menu-area li:last-child {
  margin-right: 0;
}
.tj-menu-area li a {
  font-size: 16px;
  font-family: var(--tj-ff-heading);
  font-weight: var(--tj-fw-regular);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: var(--tj-color-common-white);
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  padding: 15px 0;
}
.tj-menu-area li a.active, .tj-menu-area li a:hover {
  color: transparent;
}
.tj-menu-area li .black-color {
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: var(--tj-color-common-black);
}
.tj-menu-area li .black-color.active, .tj-menu-area li .black-color:hover {
  color: transparent;
}
.tj-menu-area li .sub-menu {
  text-align: left;
  margin: 0;
  list-style: none;
  background: var(--tj-color-common-white);
  position: absolute;
  opacity: 0;
  padding: 20px 0;
  visibility: hidden;
  z-index: 9;
  top: 130%;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  width: 250px;
  -webkit-box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
          box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}
.tj-menu-area li .sub-menu::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  content: "";
  -webkit-transition: 0.6s;
  transition: 0.6s;
}
.tj-menu-area li .sub-menu li {
  display: block;
  margin-right: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tj-menu-area li .sub-menu li a {
  position: relative;
  padding: 8px 20px;
  display: block;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: var(--tj-color-common-black);
}
.tj-menu-area li .sub-menu li a::before {
  content: "";
  width: 0;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tj-menu-area li .sub-menu li a::after {
  display: none;
}
.tj-menu-area li .sub-menu li a.active, .tj-menu-area li .sub-menu li a:hover {
  color: transparent;
}
.tj-menu-area li .sub-menu li a.active::before, .tj-menu-area li .sub-menu li a:hover::before {
  width: 14px;
}
.tj-menu-area li .sub-menu li:last-child {
  padding-bottom: 0;
}
.tj-menu-area li .sub-menu li.active, .tj-menu-area li .sub-menu li:hover {
  padding-left: 15px;
}
.tj-menu-area li:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  top: 150%;
}

.tj-canva-icon a {
  cursor: pointer;
  display: block;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}
.tj-canva-icon a span {
  height: 2px;
  width: 35px;
  display: block;
  background: var(--tj-color-common-white);
  cursor: pointer;
  list-style: none;
  margin: 8px 0;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  margin-left: auto;
}
.tj-canva-icon a span.dot2 {
  width: 27px;
}
.tj-canva-icon a span.dot4 {
  width: 22px;
}

.tj-canva-two a {
  cursor: pointer;
  display: block;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
}
.tj-canva-two a span {
  height: 2px;
  width: 35px;
  display: block;
  background: var(--tj-color-theme-primary);
  cursor: pointer;
  list-style: none;
  margin: 8px 0;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  margin-left: auto;
}
.tj-canva-two a span.dot2 {
  width: 27px;
}
.tj-canva-two a span.dot4 {
  width: 22px;
}

.header-style-two {
  padding: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-style-two .menu-area {
    max-width: 960px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-style-two .menu-area {
    max-width: 540px;
    padding: 12px 10px;
  }
}
.header-style-two .tj-menu-area ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}
.header-style-two .tj-menu-area li {
  display: inline-block;
  position: relative;
  margin-right: 55px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .header-style-two .tj-menu-area li {
    margin-right: 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-style-two .tj-menu-area li {
    margin-right: 15px;
  }
}
.header-style-two .tj-menu-area li.has-dropdown a {
  padding: 15px 20px 15px 0;
}
.header-style-two .tj-menu-area li.has-dropdown a::after {
  position: absolute;
  top: 5px;
  right: 5px;
  line-height: 1;
  content: "+";
  font-family: "Font Awesome 6 Pro";
  font-size: 10px;
  -webkit-transition: all 0.1s ease-in-out 0s;
  transition: all 0.1s ease-in-out 0s;
  color: var(--tj-color-common-black);
}
.header-style-two .tj-menu-area li.has-dropdown:hover a::after {
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
}
.header-style-two .tj-menu-area li:last-child {
  margin-right: 0;
}
.header-style-two .tj-menu-area li a {
  font-size: 16px;
  font-family: var(--tj-ff-heading);
  font-weight: var(--tj-fw-regular);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: var(--tj-color-common-black);
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  padding: 15px 0;
}
.header-style-two .tj-menu-area li a.active, .header-style-two .tj-menu-area li a:hover {
  color: transparent;
}
.header-style-two .tj-menu-area li .sub-menu {
  text-align: left;
  margin: 0;
  list-style: none;
  background: var(--tj-color-common-white);
  position: absolute;
  opacity: 0;
  padding: 20px 0;
  visibility: hidden;
  z-index: 9;
  top: 130%;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  width: 220px;
  -webkit-box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
          box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}
.header-style-two .tj-menu-area li .sub-menu::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  content: "";
  -webkit-transition: 0.6s;
  transition: 0.6s;
}
.header-style-two .tj-menu-area li .sub-menu li {
  display: block;
  margin-right: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.header-style-two .tj-menu-area li .sub-menu li a {
  position: relative;
  padding: 5px 20px;
  display: block;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: var(--tj-color-common-black);
}
.header-style-two .tj-menu-area li .sub-menu li a::before {
  content: "";
  width: 0;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.header-style-two .tj-menu-area li .sub-menu li a::after {
  display: none;
}
.header-style-two .tj-menu-area li .sub-menu li a.active, .header-style-two .tj-menu-area li .sub-menu li a:hover {
  color: transparent;
}
.header-style-two .tj-menu-area li .sub-menu li a.active::before, .header-style-two .tj-menu-area li .sub-menu li a:hover::before {
  width: 14px;
}
.header-style-two .tj-menu-area li .sub-menu li:last-child {
  padding-bottom: 0;
}
.header-style-two .tj-menu-area li .sub-menu li.active, .header-style-two .tj-menu-area li .sub-menu li:hover {
  padding-left: 15px;
}
.header-style-two .tj-menu-area li:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  top: 150%;
}

.header-style-three {
  padding: 30px 0;
}
.header-style-three .menu-area {
  padding: 20px 45px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-style-three .menu-area {
    max-width: 960px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header-style-three .menu-area {
    max-width: 540px;
    padding: 12px 10px;
  }
}
.header-style-three .tj-menu-area ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}
.header-style-three .tj-menu-area li {
  display: inline-block;
  position: relative;
  margin-right: 55px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .header-style-three .tj-menu-area li {
    margin-right: 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-style-three .tj-menu-area li {
    margin-right: 15px;
  }
}
.header-style-three .tj-menu-area li.has-dropdown a {
  padding: 15px 20px 15px 0;
}
.header-style-three .tj-menu-area li.has-dropdown a::after {
  position: absolute;
  top: 5px;
  right: 5px;
  line-height: 1;
  content: "+";
  font-family: "Font Awesome 6 Pro";
  font-size: 10px;
  -webkit-transition: all 0.1s ease-in-out 0s;
  transition: all 0.1s ease-in-out 0s;
  color: var(--tj-color-common-black);
}
.header-style-three .tj-menu-area li.has-dropdown:hover a::after {
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
}
.header-style-three .tj-menu-area li:last-child {
  margin-right: 0;
}
.header-style-three .tj-menu-area li a {
  font-size: 16px;
  font-family: var(--tj-ff-heading);
  font-weight: var(--tj-fw-regular);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: var(--tj-color-common-black);
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
  padding: 15px 0;
}
.header-style-three .tj-menu-area li a.active, .header-style-three .tj-menu-area li a:hover {
  color: transparent;
}
.header-style-three .tj-menu-area li .sub-menu {
  text-align: left;
  margin: 0;
  list-style: none;
  background: var(--tj-color-common-white);
  position: absolute;
  opacity: 0;
  padding: 20px 0;
  visibility: hidden;
  z-index: 9;
  top: 130%;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  width: 220px;
  -webkit-box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
          box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}
.header-style-three .tj-menu-area li .sub-menu::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  content: "";
  -webkit-transition: 0.6s;
  transition: 0.6s;
}
.header-style-three .tj-menu-area li .sub-menu li {
  display: block;
  margin-right: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.header-style-three .tj-menu-area li .sub-menu li a {
  position: relative;
  padding: 5px 20px;
  display: block;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: var(--tj-color-common-black);
}
.header-style-three .tj-menu-area li .sub-menu li a::before {
  content: "";
  width: 0;
  height: 2px;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.header-style-three .tj-menu-area li .sub-menu li a::after {
  display: none;
}
.header-style-three .tj-menu-area li .sub-menu li a.active, .header-style-three .tj-menu-area li .sub-menu li a:hover {
  color: transparent;
}
.header-style-three .tj-menu-area li .sub-menu li a.active::before, .header-style-three .tj-menu-area li .sub-menu li a:hover::before {
  width: 14px;
}
.header-style-three .tj-menu-area li .sub-menu li:last-child {
  padding-bottom: 0;
}
.header-style-three .tj-menu-area li .sub-menu li.active, .header-style-three .tj-menu-area li .sub-menu li:hover {
  padding-left: 15px;
}
.header-style-three .tj-menu-area li:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  top: 150%;
}

.tj-overlay-canvas {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.tj-offcanvas-area {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 485px;
  z-index: 9999;
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  padding: 30px 15px 30px;
  -webkit-transform: translateX(100%);
      -ms-transform: translateX(100%);
          transform: translateX(100%);
  opacity: 0;
  visibility: visible;
  overflow-y: scroll;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
@media (max-width: 575px) {
  .tj-offcanvas-area {
    width: 320px;
  }
}
.tj-offcanvas-area .tj-offcanvas-header {
  margin-bottom: 50px;
}
.tj-offcanvas-area .tj-offcanvas-header .logo-area {
  max-width: 180px;
  width: 100%;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-offcanvas-area .tj-offcanvas-header .logo-area {
    max-width: 150px;
  }
}
.tj-offcanvas-area .tj-offcanvas-header .offcanvas-icon a {
  font-size: 35px;
  color: var(--tj-color-common-white);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tj-offcanvas-area .tj-offcanvas-header .offcanvas-icon a:hover {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}
.tj-offcanvas-area .tj-search-box {
  position: relative;
  margin-bottom: 20px;
}
.tj-offcanvas-area .tj-search-box form input[type=text] {
  border: none;
  width: 100%;
  padding: 0 15px;
  border-radius: 3px;
  height: 52px;
  line-height: 52px;
}
.tj-offcanvas-area .tj-search-box form a i {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  padding: 17px 20px;
  border-radius: 0px 3px 3px 0px;
  background: var(--tj-color-theme-secondary);
  font-size: 18px;
  color: var(--tj-color-common-white);
}
.tj-offcanvas-area .mobile-contact {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.tj-offcanvas-area .mobile-contact ul li {
  margin-bottom: 15px;
}
.tj-offcanvas-area .mobile-contact ul li:last-child {
  margin-bottom: 0;
}
.tj-offcanvas-area .mobile-contact ul li span {
  display: inline-block;
  margin-right: 10px;
}
.tj-offcanvas-area .mobile-contact ul li span i {
  background: var(--tj-color-theme-primary);
  color: var(--tj-color-common-white);
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
}
.tj-offcanvas-area .mobile-contact ul li a {
  color: var(--tj-color-common-white);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.tj-offcanvas-area .mobile-share {
  margin-bottom: 30px;
}
.tj-offcanvas-area .mobile-share ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
}
.tj-offcanvas-area .mobile-share ul li a {
  color: var(--tj-color-common-white);
  width: 45px;
  height: 45px;
  line-height: 45px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  background: var(--tj-color-common-black);
}
.tj-offcanvas-area .mobile-share ul li a:hover {
  color: var(--tj-color-common-black);
  background: var(--tj-color-common-white);
}
.tj-offcanvas-area .header-button {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 30px;
}

.tj-search-box input::-ms-input-placeholder {
  color: rgba(58, 58, 58, 0.3);
}

.tj-search-box input::-webkit-input-placeholder {
  color: rgba(58, 58, 58, 0.3);
}

.tj-search-box input::-moz-placeholder {
  color: rgba(58, 58, 58, 0.3);
}

.tj-search-box input:-ms-input-placeholder {
  color: rgba(58, 58, 58, 0.3);
}

.tj-search-box input::placeholder {
  color: rgba(58, 58, 58, 0.3);
}

.canvas_expanded .tj-offcanvas-area {
  -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
          transform: translateX(0);
}

.canvas_expanded .tj-overlay-canvas,
.canvas_expanded .tj-offcanvas-area {
  opacity: 1;
  visibility: visible;
}

.mobile-navbar-menu {
  overflow: hidden;
}

.mean-container a.meanmenu-reveal {
  display: none !important;
}

.mobile-navbar-menu .mean-bar {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.mean-nav ul li a.mean-expand i.fa-plus {
  display: block;
}

.mean-nav ul li a.mean-expand i.fa-minus {
  display: none;
}

.mobile-navbar-menu #mobile-menu > ul {
  display: block !important;
}

.mean-container .mean-nav ul li li {
  margin-left: 15px;
}
.mean-container .mean-nav ul li li a {
  text-transform: capitalize;
  padding: 14px 0;
  width: 100%;
}

.mean-nav ul li a.mean-expand.mean-clicked i.fa-minus {
  display: block;
}

.mean-nav ul li a.mean-expand.mean-clicked i.fa-plus {
  display: none;
}

.mobile-navbar-menu .mean-bar * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.mobile-navbar-menu .mean-nav {
  background: transparent;
  overflow: hidden;
  margin-top: 15px;
  margin-bottom: 20px;
}
.mobile-navbar-menu .mean-nav ul li:first-child {
  border-top: none;
}
.mobile-navbar-menu .mean-nav ul li a {
  color: var(--tj-color-common-white);
  border-top: none;
  padding: 14px 0;
  width: 100%;
  font-family: var(--tj-ff-heading);
  font-weight: var(--tj-fw-regular);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

/* !END: Theme Mainmenu CSS */
/**----------------------------------------
START: Theme Breadcrumb CSS
----------------------------------------*/
.breadcrumb-wrapper {
  padding-top: 210px;
  padding-bottom: 140px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: linear-gradient(181deg, var(--tj-color-grey-7) 2.29%, rgba(40, 40, 40, 0.7) 89.63%);
  position: relative;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-wrapper {
    padding-top: 160px;
    padding-bottom: 90px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-wrapper {
    padding-top: 150px;
    padding-bottom: 80px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .breadcrumb-wrapper {
    padding-top: 130px;
    padding-bottom: 60px;
  }
}

.tj-breadcrumb-shape .breadcrumb-shape-one {
  position: absolute;
  bottom: 0;
  left: 0;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-breadcrumb-shape .breadcrumb-shape-one {
    width: 50%;
  }
}
.tj-breadcrumb-shape .breadcrumb-shape-two {
  position: absolute;
  top: 0;
  right: 0;
}
@media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-breadcrumb-shape .breadcrumb-shape-two {
    width: 50%;
  }
}
.tj-breadcrumb-shape .breadcrumb-shape-1 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  opacity: 0.2;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-breadcrumb-shape .breadcrumb-shape-1 {
    display: none;
  }
}
.tj-breadcrumb-shape .breadcrumb-shape-2 {
  position: absolute;
  left: 8%;
  top: 15%;
  opacity: 0.2;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-breadcrumb-shape .breadcrumb-shape-2 {
    display: none;
  }
}
.tj-breadcrumb-shape .breadcrumb-shape-3 {
  position: absolute;
  left: 8%;
  top: 25%;
  opacity: 0.2;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-breadcrumb-shape .breadcrumb-shape-3 {
    display: none;
  }
}
.tj-breadcrumb-shape .breadcrumb-shape-4 {
  position: absolute;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  left: 42%;
  bottom: 30px;
  opacity: 0.2;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-breadcrumb-shape .breadcrumb-shape-4 {
    display: none;
  }
}
.tj-breadcrumb-shape .breadcrumb-shape-5 {
  position: absolute;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  left: 36%;
  bottom: 50px;
  opacity: 0.2;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-breadcrumb-shape .breadcrumb-shape-5 {
    display: none;
  }
}
.tj-breadcrumb-shape .breadcrumb-shape-6 {
  position: absolute;
  right: 20%;
  bottom: 80px;
  opacity: 0.6;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-breadcrumb-shape .breadcrumb-shape-6 {
    display: none;
  }
}
.tj-breadcrumb-shape .breadcrumb-shape-7 {
  position: absolute;
  right: 80px;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  opacity: 0.2;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-breadcrumb-shape .breadcrumb-shape-7 {
    display: none;
  }
}
.tj-breadcrumb-shape .breadcrumb-shape-8 {
  position: absolute;
  right: 80px;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  opacity: 0.2;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .tj-breadcrumb-shape .breadcrumb-shape-8 {
    display: none;
  }
}

.breadcrumb-content-area {
  position: relative;
  z-index: 9;
}
.breadcrumb-content-area .breadcrumb-heading {
  margin-bottom: 35px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .breadcrumb-content-area .breadcrumb-heading {
    margin-bottom: 10px;
  }
}
.breadcrumb-content-area .breadcrumb-heading .breadcrumb-title {
  color: var(--tj-color-common-white);
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .breadcrumb-content-area .breadcrumb-heading .breadcrumb-title {
    font-size: 50px;
  }
}
@media (max-width: 575px) {
  .breadcrumb-content-area .breadcrumb-heading .breadcrumb-title {
    font-size: 38px;
  }
}
.breadcrumb-content-area .breadcrumb-link span {
  color: var(--tj-color-common-white);
  font-size: 16px;
  font-weight: var(--tj-fw-sbold);
  font-family: var(--tj-ff-body);
}
.breadcrumb-content-area .breadcrumb-link i {
  color: var(--tj-color-common-white);
  padding-left: 15px;
  padding-right: 15px;
  font-size: 20px;
  position: relative;
  top: 3px;
  font-weight: var(--tj-fw-sbold);
}
.breadcrumb-content-area .breadcrumb-link a span {
  color: var(--tj-color-common-white);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  position: relative;
  font-weight: var(--tj-fw-sbold);
  font-family: var(--tj-ff-body);
}
.breadcrumb-content-area .breadcrumb-link a span::before {
  position: absolute;
  content: "";
  width: 0;
  height: 1px;
  bottom: 0;
  left: 0;
  background: var(--tj-color-common-white);
}
.breadcrumb-content-area .breadcrumb-link a span:hover::before {
  width: 100%;
}

/* !END: Breadcrumb CSS */
/**----------------------------------------
START: Theme Preloder CSS
----------------------------------------*/
/***********************************
PAGE LOAD ANIMATION
***********************************/
.preloader {
  background: var(--tj-color-grey-2);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  text-align: center;
}
.preloader .tj-cancel-btn {
  position: absolute;
  top: 15px;
  right: 15px;
}
.preloader .preloader-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.preloader .preloader-inner .lines {
  height: 25px;
}
.preloader .preloader-inner .lines .line {
  width: 1px;
  height: 20px;
  background: #fff;
  margin: 0 2px;
  display: inline-block;
  -webkit-animation: opacity-2 1000ms infinite ease-in-out;
          animation: opacity-2 1000ms infinite ease-in-out;
  background: -webkit-gradient(linear, left top, left bottom, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(180deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
}
.preloader .preloader-inner .lines .line-1 {
  -webkit-animation-delay: 800ms;
          animation-delay: 800ms;
}
.preloader .preloader-inner .lines .line-2 {
  -webkit-animation-delay: 600ms;
          animation-delay: 600ms;
}
.preloader .preloader-inner .lines .line-3 {
  -webkit-animation-delay: 400ms;
          animation-delay: 400ms;
}
.preloader .preloader-inner .lines .line-4 {
  -webkit-animation-delay: 200ms;
          animation-delay: 200ms;
}
.preloader .preloader-inner .lines .line-6 {
  -webkit-animation-delay: 200ms;
          animation-delay: 200ms;
}
.preloader .preloader-inner .lines .line-7 {
  -webkit-animation-delay: 400ms;
          animation-delay: 400ms;
}
.preloader .preloader-inner .lines .line-8 {
  -webkit-animation-delay: 600ms;
          animation-delay: 600ms;
}
.preloader .preloader-inner .lines .line-9 {
  -webkit-animation-delay: 800ms;
          animation-delay: 800ms;
}
.preloader .preloader-inner .text {
  background: -webkit-gradient(linear, left top, right top, from(var(--tj-color-theme-secondary)), to(var(--tj-color-theme-primary)));
  background: linear-gradient(90deg, var(--tj-color-theme-secondary) 0%, var(--tj-color-theme-primary) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 3.5px;
  display: inline-block;
  line-height: 1.2;
  font-size: 12px;
}

@-webkit-keyframes opacity-1 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes opacity-1 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes opacity-2 {
  0% {
    opacity: 1;
    height: 25px;
  }
  50% {
    opacity: 0;
    height: 20px;
  }
  100% {
    opacity: 1;
    height: 25px;
  }
}
@keyframes opacity-2 {
  0% {
    opacity: 1;
    height: 25px;
  }
  50% {
    opacity: 0;
    height: 20px;
  }
  100% {
    opacity: 1;
    height: 25px;
  }
}
/* !END: Preloder CSS *//*# sourceMappingURL=main.css.map */